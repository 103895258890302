//React components
import React, { useState } from 'react';
import Select from 'react-select';
import { Modal, Button,Form } from 'react-bootstrap';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//API
import { SegmentsFechtAPI } from '../../../api/Segments';
//Utils 
import { RenderIf } from '../../utils/RenderIf';
import { checkUserModules } from '../../utils/CheckUserModules';
//Icons
import pencil from '../../img/icons/pencil.png'

//Function to edit a segment
function ModalEditSegment({ segment, loadSegments, segment_types, user }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [name, setName] = useState("");
    const [description, setDesciption] = useState("");

    let token = localStorage.getItem("x-access-token")


    //Function to register the product 
    const UpdateSegment = async (e) => {
        e.preventDefault();

        //We get the data
        const form = new FormData(e.target);
        //We check that the integration module is active
        if (user.business.posIntegration !== undefined) {
            form.append("pos_integration", user.business.posIntegration)
        }
        const editSegment = Object.fromEntries(form.entries());

        try {
            //We update the data 
            let response = await SegmentsFechtAPI.updateSegment(segment.segment_id, editSegment, token);
            //We show the success alert 
            ErrorAlert('', 'Acompañante editada con exito', 'success')
            loadSegments()
            setShowModal(false);

        } catch (err) {

            ErrorAlert('', 'Error en la edicion', 'error')
            console.log(err)
        }
    }

    return (
        <>
            <button className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border'
                onClick={() => setShowModal(true)}
            >
                <img className='icon-options-cards' src={pencil} alt="Edit" />
            </button>

            <Modal
                show={showModal}
                onHide={(e) => (setShowModal(false))}
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Editar acompañante </h3>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        className="needs-validation"
                        onSubmit={UpdateSegment}
                    >

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                name='name'
                                placeholder={segment.name}
                                onChange={(e) => setName(e.target.value)}


                            />
                        </Form.Group>


                        <Form.Group className="mb-3" >
                            <Form.Label>Decripcion</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                placeholder={segment.description}
                                onChange={(e) => setDesciption(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Seleccione el tipo de acompañante</Form.Label>
                            <Select
                                className='w-100'
                                options={segment_types}
                                name='type'
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Cantidad minima</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder={segment.minimumCount}
                                name="minimumCount"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Cantidad maxima</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder={segment.maximumCount}
                                name="maximumCount"
                            />
                        </Form.Group>


                        <RenderIf isTrue={checkUserModules('pos_integrations_module', user.modules) !== -1 && user.business.posIntegration !== undefined}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Referencia externa</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Referencia externa"
                                    name="external_reference"
                                />
                            </Form.Group>
                        </RenderIf>

                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalEditSegment