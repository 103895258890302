import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { ButtonGroup } from 'react-bootstrap';
import { ToggleButton } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import { UsersFetchAPI } from '../../api/Users';
import { ClientsFetchAPI } from '../../api/Clients';


import { ListGroup } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

function Modalbeneficiary({ business, setclient_beneficiary, setuser_beneficiary, sethandlerloadData, setbeneficiary, getClientsByBusiness}) {
    const [showModal, setShowModal] = useState(false)
    const [clients, setClients] = useState([])
    const [searchedClients, setSearchedClients] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [deleteMode, setDeleteMode] = useState(false)
    const [orderSort, setOrderSort] = useState('asc')
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');

    const [typeFilter, setTypeFilter] = useState('user_name')
    const [radioValue, setRadioValue] = useState('1');


    const getClients = async () => {    
        setbeneficiary('')    
        setuser_beneficiary({user_id:"", user_name:""})
        setclient_beneficiary({client_id:"", client_name:""})
        try {
            let clients_data = await getClientsByBusiness();
            let dataFilter = clients_data

            setTypeFilter("name")
            sortJSON(dataFilter, "name")
            setClients(dataFilter)
            setSearchedClients(dataFilter)
        } catch (err) {
            console.log(err)
        }
    }

    const getUserBybusiness = async () => {
        setbeneficiary('')
        setuser_beneficiary({user_id:"", user_name:""})
        setclient_beneficiary({client_id:"", client_name:""})
        try {
            const response = await UsersFetchAPI.getBusinessUser(business.id, token)

            let dataFilter = response.data.users.filter(({lastName}) => lastName === "Descuento")
            

            setTypeFilter("user_name")
            sortJSON(dataFilter, "user_name")
            setClients(dataFilter)
            setSearchedClients(dataFilter)
        } catch (err) {
            console.log(err)
        }
    }


    function sortJSON(data, key) {
        return data.sort(function (a, b) {
            var x = a[key],
                y = b[key];

            if (orderSort === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }

            if (orderSort === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }


    const handleFilter = () => {
        if (orderSort === 'asc') {
            setOrderSort('desc')

        } else {
            setOrderSort('asc')
        }


        if (searchTerm === '') {
            sortJSON(clients, typeFilter)
        } else {
            sortJSON(filteredProducts, typeFilter)
        }
    }

    const handleSaveClient = (data) => {
        setOrderSort('asc')

        console.log(data)
        if (typeFilter == 'user_name') {
            let dataUser={user_id:data.user_id, user_name:data.user_name}
            console.log(dataUser)
            setuser_beneficiary(dataUser)
            setbeneficiary(data.user_name)
        }else{
            let dataClient={client_id:data.id, client_name:data.name}
            setclient_beneficiary(dataClient)
            setbeneficiary(data.name)

        }
        
        setShowModal(false)
        setSearchTerm('')
        sethandlerloadData(1)
    }


    //Filter the products by the term searched 
    const filteredProducts = useMemo(() => {
        if (searchTerm === '') {
            return clients
        }
        return searchedClients.filter(data => {
            if (data.name) {
                const nameMatch = data.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                return nameMatch;
            } else {
                const nameMatch = data.user_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                return nameMatch;
            }

        })
    }, [searchTerm, clients]);







    return (
        <>
            <Button className='' onClick={() => (getUserBybusiness(), setShowModal(true))}><i class="uil uil-chat-bubble-user"></i>Buscar Beneficiario</Button>

            <Modal
                className='bg-blur-sp'
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='xl'
                centered>

                <Modal.Body className='p-2'>
                    <section className='flex-container'>
                        <div className="d-flex justify-content-center border-bottom-0">
                            <h2 className="modal-title fw-bold fs-5">
                                <i class="uil uil-users-alt"></i> Beneficiario descuento
                            </h2>
                        </div>

                        <div className='d-flex justify-content-center border-bottom-0'>
                                <ToggleButton
                                 className='m-1'
                                    key={1}
                                    id={`radio-1`}
                                    type="radio"
                                    variant="secondary"
                                    name="radio"
                                    value={"1"}
                                    checked={radioValue === "1"}
                                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                                    onClick={() => getUserBybusiness()}
                                >
                                    Colaborador
                                </ToggleButton>

                                <ToggleButton
                                className='m-1'
                                    key={2}
                                    id={`radio-2`}
                                    type="radio"
                                    variant="secondary"
                                    name="radio"
                                    value={"2"}
                                    checked={radioValue === "2"}
                                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                                    onClick={() => getClients()}
                                >
                                    Cliente
                                </ToggleButton>
                            
                        </div>

                        <span className='p-0  text-primary fw-bold'>Seleccion de clientes </span>

                        <div className="d-flex justify-content-center align-items-center ">
                            <form className="row g-3 align-content-center align-items-center">
                                <div className="col-auto ">
                                    <span className='mb-0 text-secondary fw-bold' ><i class="uil uil-search"></i> Buscar {typeFilter == "name"? <>cliente</>: <>colaborador</>}</span>
                                </div>
                                <div className="col-auto">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Ingrese el nombre"
                                        onChange={e => setSearchTerm(e.target.value)}

                                    />
                                </div>
                                <div className="col-auto">
                                    <Button variant="outline-secondary" onClick={() => handleFilter()}> {orderSort === 'asc' ? <><i class="uil uil-sort-amount-down"></i> A-Z</> : <><i class="uil uil-sort-amount-up"></i> Z-A</>}</Button>
                                </div>
                            </form>
                        </div>
                        {/* List  */}

                        <div className={
                            !filteredProducts.length
                                ? "d-block item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer align-self-center overflow-auto w-100 d-flex align-items-center justify-content-center"
                                : "row item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer-2 align-self-center  w-100"

                        }>

                            {filteredProducts.map(data => (
                                <div className='col-12 col-lg-6'>
                                    <ListGroup as="ol" className='m-2' >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start  shadow-sm"
                                            onClick={() => handleSaveClient(data)}
                                        >
                                            <div className="ms-2 me-auto d-inline-block text-truncate">

                                                {typeFilter == 'user_name' ? <><div>Nombre: {data.user_name}</div></> : <><div>Nombre: {data.name}</div>
                                                    <div>RUC: {data.ruc}</div>
                                                    <div>Dv: {data.dv}</div></>}

                                            </div>

                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            ))}

                        </div>
                        <small className='text-body-tertiary text-center'>
                            <i className="uil uil-info-circle me-1"></i>
                            Por favor, seleccione un cliente para personalizar la factura.
                        </small>
                        {/* List end  */}
                    </section>
                    <div className='d-block mt-lg-1 mt-0'>
                        <div className='d-flex justify-content-end mt-2'>
                            <button className='btn bg-danger-subtle text-danger  border-0 fw-bold '
                                onClick={() => (setShowModal(false), setSearchTerm(''), setOrderSort('asc'))}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >

        </>
    )
}

export default Modalbeneficiary