//React components
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//API
import { SmartpayModulesFetchAPI } from '../../../api/SmartPay.Modules';

//Modal to add a group to a set group
function ModalAddPermissionToUserRole({ module, modulePermissions, token, role, setEventHandler }) {
    //States
    const [permission, setPermissions] = useState([])
    const [showModalAsing, setShowModalAsing] = useState(false)
    
    //Function to format the permissions 
    const getPermissions = async () => {
        //Variables
        let permissionInfo = []
        try {
            //We set the permissions
            modulePermissions.forEach(permission => {
                permissionInfo.push({ value: permission.permise_id, label: permission.permission_name })
            });
            setPermissions(permissionInfo)
        } catch (err) {
            setPermissions([])
        }
    }

    //Function to asing permission to a module of a user role
    const asingSmartPayModulePermissionByUserRole = async (e) => {
        e.preventDefault();
        //We get the data
        const form = new FormData(e.target);
        form.append('module_id', module.module_id)
        form.append('role_id', role.id)
        Object.fromEntries(form.entries());
        try {
            //We asing the product to the set group
            await SmartpayModulesFetchAPI.asingSmartpayModulePermission(form, token);
            setShowModalAsing(false)
            ErrorAlert('', 'Permiso asignado', 'success')
            setEventHandler(1)
        } catch (err) {
            ErrorAlert(err.response.data.message, 'Error de asignacion', 'warning')
        }
    }
 
    return (
        <>

            <button className='btn btn-outline-primary btn-sm border-0' onClick={() => (setShowModalAsing(true), getPermissions())}> <i className="uil uil-plus">Asignar permiso</i></button>
            {/** Modal to add group to set groups */}
            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar permiso</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        className="needs-validation"
                        onSubmit={asingSmartPayModulePermissionByUserRole}
                    >

                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Seleccione permiso</Form.Label>
                            <Select
                                className='w-100'
                                options={permission}
                                name='permissionId'
                            />
                        </Form.Group>
                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit" className='m-3'>
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsing(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ModalAddPermissionToUserRole