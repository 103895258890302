//React Components
import React, { useState, useEffect, useMemo } from 'react';
//Components
import { Form } from 'react-bootstrap';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import ModalCreatePromotion from './ModalPromotions/ModalCreatrePromotion';
import ModalViewPromotion from './ModalPromotions/ModalViewPromotion';
import ModalListSegmentsByPromotion from './ModalPromotions/ModalListSegmentsByPromotion';
import ModalEditPromotion from './ModalPromotions/ModalEditPromotion';
import ModalAsingAndRemovedPromotionToBranchoffice from './ModalPromotions/ModalAsingAndRemovedPromotionToBranchoffice';
//Api 
import { PromotionsFechtAPI } from '../../api/Promotions';
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';
import Swal from 'sweetalert2';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
//icons
import on from '../img/icons/on.png'
import off from '../img/icons/off.png'
import trash from '../img/icons/delete.png'
//Promotion table component
const PromotionTable = ({ branchoffice_id, eventHandler, token, user, title }) => {
    const [promotions, setPromotions] = useState([]);
    const [searchedPromotions, setSearchedPromotions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // We load the promotions list
        loadPromotions();
    }, [eventHandler]);

    //Function to load promotions
    const loadPromotions = async () => {
        try {
            //We search the promotions of the branchoffice
            let user = JSON.parse(localStorage.getItem('user'))

            if (branchoffice_id != 0) {
                console.log(branchoffice_id);
                const promotions = await PromotionsFechtAPI.getPromotionsByBranchoffice(branchoffice_id, token);
                console.log(promotions)
                let dataArray = promotions.data.promotions.sort(function (a, b) {
                    if (a.promotion_name.toLowerCase() < b.promotion_name.toLowerCase()) { return -1; }
                    if (a.promotion_name.toLowerCase() > b.promotion_name.toLowerCase()) { return 1; }
                    return 0;
                })

                console.log(dataArray);

                setPromotions(dataArray)
                setSearchedPromotions(dataArray)
            } else {
                console.log(branchoffice_id);
                const promotions = await PromotionsFechtAPI.getPromotionsByBusiness(user.business_id, token);
                console.log(promotions)
                console.log(promotions)
                let dataArray = promotions.data.data.sort(function (a, b) {
                    if (a.promotion_name.toLowerCase() < b.promotion_name.toLowerCase()) { return -1; }
                    if (a.promotion_name.toLowerCase() > b.promotion_name.toLowerCase()) { return 1; }
                    return 0;
                })

                console.log(dataArray);

                setPromotions(dataArray)
                setSearchedPromotions(dataArray)
            }
        } catch (err) {
            console.log(err);
            setPromotions([])
            setSearchedPromotions([])
        }
    };


    //Filter the promotions by the term searched
    const filteredPromotions = useMemo(() => {
        if (searchTerm === '') {
            return promotions
        }
        return searchedPromotions.filter(promotion => {
            const nameMatch = promotion.promotion_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = promotion.promotion_description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, promotions]);


    const beforeDelete = async (promotion_id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Estas seguro?',
            text: "Quieres eliminar esta promocion de la sucursal?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si eliminar',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deletePromotionByBrachoffice(promotion_id);
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'No se elimino la promocion',
                    'error'
                )
            }
        })
    }

    const deletePromotionByBrachoffice = async (promotion_id) => {
        let user = JSON.parse(localStorage.getItem('user'))
        let data = {
            "promotion_id": promotion_id
        }
        try {
            const response = await PromotionsFechtAPI.deletePromotionByBrachoffice(branchoffice_id, data, token)
            ErrorAlert('', "Promocion eliminada con exito", "success")
            loadPromotions();
        } catch (err) {
            ErrorAlert(err.data.response.data, "Error, no se pudo remover la promocion", "error")
            console.log(err);
        }
    }

    //Function to listen the swicht
    const handleSwitchChange = async (event) => {
        //We set the data to update
        let data = {
            status: event.target.checked,
            //promotion_id: event.target.id,
            //user_id: user.id
        }
        try {

            if (branchoffice_id != 0) {
                data.promotion_id = event.target.id

                //We update the data
                let response = await PromotionsFechtAPI.updatePromotionBranchoffice(branchoffice_id, data, token)
                console.log(response);
                //We show the success alert
                SuccessAlert(response.data.message);
            } else {
                //We update the data
                let response = await PromotionsFechtAPI.updatePromotionStatus(event.target.id, data, token)
                //We show the success alert
                SuccessAlert(response.data.message);
            }

            // Recarga los productos al cambiar el estado del switch
            loadPromotions();
        } catch (err) {
            //We show the error alert
            ErrorAlert(err.response.data.error);
        }
    }

    const viewPromotion = () => {

        const list = filteredPromotions.map(promotion => (
            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                <article className={promotion.status === 1 ? 'card-on shadow-sm rounded-4 mt-4 p-2' : 'border rounded-4 mt-4 p-2'} >
                    <div
                        className="d-flex justify-content-between align-items-start "
                    >
                        <img
                            className='imgitemsList  border'
                            src={promotion.img}
                            alt={promotion.promotion_name}
                        />
                        <div className="ms-1 me-auto d-inline-block">
                            <div className="fw-bold text-truncate card-item-categorie-title">{promotion.promotion_name}</div>
                            <div className="">Precio: <b>${parseFloat(promotion.price).toFixed(2)}</b></div>
                            <div className="">Impuesto: <b>{promotion.tax}</b></div>
                            <p className='text-secondary fw-light text-truncate card-item-categorie-description'>{promotion.promotion_description}</p>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between align-items-center rounded-3 mt-1'>
                        <div className=' d-flex align-items-center justify-content-center gap-1'>
                            <div className=''>

                                <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_promotions', user.permissions) !== -1}>
                                    <button className='btn btn-outline-light  pulse rounded-3 p-2 lh-1 border'
                                        onClick={async () => beforeDelete(promotion.promotion_id)}
                                    >
                                        <img className='icon-options-cards' src={trash} alt="Delete" />
                                    </button>
                                </RenderIf>
                            </div>

                            <div className=''><ModalListSegmentsByPromotion promotion={promotion}></ModalListSegmentsByPromotion></div>

                            <RenderIf isTrue={checkUserPermission('update_promotions', user.permissions) !== -1}>
                                <div className=''><ModalEditPromotion promotion={promotion} loadPromotions={loadPromotions} ></ModalEditPromotion></div>
                            </RenderIf>

                            <div className=''><ModalViewPromotion promotion={promotion}></ModalViewPromotion></div>


                        </div>
                        <div>
                            <div className='d-flex justify-constent-center align-items-center'>

                                <RenderIf isTrue={checkUserPermission('enable_disable_promotion', user.permissions) !== -1 && branchoffice_id !== 0}>
                                    <Form.Switch
                                        type="switch"
                                        id={promotion.promotion_id}
                                        label=""
                                        checked={promotion.status === 1 ? true : false}
                                        onChange={handleSwitchChange}
                                    />
                                </RenderIf>
                                <RenderIf isTrue={branchoffice_id !== 0}>
                                    {promotion.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                                </RenderIf>
                            </div>
                        </div>
                    </div>

                </article>

                {/* <ListGroup as="ol" className='' >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <img
                            className='imgitemsList '
                            src={promotion.img}
                            alt={promotion.promotion_name}
                        />
                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{promotion.promotion_name}</div>
                            <div className='d-flex justify-constent-center align-items-center'>
                         
                                <RenderIf isTrue={checkUserPermission('enable_disable_promotion', user.permissions) !== -1}>
                                    <Form.Switch
                                        type="switch"
                                        id={promotion.promotion_id}
                                        label=""
                                        checked={promotion.status === 1 ? true : false}
                                        onChange={handleSwitchChange}
                                    />
                                </RenderIf>
                                {promotion.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                            </div>


                            <p className=''>{promotion.promotion_description}</p>
                            <div className=' d-flex align-item-center justify-content-center'>
                                <div className=''><ModalListSegmentsByPromotion promotion={promotion}></ModalListSegmentsByPromotion></div>
                                
                                <RenderIf isTrue={checkUserPermission('update_promotions', user.permissions) !== -1}>
                                    <div className=''><ModalEditPromotion promotion={promotion} loadPromotions={loadPromotions} ></ModalEditPromotion></div>
                                </RenderIf>

                                <div className=''><ModalViewPromotion promotion={promotion}></ModalViewPromotion></div>

                                <div className=''>
                                
                                    <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_promotions', user.permissions) !== -1}>
                                        <button className='btn btn-outline-danger btn-sm border-0'
                                            onClick={async () => beforeDelete(promotion.promotion_id)}
                                        >
                                            <i className="uil uil-trash-alt"></i>
                                        </button>
                                    </RenderIf>
                                </div>

                            </div>
                        </div>
                    </ListGroup.Item>
                </ListGroup> */}
            </Col>


        ))
        return (list)
    }

    return (
        <>
            <div className='mb-5'>
                <div className="row mx-1 mt-3 border rounded-3 bg-section-filters-promo py-2 p-lg-4 sticky-lg-top">
                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4  p-1 order-0 order-lg-0 order-md-0'>
                        <div className='d-flex justify-content-lg-between justify-content-md-start justify-content-center'>
                            <h4 className='fs-4 fw-bold text-left text-white'>
                                {title}
                                <span className='badge rounded-pill text-color-promo shadow-sm rounded-pill mx-1'>
                                    {promotions.length}
                                </span>
                            </h4>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4  p-1 order-2 order-lg-1 order-md-2">
                        <div className="d-flex justify-content-md-center justify-content-center">
                            <form className="w-75 mx-2">
                                <div className="col-auto ">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Buscar producto..."
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-8 col-xl-4 p-1 order-1 order-lg-2 order-md-1">
                        <div className='d-flex  justify-content-md-end justify-content-lg-end  justify-content-center align-items-center gap-1 p-1 '>
                            {/**Validation to create promotions */}
                            <RenderIf isTrue={checkUserPermission('create_promotions', user.permissions) !== -1}>
                                <div className=''>
                                    <ModalCreatePromotion loadPromotions={loadPromotions}></ModalCreatePromotion>
                                </div>
                            </RenderIf>
                            {/**Validation to asing promotion to branchoffices */}
                            <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('asing_branchoffice_promotions', user.permissions) !== -1}>
                                <div className=''><ModalAsingAndRemovedPromotionToBranchoffice branchoffice_id={branchoffice_id} load={loadPromotions} promotionsBranchoffice={promotions} ></ModalAsingAndRemovedPromotionToBranchoffice></div>
                            </RenderIf>
                        </div>
                    </div>
                </div>
                <div className="mt-0 m-0 p-0">
                    <Row>
                        {viewPromotion()}
                    </Row>
                </div>
            </div >
        </>
    );
}

export default PromotionTable;
