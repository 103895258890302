//React Components
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//Utils
import { RenderIf } from '../../utils/RenderIf';
import { checkUserModules } from '../../utils/CheckUserModules';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//API
import { CombosFetchAPI } from '../../../api/Combos';

import pencil from '../../img/icons/pencil.png'

//Modal to edit a combo
function ModalEditCombo({ combo, loadCombo, branchoffice_id, products, user }) {
    //States
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [imgpreview, setImgpreview] = useState(combo.img)
    //Token
    let token = localStorage.getItem("x-access-token")

    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);
        let editCombo = Object.fromEntries(form.entries());
        //We check that the integration module is active
        if (user.business.posIntegration !== undefined) {
            form.append("pos_integration", user.business.posIntegration)
        }
        try {
            //We update the data 
            await CombosFetchAPI.updateCombo(combo.combo_id, editCombo, token);
            //We check if the user want to edit details of the combo on a branchoffice 
            if (branchoffice_id !== 0) {
                //We add the combo id on the edit object
                editCombo.combo_id = combo.combo_id;
                //We update the combo details on the branchoffice
                await CombosFetchAPI.updateComboBranch(editCombo, branchoffice_id, token);
            }
            //We show the success alert 
            ErrorAlert('', 'Combo editado con exito', 'success')
            loadCombo();
            setShowModal(false);
            setImgpreview(combo.img)
        } catch (err) {
            setImgpreview(combo.img)
            ErrorAlert(err.response.data.error, 'Error en la edicion', 'error')
        }
    }

    //Function to change the image
    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }



    //Hook to charge the data when the page is loaded
    useEffect(() => {
        setEventHandle(0)
    }, [eventHandle])




    return (
        <>
            <button type="button" className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border'
                onClick={() => (setShowModal(true))}
            >

                <img className='icon-options-cards' src={pencil} />
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(combo.img))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Editar Combo </h3>
                </Modal.Header>
                <Modal.Body>


                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={imgpreview}
                            alt={combo.name}
                        />
                    </div>

                    <div className='d-flex align-item-center justify-content-center m-3'>
                        <label htmlFor="upload-button">
                            <h5 className="text-center">Actualizar imagen</h5>
                        </label>
                    </div>
                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={combo.name}
                                name="name"

                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Producto de referencia</Form.Label>
                            <Form.Select aria-label="" name="group_id">
                                <option value="">Seleccione un producto</option>
                                {products.map((product, index) => (
                                    <option value={product.group_id}>{product.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Descripcion</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                placeholder={combo.description}
                                name="description"

                            />
                        </Form.Group>
                        <RenderIf isTrue={checkUserModules('pos_integrations_module', user.modules) !== -1 && user.business.posIntegration !== undefined}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Referencia externa</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Referencia externa"
                                    name="external_reference"
                                />
                            </Form.Group>
                        </RenderIf>

                        <RenderIf isTrue={branchoffice_id !== 0}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Favorito/destacado</Form.Label>
                                <Form.Select aria-label="" name="favorite">
                                    <RenderIf isTrue={combo.favorite == 0}>
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                    </RenderIf>
                                    <RenderIf isTrue={combo.favorite == 1}>
                                        <option value={1}>Si</option>
                                        <option value={0}>No</option>
                                    </RenderIf>
                                </Form.Select>
                            </Form.Group>
                        </RenderIf>


                        <RenderIf isTrue={branchoffice_id !== 0}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Habilitar combo para venta cruzada</Form.Label>
                                <Form.Select aria-label="" name="cross_sell" defaultChecked={combo.cross_sell}>
                                    <RenderIf isTrue={combo.cross_sell == 0}>
                                        <option value="0">No</option>
                                        <option value="1">Si</option>
                                    </RenderIf>
                                    <RenderIf isTrue={combo.cross_sell == 1}>
                                        <option value="1">Si</option>
                                        <option value="0">No</option>
                                    </RenderIf>
                                </Form.Select>
                            </Form.Group>
                        </RenderIf>

                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label></Form.Label>
                            <Form.Control
                                id="upload-button"
                                type="file"
                                placeholder="Imagen"
                                name="files"
                                onChange={(e) => changeimg(e)}
                                hidden
                            />
                        </Form.Group>




                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false), setImgpreview(combo.img))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalEditCombo