//React Components
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Components 
import Navbar from '../NavBar/Navbar';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

import { Tabs, Tab } from 'react-bootstrap';

//API 
import VendingTable from './VendingTable';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import SupportBot from '../SupportBot/SupportBot';

const VendingPanel = () => {
    //Params 
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    //States 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0
    });
    //Event handler 
    const [eventHandler, setEventHandler] = useState(0);
    //Token
    let token = localStorage.getItem('x-access-token');

    //Function to get the branch office
    const getBranchoffice = async (code) => {
        try {
            //We search the branch office
            const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, code);
            setSelectedBranch({
                name: branch.data.branchoffice.name,
                code: branch.data.branchoffice.code,
                id: branch.data.branchoffice.id
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        } catch (err) {
            //We set the branch office to 0
            setSelectedBranch({
                code: 0,
                id: 0
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        }
    }


    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Hook to charge the data when the page is loaded 
    useEffect(() => {
        (async () => {
            try {
                console.log(user)
                //We set the branchoffices of the user 
                setUserBranchs(user.branchoffices);
            } catch (err) {
                console.log(err)
            }
        })();
    }, []);

    return (
        <>
            <div className='sp-grid bg-white no-scroll position-relative g-0'>
                <div className=' fixed-column'>
                    <Navbar titlePage="Productos" />
                </div>
                <div className='other-column '>
                </div>
                {/* Branch office data  */}
                <div className='m-2'>
                    <main className=" px-lg-2 px-1  rounded-4 bg-body position-relative">
                        <section className='banner-reports d-lg-flex justify-content-lg-between align-items-center mb-2 
                     rounded-4 p-2 '>
                            <div>
                                <div className='d-flex justify-content-lg-between align-items-center'>
                                    <h2 className='banner-reports-title mb-0 pb-0'>
                                        Vendings:  {selectedBranch.name
                                            ? <>{selectedBranch.name}</>
                                            : <>Todas las sucursales</>}
                                    </h2>
                                </div>
                                <p className='m-0 p-0 banner-reports-description'>Gestión y creación de artículos y elementos para sus sucursales.</p>
                            </div>
                            <div className="mt-2">
                                <select onChange={e => getBranchoffice(e.target.value)} className='form-select' name="user_branch" id="user_branch">
                                    <option value={0}>Mostrar todos</option>
                                    {branchList()}
                                </select>
                            </div>
                        </section>
                    </main>


                    <div className="container ">
                        <div className='mx-lg-3 mt-4'>
                            {selectedBranch ? <>
                                <VendingTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} eventHandler={eventHandler} token={token} user={user} ></VendingTable>
                            </> : ''}
                        </div>

                    </div >
                </div>


            </div >
        </>
    );
}

export default VendingPanel;
