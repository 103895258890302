import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//API
import { BranchOfficeFetchAPI } from '../../../api/BranchOffices';
import { UsersFetchAPI } from '../../../api/Users';
import { OrdersFetchAPI } from '../../../api/Orders';

// External Componets
import moment from 'moment-timezone';
import Select from 'react-select';
//Internal components 
import ReportTable from '../table/ReportTable';
import CardTotals from '../cards/CardTotals';
import SuccessAlert from '../../Alerts/SuccessAlert';
import LoadingAlert from '../../Alerts/LoadingAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
//External components 
import Button from 'react-bootstrap/Button';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs


const DiscountsReport = () => {
    //Params
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    //States 
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [userBusiness, setUserBusiness] = useState({
        id: 0
    });
    const [initialDate, setInitialDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
    const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')
    //States of search select 
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [cashiers, setCashiers] = useState([]);
    const [machineCase, setMachineCase] = useState(false);
    const [vendingCase, setVendingCase] = useState(false);
    const [url, setUrl] = useState(null);

    const [data, setReportData] = useState({
        business: '',
        branchoffice: '',
        machines: [],
        paymenthods: [],
        createdAt: '',
        user: '',
        discounts: [],
        discountDetails: []
    });

    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);
        } catch (err) {
            console.log(err)
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
            setCashiers([])
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Functio to get cashier users depending of the branch selected
    const getCashiers = async (branchSelected) => {
        let response;
        try {
            //We get the users depending the branch selected 
            if (branchSelected === 0) {
                //If the branch selected is 0 it means that we want the users of the business (all)
                //We get the user cashiers of the business
                response = await UsersFetchAPI.getUsersByBusinessAndRole(userBusiness.id, [4]);
            } else {
                //We get the user cashiers of the branch selected
                response = await UsersFetchAPI.getUsersByRoleAndBranchoffice(branchSelected, 4);
            }
            setCashiers(response.data.users)
        } catch (err) {
            setCashiers([])
        }
    }

    //The default option of the select input 
    const defaultOption = [{ label: 'Seleccione...', value: 0 }];

    //Get users (cashiers)
    const usersList = cashiers.map((user) => ({
        label: user.user_name + ' ' + user.lastName + ': ' + user.idCard,
        value: user.user_id
    }));

    //Function to get the report of the branch office 
    const getReport = async (dateMin, dateMax) => {
        let response;
        try {
            //We show a loading alert 
            LoadingAlert();

            //We get the transactions depending of the user selected
            if (selectedUserOption === null) {
                //We get the transaction general info with the admin user logged 
                response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, selectedBranch.code, dateMin, dateMax, 0, user.id, token);
            } else {
                //We get the transaction info with the user selected
                response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, selectedBranch.code, dateMin, dateMax, 0, selectedUserOption.value, token);
            }

            console.log(response)
            //We set the info 
            setReportData({
                business: response.data.analitics.business,
                branchoffice: response.data.analitics.branchoffice,
                machines: response.data.analitics.machines,
                paymenthods: response.data.analitics.paymenthods,
                createdAt: response.data.analitics.createdAt,
                user: response.data.analitics.user_selected,
                discounts: response.data.analitics.discounts,
                discountDetails: response.data.analitics.discountDetails,
                beneficiaryDiscounts: response.data.analitics.beneficiaryDiscounts
            });

            //We show a success alert
            SuccessAlert();
        } catch (err) {
            //We show a error alert 
            ErrorAlert('No tiene información')
            console.log(err)
        }
    }

    //Event to detect the user selected on search select 
    const handleChangeUsers = (option) => {
        //We check if the option is null 
        if (option.value === 0) {
            setSelectedUserOption(null);
        } else {
            setSelectedUserOption(option);
        }
    };

    //Function to populate the discounts applied 
    const tableDiscounts = () => {
        let rows = []
        //We construc the table 
        for (let i = 0; i < data.discounts.length; i++) {
            let discountInfo = data.discounts[i];
            let discount_row = [
                //Column 1
                discountInfo.name,
                //Column 2
                ' $ ' + discountInfo.total.toFixed(2)
            ]

            rows.push(discount_row)
        }

        let table = {
            style: 'tableExample',
            table: {
                widths: [200, '*'],

                headerRows: 1,
                // dontBreakRows: true,
                // keepWithHeaderRows: 1,
                body: [
                    [

                        //Tables Headers 
                        { text: 'Descuento', style: 'tableHeader2' },
                        { text: 'Total', style: 'tableHeader2' }],
                    ...rows
                ]
            }, layout: 'lightHorizontalLines'
        }
        //We return the table 
        return table
    }

    //Function to populate the beneficiary discounts applied 
    const tableBeneficiaryDiscounts = () => {
        let rows = []
        //We construc the table 
        for (let i = 0; i < data.beneficiaryDiscounts.length; i++) {
            let discountInfo = data.beneficiaryDiscounts[i];
            let discount_row = [
                //Column 1
                discountInfo.name,
                //Column 2
                ' $ ' + discountInfo.total.toFixed(2)
            ]

            rows.push(discount_row)
        }

        let table = {
            style: 'tableExample',
            table: {
                widths: [200, '*'],

                headerRows: 1,
                // dontBreakRows: true,
                // keepWithHeaderRows: 1,
                body: [
                    [

                        //Tables Headers 
                        { text: 'Beneficiario', style: 'tableHeader2' },
                        { text: 'Descuentos totales aplicados al beneficiario', style: 'tableHeader2' }],
                    ...rows
                ]
            }, layout: 'lightHorizontalLines'
        }
        //We return the table 
        return table
    }

    //Function to populate the discounts details applied 
    const tableDiscountsDetails = () => {
        let rows = []
        //We construc the table 
        for (let i = 0; i < data.discountDetails.length; i++) {
            let discountInfo = data.discountDetails[i];
            let discount_row = [
                //Date 
                { text: discountInfo.createdAt.split('T')[0], style: 'cells2' },                
                // Hour
                { text: discountInfo.createdAt.split('T')[1].split(':')[0] + ':' + discountInfo.createdAt.split('T')[1].split(':')[1] + ' ' + (discountInfo.createdAt.split('T')[1].split(':')[0] <= 11 ? 'AM' : 'PM'), style: 'cells2' },
                //Orden       
                { text:discountInfo.transaction_code, style: 'cells2' },
                //Discount 1
                { text: discountInfo.discount, style: 'cells2' },
                
                //Column 2
                { text: ' $ ' + discountInfo.total.toFixed(2), style: 'cells2' },
                
                // Aprobed by 
                { text:discountInfo.approvedby, style: 'cells2' },
                
                // Beneficiary 
                { text: discountInfo.user === null && discountInfo.client === null ? 'Cliente generico' : discountInfo.user !== null && discountInfo.client === null ? discountInfo.user : discountInfo.user === null && discountInfo.client !== null ? discountInfo.client : 'Cliente generico', style: 'cells2' },
                

            ]

            rows.push(discount_row)
        }

        let table = {
            style: 'tableExample',
            table: {                
                headerRows: 1,
                widths: ['*', 'auto', '*',50, 50,'auto', 'auto'],
                // dontBreakRows: true,
                // keepWithHeaderRows: 1,
                body: [
                    [

                        //Tables Headers 
                        { text: 'Fecha', style: 'tableHeader2' },
                        { text: 'Hora', style: 'tableHeader2' },
                        { text: 'Orden', style: 'tableHeader2' },
                        { text: 'Tipo', style: 'tableHeader2' },
                        { text: 'Total', style: 'tableHeader2' },
                        { text: 'Autorizado por', style: 'tableHeader2' },
                        { text: 'Beneficiario', style: 'tableHeader2' }

                    ],

                    ...rows
                ]
            }, layout: 'lightHorizontalLines'
        }
        //We return the table 
        return table
    }
    //We create the pdf 
    const createPdf = () => {

        let discounts = []
        let date = ''
        let hour = '';

        if (data.length !== 0) {
            date = data.createdAt.split('T')[0];
            hour = data.createdAt.split('T')[1];
        }
        //Information
        let name_bussiness = data.business
        let name_branch = data.branchoffice

        const docDefinition = {
            pageSize: 'A4',
            pageMargins: [23, 10, 23, 5],

            content: [
                //?IMG
                {
                    "image": "logo-smartpay",
                    width: 90,
                },
                //?Title Report 
                {
                    stack: ['Reportes de Descuentos  ',],
                    style: 'header'
                },
                // {
                //     margin: [0, 0, 0, 10],
                //     layout: {
                //         fillColor: function (rowIndex, node, columnIndex) {
                //             return (rowIndex % 2 === 0) ? '#ebebeb' : '#f5f5f5';
                //         }
                //     },

                //     table: {
                //         widths: ['100%'],
                //         heights: [0, 10],
                //         body: [
                //             [                              
                //                 {
                //                     text:
                //                         [
                //                             { text: 'Empresa:' },
                //                             name_bussiness

                //                         ], style: 'subheader',
                //                 },
                //             ],
                //             [
                //                 {
                //                     text: 'FUNÇÃO: DIRETOR DE ENSINO',
                //                     fontSize: 9,
                //                     bold: true
                //                 }
                //             ],
                //         ],
                //     }
                // },
                {
                    style: 'tableExample',
                    layout: {
                        fillColor: function (rowIndex) {
                            return (rowIndex === 0) ? '#0c4c80' : null;
                        }
                    },
                    table: {
                        widths: ['*', '10%', '25%', '35%'],                 
                        headerRows: 1,
                        body: [
                            [
                                //Information Bussiness
                                //? 1 --------------------------
                                {
                                    text:
                                        [
                                            {
                                                text:'Empresa: ',

                                            },
                                            name_bussiness

                                        ], colSpan: 4,
                                    bold: true,
                                    fontSize: 12,
                                    color: 'white',
                                    border: [false, false, false, false],                                    
                                },
                                {
                                },
                                {

                                },
                                {
                                }
                            ],

                        ]
                    }
                },

                {
                   
                    table: {
                        widths: ['*', '*', '*', '*'],
                        headerRows: 1,
                        // dontBreakRows: true,
                        // keepWithHeaderRows: 1,
                        body: [
                            //Values of Reports 
                            [
                                //Tables Headers 
                                {
                                    text: 'Sucursal',
                                    style: 'tableHeader',
                                    border: [false, false, true, false],
                                },
                                {
                                    text: 'Usuario de caja',
                                    style: 'tableHeader',
                                    border: [false, false, true, false],
                                },
                                {
                                    text: 'Fecha',
                                    style: 'tableHeader',
                                    border: [false, false, true, false],
                                },
                                {
                                    text: 'Hora',
                                    style: 'tableHeader',
                                    border: [false, false, true, false],
                                }
                            ],
                            [
                                //Column 1
                                {                                
                                    text: name_branch !== '' ? name_branch : '-------',
                                    margin: [0, 0, 0, 5],
                                    border: [false, false, true, false],
                                    fillColor: '#eeffee',
                                    style: 'cells',
                                },

                                //Column 2
                                {                                
                                    text: data.user.name !== '' ? '' + data.user.name + data.user.lastName : '-------',
                                    margin: [0, 0, 0, 5],
                                    border: [false, false, true, false],
                                    fillColor: '#eeffee',
                                    style: 'cells',
                                },
                                

                                //Column 3
                                {                                
                                    text: date,
                                    margin: [0, 0, 0, 5],
                                    border: [false, false, true, false],
                                    fillColor: '#eeffee',
                                    style: 'cells',
                                },


                                //Column 4
                                {                                
                                    text: hour,
                                    margin: [0, 0, 0, 5],
                                    border: [false, false, true, false],
                                    fillColor: '#eeffee',
                                    style: 'cells',
                                },

                            ]
                        ]
                    }, layout: 'lightHorizontalLines'
                },

             
                //?Title Of Table
                {
                    text: 'Descuentos Aplicados',
                    style: 'titles', margin: [0, 5, 0, 0]
                },

                tableDiscountsDetails(),
                //?Title Of Table
                {
                    text: 'Totales por descuento aplicado',
                    style: 'titles', margin: [0, 15, 0, 0]
                },
                //Discounts table 
                tableDiscounts(),

                //?Title Of Table
                {
                    text: 'Totales de descuentos por cliente',
                    style: 'titles', margin: [0, 15, 0, 0]
                },

                //Beneficiary Discounts table 
                tableBeneficiaryDiscounts(),

            ],

            images: {
                "logo-smartpay": "https://res.cloudinary.com/dk3x6ubyi/image/upload/v1682698324/hi2vihfdtgg98ybwbxnn.png"
            },
            //!Styles  Document Discound
            styles: {
                header: {
                    fontSize: 20,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 5, 0, 10],
                },
                titles: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 10,
                    bold: true,
                },
                subheaderRight: {
                    fontSize: 12,
                    alignment: 'right',
                    color: '#A8A8A8'
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    fillColor: '#092b08',
                    fontSize: 10,
                    color: 'white',
                    bold: true,
                },

                tableHeader2: {
                    fillColor: '#09599b',
                    fontSize: 10,
                    color: 'white',
                    bold: true,
                },

                tableHeader3: {
                    fillColor: '#266DBF',
                    fontSize: 13,
                    color: 'white',
                    bold: true,
                },

                cells: {
                    fillColor: '#266DBF',
                    fontSize: 10,
                    color: '#092b08',
                    bold: true,
                },
                cells2: {
                    fillColor: '#f1f8fe',
                    fontSize: 9,
                    color: '#0c4c80',
                    bold: true,
                }
            }

        }

        const pdfGenerator = pdfMake.createPdf(docDefinition);
        pdfGenerator.getBlob((blob) => {
            const url = URL.createObjectURL(blob);
            setUrl(url)

        })

        pdfGenerator.download();
    }
    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        //We set the business user 
        setUserBusiness(user.business);
    }, []);

    //Hook to load the users depending the selection of branch 
    useEffect(() => {
        //We get the users 
        getCashiers(selectedBranch.id)
    }, [selectedBranch]);


    return (
        <div>
            <h4>Filtros</h4>
            <div className="row gx-3 gy-2 align-items-center bg-white p-2 my-3 border-sp border shadow-sm">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                        <label htmlFor="input_from">Sucursal</label>
                        <select onChange={e => getBranchoffice(business_code, e.target.value)} className='form-select' name="branch" id="branch">
                            <option value={0}>Mostrar todos</option>
                            {branchList()}
                        </select>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Desde</label>
                        <input type="date" name="initialDate" onChange={e => setInitialDate(e.target.value + "T00:00:00.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Hasta</label>
                        <input type="date" name="finishDate" onChange={e => setMaxDate(e.target.value + "T23:59:59.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <label htmlFor="input_from">Cajeros (opcional)</label>
                    <Select
                        className='w-100'
                        value={selectedUserOption}
                        options={[defaultOption[0], ...usersList]}
                        onChange={handleChangeUsers}
                    />

                </div>
                <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                    <button type="button" onClick={e => getReport(initialDate, maxDate)} className="btn btn-success px-5">Aceptar</button>
                </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
                <h5 className="mt-4">Reporte de descuentos</h5>
                <div className="mt-3 mb-2">
                    <a href={`${process.env.REACT_APP_API_URL}/excel/discounts/${initialDate}/${maxDate}/${selectedBranch.id}/${user.business.id}/download`} className="btn btn-success">
                        <i className="fa-solid fa-file-excel"></i>
                        Descargar Excel</a>
                    <Button variant="outline-secondary" className='mx-3' onClick={createPdf}>
                        <i className="uil uil-file-download "></i> <span >Descargar PDF</span>
                    </Button>
                </div>

            </div>

            {/**Table */}
            <ReportTable
                table_name="Tabla de descuentos aprobados"
                table_headers={["Fecha", "Hora", "Orden", "Total de descuento", "Aprobado por", "Beneficiario"]}
                table_type="discount_report"
                table_data={data.discountDetails}
            />
        </div>



    );
}

export default DiscountsReport;