
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})


//Class to manage the fetch to the API of the set groups
export class SetGroupsFetchAPI {

    //Function to create a set group
    static async createSetGroup(data, token) {
        const res = await server('/set', {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'application/json', 'x-access-token': token },
        });
        return res
    }
    //Function to update a set group 
    static async updateSetGroup(data, set_id, token) {
        const res = await server(`/set/${set_id}`, {
            method: "put",
            data: data,
            headers: { 'Content-Type': 'application/json', 'x-access-token': token },
        });
        return res
    }

    //Function to get set group by id
    static async getSetGroupById(set_id, token) {
        const res = await server(`/set/${set_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get the set groups by combo
    static async getSetGroupsByCombo(combo_id, token) {
        const res = await server(`/set_per_combo/${combo_id}/combo`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get set groups by business 
    static async getSetGroupsByBusiness(business_id, token) {
        const res = await server(`set?business_id=${business_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to asing set group to combo
    static async asingSetGroupToCombo(data,combo_id, token) {
        const res = await server(`/set_per_combo/${combo_id}/combo`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'application/json', 'x-access-token': token },
        });
        return res
    }

    //Function to remove set group from combo
    static async removeSetGroupFromCombo(combo_id, data, token) {
        const res = await server(`/set_per_combo/${combo_id}/combo`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to asing group to set group on a combo
    static async asingGroupToSetGroup(data, combo_id, set_id, token) {
        const res = await server(`/set_per_combo/${combo_id}/combo/${set_id}/set`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'application/json', 'x-access-token': token },
        });
        return res
    }


    //Function to remove group from set group on a combo
    static async removeGroupFromSetGroup(combo_id, set_id, data, token) {
        const res = await server(`/set_per_combo/${combo_id}/combo/${set_id}/set`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }
}