//React components
import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//Utils
import { RenderIf } from '../../utils/RenderIf';

//This component is used to show the modal to comfirm a buy or show details of a buy
const BuyModal = ({ buySelected, voidBuy, voidedBuyDetails, buttonModal, modalTitle, modalType, setShowModal, showModal, selectedBranch, productsList, invoiceDiscount, invoiceSubtotal, invoiceTax, invoiceTotal, newBuy }) => {

    // Function to show the modal
    const handleShowModal = (e) => {
        console.log('se hizo click')
        e.preventDefault()
        //We validate that the user select a branch
        if (selectedBranch.id === 0) {
            ErrorAlert('Seleccione una sucursal', 'Error', 'warning')
        }
        //We validate that the user has products to buy
        if (productsList.length === 0) {
            ErrorAlert('No hay productos para comprar', 'Error', 'warning')
        }
        //We show the modal if the user has selected a branch and has products to buy
        if (selectedBranch.id !== 0 && productsList.length !== 0) {
            setShowModal(true);
        }
    }

    // function to close the modal
    const handleCloseModal = () => setShowModal(false);

    //Return the component
    return (
        <>
            <RenderIf isTrue={buttonModal === true}>
                <div className="d-flex justify-content-center justify-content-sm-end mb-3 mt-2">
                    <button type="button" onClick={(e) => {
                        handleShowModal(e);
                    }} className="btn btn-continue px-5">Aceptar</button>
                </div>
            </RenderIf>

            <Modal size='xl' show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle === undefined ? "" : modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <div class="main">
                        <div class="container mt-3">
                            <div class="card border">

                                <div class="card-body">
                                    <div class="row mb-4">
                                        <div>
                                            <h5 className='text-primary fw-bold'>{modalType === "void" ? "Detalles de la anulación" : "Detalles de Compra"}</h5>
                                            <RenderIf isTrue={modalType === "void" && voidedBuyDetails !== null}>
                                                <p><strong>Compra anulada: #{voidedBuyDetails.invoice} </strong></p>
                                            </RenderIf>
                                            <p><strong>Sucursal:</strong> {selectedBranch.name}</p>
                                            {/* <p><strong>Proveedor:</strong> {proveedor}</p> */}
                                            {/* <p><strong>Fecha de Compra:</strong> {date}</p> */}
                                            {/*  <p><strong>Número de Factura:</strong> {numeroFactura}</p> */}
                                        </div>
                                    </div>
                                    <h5 className='text-primary fw-bold'>Productos</h5>
                                    <div class="table-responsive-sm">
                                        <table class="table table-sm table-striped border ">
                                            <thead className='table-dark'>
                                                <tr>
                                                    <th scope="col" width="30%" class="center">Producto/Servicio</th>
                                                    <th scope="col" width="20%">Código <small>(upc/ean)</small> </th>
                                                    <th scope="col" class="text-right" width="8%">Cantidad</th>

                                                    <th scope="col" width="10%" class="text-right">P. Unidad</th>
                                                    <th scope="col" width="8%" class="text-right">Impuesto</th>
                                                    <th scope="col" width="8%" class="text-right">Descuento</th>
                                                    <th scope="col" width="10%" class="text-right">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productsList.map((product, index) => (
                                                    <tr key={index}>
                                                        <td>{product.name}</td>
                                                        <td>{product.code === null || product.code === '' ? 'No ingresado' : product.code}</td>
                                                        <td>{product.cant}</td>
                                                        <td>${product.price}</td>
                                                        <td>{product.tax}</td>
                                                        <td>${product.unit_discount.toFixed(2)}</td>
                                                        <td>${product.total_product.toFixed(2)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">

                                        <div class="col-lg-4 col-sm-5 ms-auto">
                                            <table class="table table-sm   border rounded-3 ">
                                                <tbody>
                                                    <tr className='d-flex justify-content-between border' >
                                                        <td class="">
                                                            <strong>Subtotal</strong>
                                                        </td>
                                                        <td>${invoiceSubtotal.toFixed(2)}</td>
                                                    </tr>
                                                    <tr  className='d-flex justify-content-between border'>
                                                        <td class="">
                                                            <strong>Descuento</strong>
                                                        </td>
                                                        <td>${invoiceDiscount.toFixed(2)}</td>
                                                    </tr>
                                                    <tr className='d-flex justify-content-between border'>
                                                        <td class="">
                                                            <strong>Impuesto</strong>
                                                        </td>
                                                        <td cl>${invoiceTax.toFixed(2)}</td>
                                                    </tr>
                                                    <tr className='d-flex justify-content-between border'>
                                                        <td class="">
                                                            <strong>Total</strong>
                                                        </td>
                                                        <td>
                                                            <strong>${invoiceTotal.toFixed(2)}</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div class="footer container-fl>
                        <div class="row">
                            <div class="col footer-app">&copy; Todos los derechos reservados · <span class="brand-name"></span></div>
                        </div>
                    </div> */}
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseModal}>
                        {modalType === "buy_confirmation" ? "Cancelar" : "Cerrar"}
                    </Button>
                    {/** Void button */}
                    <RenderIf isTrue={modalType === "details"}>
                        <Button variant="danger" onClick={() => voidBuy(buySelected)}>
                            Anular Compra
                        </Button>
                    </RenderIf>
                    {/** Buy confirmation button */}
                    <RenderIf isTrue={modalType === "buy_confirmation"}>
                        <Button variant="primary" onClick={() => newBuy()}>
                            Confirmar Compra
                        </Button>
                    </RenderIf>

                </Modal.Footer>
            </Modal>
        </>

    );
}

export default BuyModal;
