import React, { useEffect, useState } from 'react';

//Internal Components
import Navbar from '../NavBar/Navbar';
import GeneralReport from './tabs/GeneralReports';
//Components 
import { Tabs, Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import PaymentMethodReports from './tabs/PaymentMethodReports';
import ProductSalesReport from './tabs/ProductSalesReport';
import BuysAndSalesReport from './tabs/BuysAndSalesReport';
import PreAccountReport from './tabs/PreAccountReport';
import DiscountsReport from './tabs/DiscountsReport';
//CSS
import "./reports.css"



const ReportStore = () => {

    return (
        <>
            <div className='sp-grid bg-white no-scroll position-relative g-0'>
                <div className=' fixed-column'>
                    <Navbar titlePage="Informes" />
                </div>
                <div className='other-column '>
                </div>
                {/** Main container */}

                <main className="m-2">
                    {/** Header */}
                    <header className='banner-reports d-flex justify-content-lg-between align-items-center mb-4 banner-sp rounded-4 py-2 px-3'>
                        <div>
                            <div className='d-flex justify-content-lg-between align-items-center'>
                                <h2 className='banner-reports-title mb-0 pb-0'>
                                    Reportes
                                </h2>
                            </div>
                            <p className='m-0 p-0 banner-reports-description'>Visualización de datos clave para análisis.</p>
                        </div>
                    </header>
                    {/** End Header  */}
                    {/**Tabs */}
                    <Tab.Container defaultActiveKey="General">
                        <Row>
                            <Col sm={12} >
                                <Nav variant="underline" className="d-flex horizontal-scroll border-bottom  pe-3 ">
                                    <li className="nav-sp-item"  >
                                        <Nav.Link eventKey="General" title="General">
                                            <i className="fa-solid fa-house me-1"></i>
                                            General
                                        </Nav.Link>
                                    </li>
                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="combo"> </Nav.Link>
                                    </li>

                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="payments" >
                                            <i className="fa-solid fa-money-check-dollar  me-1"></i> Métodos de Pago
                                        </Nav.Link>
                                    </li>

                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="products" ><i className="fa-solid fa-bottle-water  me-1"></i> 
                                        Productos</Nav.Link>
                                    </li>

                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="buysales" ><i className="fa-solid uil-shopping-bag  me-1"></i> 
                                        Entrada y Salida de Productos</Nav.Link>
                                    </li>

                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="preaccount" ><i className="fa-solid uil-shopping-bag  me-1"></i> 
                                        Pre-Cuentas Eliminadas</Nav.Link>
                                    </li>

                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="discount" ><i className="fa-solid uil-shopping-bag  me-1"></i> 
                                        Descuentos Aprobados</Nav.Link>
                                    </li>
                                           
                                </Nav>
                            </Col>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="General">
                                        <div className=' m-0'>
                                            <GeneralReport />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="payments">
                                        <div className=' m-0'>
                                            <PaymentMethodReports />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="products">
                                        <div className=' m-0'>
                                            <ProductSalesReport />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="buysales">
                                        <div className=' m-0'>
                                            <BuysAndSalesReport />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="preaccount">
                                        <div className=' m-0'>
                                            <PreAccountReport />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="discount">
                                        <div className=' m-0'>
                                            <DiscountsReport />
                                        </div>
                                    </Tab.Pane>
                             
                             

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </main>

            </div>
        </>
    );
}

export default ReportStore;
