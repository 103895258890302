//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import { Form } from 'react-bootstrap';
import Select from 'react-select'
import { Modal } from 'react-bootstrap';
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { WarehouseFetchAPI } from '../../api/Warehouse';
import { GroupsFechtAPI } from '../../api/Groups';
import { InventoryFetchAPI } from '../../api/Inventory';

//Internal components 
import NavBar from '../NavBar/Navbar';
import Table from 'react-bootstrap/Table';
//Modals 
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
const InAndExit = ({ Type }) => {

    let { business_code } = useParams();
    //We get the user data 
    const [showModalExit, setShowModalExit] = useState(false)
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [eventHandler, setEventHandler] = useState(0)
    const [warehouses, setWarehouses] = useState([])
    const [warehouseselected, setWarehouseSelected] = useState(0);
    const [groups, setGroups] = useState([])
    const [groupsSelected, setgroupsSelected] = useState([])
    const [groupNow, setGroupNow] = useState({})
    const [warehouseDestination, setWarehouseDestination] = useState(0)
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [reason, setReason] = useState('')
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });

    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);

            getWarehouseByBrachoffice(response.data.branchoffice.id)

            if (Type == "In") {
                getGroups(response.data.branchoffice.id)
            }

        } catch (err) {
            console.log(err)
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to populate the warehouses
    const warehouseList = () => {
        const listWarehouse = warehouses.map((warehouse) => (
            <>
                <option value={warehouse.id}>{warehouse.name}</option>
            </>
        ));
        return listWarehouse
    }

    const listGroupsInventory = () => {
        const listGroups = groupsSelected.map((group, index) => (
            <>
                <tr key={1}>
                    <td>{Type == "In" ? group.name : group.group_name}</td>
                    {Type != "In" ? <><td>{group.quantityNow}</td></> : <></>}
                    <td>
                        <input
                            id={group.group_id}
                            type='number'
                            defaultValue={1}
                            className='form-control'
                            onChange={(e) => handleChangeInventory(group, index, e.target.value)}
                        />
                    </td>

                    <td>
                        <button type="button" className="btn btn-outline-danger " onClick={() => deleteGroup(group)}>
                            <i class="uil uil-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            </>
        ));
        return listGroups
    }

    //Function get warehouse
    const getWarehouseByBrachoffice = async (branchoffice_id) => {
        try {
            const response = await WarehouseFetchAPI.getwarehousebybracnhoffice(branchoffice_id, 0, token)
            console.log(response);
            setWarehouses(response.data.warehouses)
            setEventHandler(1)

        } catch (err) {
            console.log(err);
            setWarehouses([])
        }
    }

    //Function add list groups to inventory
    const addGroups = () => {

        if (Type == "In") {
            add()
        } else {
            if (groupNow.quantity > 0) {
                add()
            } else {
                ErrorAlert("", "Este producto no tiene inventario", 'warning')
            }
        }

        function add() {
            let validGroup = groupsSelected.find((group) => group.group_id == groupNow.group_id)
            if (groupNow.group_id) {
                if (validGroup == undefined) {
                    groupNow.quantity = "1"
                    setgroupsSelected([...groupsSelected, groupNow])
                }
            }
        }

    }

    //Funtion delete group of groups selected
    const deleteGroup = (groupDelete) => {

        let filterGroup = groupsSelected.filter((group) => group.group_id != groupDelete.group_id)

        console.log(filterGroup);
        console.log(groupDelete);
        setgroupsSelected(filterGroup)
    }

    //Function get groups by branchoffice
    const getGroups = async (branchoffice_id) => {
        try {
            const response = await GroupsFechtAPI.getGroupsByBranchoffice(branchoffice_id, token)
            let groupInfo = []
            response.data.data.forEach(group => {
                groupInfo.push({ value: JSON.stringify(group), label: group.name })
            });
            setGroups(groupInfo)
            setEventHandler(1)

        } catch (err) {
            console.log(err);
            setGroups([])
        }
    }

    //Function update inventory
    const inInventory = async () => {
        if (warehouseselected != 0) {
            let newArrayGroups = []
            groupsSelected.forEach(group => {
                let dataNow = { "group_id": group.group_id, "quantity": group.quantity }
                newArrayGroups.push(dataNow)
            })

            console.log(newArrayGroups);

            let data = {
                "warehouse_id": warehouseselected,
                "products": newArrayGroups,
                "user_id": user.id
            }

            try {
                const response = await InventoryFetchAPI.receiveInventory(data, token)
                ErrorAlert('', 'Inventario ingresado con exito', 'success')
                setgroupsSelected([])

                console.log(response);
            } catch (err) {
                ErrorAlert(err.response.data.error, 'Error de creacion', 'error')
            }
        } else {
            ErrorAlert("", 'Debes seleccionar una bodega', 'warning')
        }
    }

    //Function exit inventory
    const exitInventory = async () => {
        if (warehouseselected != 0) {
            if (reason != "") {
                let newArrayGroups = []
                groupsSelected.forEach(group => {
                    let dataNow = { "group_id": group.group_id, "quantity": group.quantity }
                    newArrayGroups.push(dataNow)
                })

                console.log(newArrayGroups);

                let data = {
                    "warehouse_id": warehouseselected,
                    "products": newArrayGroups,
                    "user_id": user.id,
                    "reason": reason
                }

                try {
                    const response = await InventoryFetchAPI.sendInventory(data, token)
                    ErrorAlert('', 'Salida de inventario registrado con exito', 'success')
                    setgroupsSelected([])
                    setReason('')
                    setShowModalExit(false)

                    console.log(response);
                } catch (err) {
                    ErrorAlert(err.response.data.error, 'Error de registro', 'error')

                }

            } else {
                ErrorAlert("", 'Debes ingresar un concepto de salida', 'warning')
            }
        } else {
            ErrorAlert("", 'Debes seleccionar una bodega', 'warning')
        }
    }

    //Function to transfer inventory between warehouses
    const transferInventory = async () => {
        if (warehouseselected != 0) {
            if (warehouseDestination != 0) {
                let newArrayGroups = []
                groupsSelected.forEach(group => {
                    let dataNow = { "group_id": group.group_id, "quantity": group.quantity }
                    newArrayGroups.push(dataNow)
                })

                let data = {
                    "source_warehouse_id": warehouseselected,
                    "destination_warehouse_id": warehouseDestination,
                    "products": newArrayGroups,
                    "user_id": user.id
                }

                try {
                    const response = await InventoryFetchAPI.transferInventory(data, token)
                    ErrorAlert('', 'Transferencia realizada con exito', 'success')
                    setgroupsSelected([])

                    console.log(response);
                } catch (err) {
                    ErrorAlert(err.response.data.error, 'Error de transferencia', 'error')
                }
            } else {
                ErrorAlert("", 'Debes seleccionar una bodega de destino', 'warning')
            }
        } else {
            ErrorAlert("", 'Debes seleccionar una bodega raiz', 'warning')
        }
    }

    //get products by warehouse
    const getInventoryByWarehouse = async (warehouse_id) => {
        try {
            const response = await InventoryFetchAPI.getInventoryBywarehouse(warehouse_id, token)
            console.log(response.data.inventory);

            let groupInfo = []
            response.data.inventory.forEach(group => {
                group.quantityNow = group.quantity
                groupInfo.push({ value: JSON.stringify(group), label: group.group_name + " Cantidad: " + group.quantity })

            });
            setGroups(groupInfo)
            console.log(response.data.inventory);
        } catch (err) {
            setGroups([])
            ErrorAlert(err.response.data.message, 'Error de consulta', 'error')
            console.log(err);
        }


    }

    //Change warehouse selected
    const handleChangeWarehouseSelected = (e) => {
        setWarehouseSelected(e.target.value)
        if (Type == "Exit" || Type == "Transfer") {
            if (e.target.value != 0) { getInventoryByWarehouse(e.target.value) }
        }
    }

    //Add quantity to product
    const handleChangeInventory = (group, index, quantity) => {

        console.log(Type);

        console.log(group.quantityNow);
        console.log(quantity);
        let quantityType = 1
        if (quantity != "") {
            quantityType = quantity
        }
        if (Type == "In") {
            console.log("Entre");
            addGroupNow()
        } else {
            

            if (parseInt(group.quantityNow) >= parseInt(quantity)) {
                addGroupNow()
            } else {
                console.log("Entre exit hjgkjhg");
                let valueInput = document.getElementById(group.group_id)
                if (valueInput.value != "") {
                    console.log("Entre exit ----");
                    
                    ErrorAlert("", "La cantidad ingresada no puede ser mayor a la cantidad actual", 'warning')
                }


            }
        }


        function addGroupNow() {
            let newGroups = groupsSelected.map((group, i) => {
                if (i === index) {
                    return { ...group, quantity: quantityType }
                } else {
                    return group
                }
            })
            setgroupsSelected(newGroups)
        }

    }

    const handleSaveTableSelect = (option) => {
        setGroupNow(JSON.parse(option.value));
    }

    const handleActionButton = () => {

        groupsSelected.forEach(group => {
            if (group.quantity == "" || group.quantity == undefined || group.quantity == null) {
                group.quantity = 1
            }
        })

        if (Type == "Exit") { setShowModalExit(true) } else if (Type == "Transfer") {
            transferInventory()
        } else {
            inInventory()
        }
    }

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);

    }, []);

    useEffect(() => {
        setEventHandler(0)
    }, [eventHandler]);

    useEffect(() => {
    }, [groupsSelected]);

    return (
        <>
            <div className='m-3'>
                <div className='d-flex justify-content-between'>
                    <h4>{Type == "In" ? <><i class="uil uil-sign-in-alt"></i> Entrada inventario</> : <> {Type == "Transfer" ? <><i class="uil uil-exchange"></i> Transferencia de inventario</> : <><i class="uil uil-sign-out-alt"></i> Salida de inventario</>}</>} </h4>

                    <small><b className='text-danger'>*</b>
                        <span className='text-secondary ms-1'>
                            Campos Obligatorios
                        </span>
                    </small>
                </div>

                <form className="row  bg-white p-0 p-lg-2  mt-1 border-sp border ">
                    <div className='col-12 mb-2 mt-1'>
                        <span className='h5 text-primary-emphasis fw-bold '>
                            Detalle de almacenamiento
                        </span>
                    </div>
                    <div className="col-6 col-sm-4 col-md-4  col-lg-4   mt-1">
                        <div className="form-group">
                            <label htmlFor="input_from">Sucursal <b className='text-danger'> * </b></label>
                            <select onChange={e => (getBranchoffice(business_code, e.target.value))} className='form-select' name="branch" id="branch">
                                <option value={0}>Seleccione una sucursal</option>
                                {branchList()}
                            </select>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-4  col-lg-4   mt-1">
                        <div className="form-group">
                            <label htmlFor="input_from">Bodega {Type == "Transfer" ? <>raiz</> : <></>}<b className='text-danger'> * </b></label>
                            <select className='form-select' name="branch" id="branch" onChange={(e) => handleChangeWarehouseSelected(e)}>
                                <option value={0}>Seleccione...</option>
                                {warehouseList()}
                            </select>
                        </div>
                    </div>

                    {Type == "Transfer" ? <>
                        <div className="col-6 col-sm-4 col-md-4  col-lg-4   mt-1">
                            <div className="form-group">
                                <label htmlFor="input_from">Bodega destino<b className='text-danger'> * </b></label>
                                <select className='form-select' name="branch" id="branch" onChange={(e) => setWarehouseDestination(e.target.value)}>
                                    <option value={0}>Seleccione...</option>
                                    {warehouseList()}
                                </select>
                            </div>
                        </div></> : <></>}

                    <div className='col-12  mb-2 mt-lg-3 mt-1'>
                        <div className=' opacity-25'>
                            <hr />
                        </div>
                        <span className='h5 text-primary-emphasis fw-bold '>
                            {Type == "Transfer" ? <>Detalles de productos de bodega raiz</> : <>Detalle del producto</>}
                        </span>
                    </div>

                    <div className="col-6 col-sm-4 col-md-4 col-lg-4   mt-1">
                        <div className="form-group">
                            <label htmlFor="input_from">Producto<b className='text-danger'> * </b></label>
                            {/**Product select  */}
                            <div className=''>
                                <Select
                                    className='w-100'
                                    options={groups}
                                    onChange={handleSaveTableSelect}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-12  mt-lg-12 mt-3  ">
                        <div className='d-flex justify-content-end'>
                            <button type="button" className='btn btn-primary' onClick={() => addGroups()}>Agregar Producto </button>
                        </div>
                    </div>


                    {groupsSelected.length > 0 ? <>
                        <div className='col-12 mb-2 mt-1'>
                            <div className=' opacity-25'>
                                <hr />
                            </div>
                            <span className='h5 text-primary-emphasis fw-bold '>
                                Productos agregador
                            </span>
                        </div>

                        {/**Product table  */}
                        <Table responsive>
                            <thead className='table-dark'>
                                <tr>
                                    <th>Producto</th>
                                    {Type != "In" ? <><th>Cantidad actual</th></> : <></>}
                                    <th>Cantidad ingresada</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>

                                {listGroupsInventory()}


                            </tbody>
                        </Table>



                        <div className="col-12 col-sm-4 col-md-4 col-lg-12  mt-lg-12   ">
                            <div className='d-flex justify-content-end m-1'>

                                <button type="button" className='btn btn-primary' onClick={() => handleActionButton()}>
                                    {Type == "In" ? <> Guardar inventario </> : <>{Type == "Transfer" ? <> Tranferir inventario</> : <>Registro de salida</>}</>}</button>
                            </div>
                        </div></> : <></>}

                </form>
            </div>



            <Modal
                show={showModalExit}
                onHide={() => (setShowModalExit(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i class="uil uil-file-shield-alt"></i>Concepto de la salida</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="m-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Describa el concepto de la salida:</Form.Label>
                            <Form.Control as="textarea" rows={1} onChange={(e) => setReason(e.target.value)} />
                        </Form.Group>
                    </Form>

                    <div className="col-12 col-sm-4 col-md-4 col-lg-12  mt-lg-12   ">
                        <div className='d-flex justify-content-between m-1'>
                            <button type="button" className='btn btn-danger' onClick={() => setShowModalExit(false)}>Cerrar</button>
                            <button type="button" className='btn btn-primary' onClick={() => exitInventory()} >Guardar</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default InAndExit;
