import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
//Img
import update from '../img/icons/update-list.png'
//Utils 
import Socket from '../utils/Socket.config';
import {RenderIf} from '../utils/RenderIf';
//API
import { NotificationsFetchAPI } from '../../api/Notifications';
//Alerts
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';

//Client Notifications component
const ClientNotifications = ({ userBranchoffices, business_code, token, user }) => {
    //States    
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [notifications, setNotifications] = useState([]);
    const [showModal, setShowModal] = useState(false);
    //We set the notification detail data 
    const [notificationDetail, setNotificationDetail] = useState({
        title: "",
        body: "",
        table: 0,
        status: "",
        created_at: "",
        notification_type: "",
        _id: 0
    });
    const [notificationType, setNotificationType] = useState(null);
    const [finalNotifications, setFinalNotifications] = useState([]);
    const [eventHandler, setEventHandler] = useState(false);
    //Functions to show and hide the modal 
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true)


    //Function to populate the users branch 
    const branchList = () => {
        const listBranch = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code} data-branchname={branch.branchoffice_name} data-branchid={branch.branchoffice_id}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBranch
    }

    //We get the client notifications 
    const getClientNotifications = async (branchoffice_code, business_code) => {
        try {
            //We get the notifications
            let response = await NotificationsFetchAPI.getNotifications(branchoffice_code, business_code, token);
            //We filter the notifications by the status
            let notificationsToApprove = response.data.notifications.filter(notification => notification.status === 1);
            let notificationsApprovedRejected = response.data.notifications.filter(notification => notification.status === 2 || notification.status === 3);
            //We set the notifications data
            setNotifications(notificationsToApprove)
            //We set the final notifications data
            setFinalNotifications(notificationsApprovedRejected)
        } catch (err) {
            setNotifications([])
        }
    }

    //We get the notification details by id 
    const getNotificationDetails = async (notification_id) => {
        try {
            //We get the notification details
            let response = await NotificationsFetchAPI.getNotificationOne(notification_id, token);
            console.log(response.data.notification)
            setNotificationDetail({
                title: response.data.notification.title,
                body: response.data.notification.body,
                table: response.data.notification.table.code,
                status: response.data.notification.status,
                created_at: response.data.notification.createdAt,
                notification_type: response.data.notification.notification_type,
                _id: response.data.notification._id
            })
        } catch (err) {
            setNotificationDetail({
                title: "",
                body: "",
                table: 0,
                status: "",
                created_at: "",
                notification_type: "",
                _id: 0
            });
        }
    }
    //Function to update a notification
    const updateNotification = async (notification_id, status) => {
        try {
            let data = {
                status: status
            }
            //We update the notification
            await NotificationsFetchAPI.updateNotification(notification_id, data, token);
            //Success alert 
            SuccessAlert("Notificacion actualizada correctamente")
        } catch (err) {
            console.log(err)
            ErrorAlert("Error al actualizar la notificacion", "Intente de nuevo", "warning")
        }
    }


    //Function to populate the notifications
    const populateNotifications = (notificationsToApprove) => {
        //We check if the notifications are to approve
        if (notificationsToApprove === true) {
            const listNotifications = notifications.map((notification) => (
                <>
                    <div className="d-flex align-items-center border-history shadow-sm mt-3 mx-1 bg-white" onClick={() => { handleShowModal(); getNotificationDetails(notification._id, 2); setNotificationType("toapprove") }}>
                        <div className="flex-grow-1 ms-3">
                            <div className=' d-flex justify-content-between mt-2 me-2'>
                                <h5 className="mb-0 text-muted">Habitacion #{notification.table.code} </h5>
                            </div>

                            <div className='mt-1 me-2 d-flex justify-content-between'>
                                <span className="text-nowrap text-secondary h6">
                                    <i className="fa-solid fa-store"></i>&nbsp;
                                    Tipo de notificacion: {notification.title}
                                </span>
                                <span className="text-nowrap text-secondary h6">
                                    <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                                    Estado: {notification.status === 1 ? "Pendiente" : notification.status === 2 ? "Aprobada" : "Rechazada"}
                                </span>
                            </div>

                        </div>
                    </div>
                </>
            ));
            return listNotifications
        } else {
            const listNotifications = finalNotifications.map((notification) => (
                <>
                    <div className="d-flex align-items-center border-history shadow-sm mt-3 mx-1 bg-white" onClick={() => { handleShowModal(); getNotificationDetails(notification._id, 2); setNotificationType("approvedRejected") }}>
                        <div className="flex-grow-1 ms-3">
                            <div className=' d-flex justify-content-between mt-2 me-2'>
                                <h5 className="mb-0 text-muted">Habitacion #{notification.table.code} </h5>
                            </div>

                            <div className='mt-1 me-2 d-flex justify-content-between'>
                                <span className="text-nowrap text-secondary h6">
                                    <i className="fa-solid fa-store"></i>&nbsp;
                                    Tipo de notificacion: {notification.title}
                                </span>
                                <span className="text-nowrap text-secondary h6">
                                    <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                                    Estado: {notification.status === 1 ? "Pendiente" : notification.status === 2 ? "Aprobada" : "Rechazada"}
                                </span>
                            </div>

                        </div>
                    </div>
                </>
            ));
            return listNotifications
        }

    }

    //Hook to replace the selected branch
    useEffect(() => {
        localStorage.setItem('branchoffice_selected', selectedBranch.code);
    }, [selectedBranch]);

    //Hook to conect the client to the sockets and make the initial charge of data 
    useEffect(() => {
        // Connection with the sockets server
        const socket = Socket({ "business_code": business_code })
        // Update of the data when the server send the signal of new notification for this business 
        socket.on(`newNotification_${business_code}`, (newNotification) => {
            let selected_branch = localStorage.getItem('branchoffice_selected')
            //We set the notifications data 
            setNotifications((prevNotifications) => {
                let index_user_branchoffices = user.branchoffices.findIndex(branch => newNotification.branchoffice.code === branch.code);
                //We check that the new order is from any branch available to this user 
                if (index_user_branchoffices !== -1) {
                    //We add the order if the order comes from an avalaible branch or a selected branc by the user
                    if (selected_branch === 0 || selected_branch === '0' || selected_branch === newNotification.branchoffice.code) {
                        return [newNotification, ...prevNotifications]
                    }
                }
                return prevNotifications;
            });
        });

        //We get the event when the notification is updated
        socket.on(`editNotification_${business_code}`, (updatedNotification) => {
            console.log(updatedNotification)
            let selected_branch = localStorage.getItem('branchoffice_selected')
            //We check if the notification is approved
            if (updatedNotification.status === 2 || updatedNotification.status === 3) {
                //We add the the notification to the final notifications list
                setFinalNotifications((prevNotifications) => {
                    let index_user_branchoffices = user.branchoffices.findIndex(branch => updatedNotification.branchoffice.code === branch.code);
                    //We check that the new edited notification to the final notifications list 
                    if ((index_user_branchoffices !== -1 && (updatedNotification.status === 2 || updatedNotification.status === 3))) {
                        //We add the notification if the notification comes from an notification branch or a selected branc by the user
                        if (selected_branch === 0 || selected_branch === '0' || selected_branch === updatedNotification.branchoffice.code) {
                            return [updatedNotification, ...prevNotifications]
                        }
                    }
                    return [updatedNotification, ...prevNotifications]
                });
            }

            //We remove the notification from the notifications list
            if (updatedNotification.status === 3 || updatedNotification.status === 2) {
                setNotifications((prevNotifications) => {
                    //We get the notification index
                    let index = prevNotifications.findIndex(notification => notification._id === updatedNotification._id);
                    //We remove the notification 
                    if (index !== -1) {
                        prevNotifications.splice(index, 1);
                    }
                    return prevNotifications;
                });
            }
            //We recharge the notifications
            setEventHandler(1)
            //We close the modal
            handleCloseModal();
        });
        return () => {
            socket.disconnect();
        }
    }, [business_code]);

    //Hook to recharge the notifications 
    useEffect(() => {
        //We set the notifications data
        setFinalNotifications([...finalNotifications]);
        setNotificationDetail([...notifications]);
        //We reset the handler
        setEventHandler(0);
    }, [eventHandler]);
    //Return 
    return (
        <>
            <section className='banner-sp d-lg-flex justify-content-lg-between  align-items-center rounded-4 mx-2  p-2 '>
                <div>
                    <div className='d-flex justify-content-between  align-items-center'>
                        <h2 className='banner-reports-title mb-0 pb-0'>
                            Notificaciones de Clientes
                        </h2>
                    </div>
                    <p className='m-0 p-0 banner-reports-description'>Notificaciones en tiempo real.</p>
                </div>
                <form className='filter-sp-trn'>
                    <div className="d-block">
                        <select onChange={e => {
                            getClientNotifications(e.target.value, business_code);
                            setSelectedBranch({ code: e.target.value, name: document.querySelector(`option[value=\"${e.target.value}\"]`).dataset.branchname, id: document.querySelector(`option[value=\"${e.target.value}\"]`).dataset.branchid });
                        }}
                            className='form-select' name="user_branch" id="user_branch">
                            <option value={0}> Mostrar todos</option>
                            {branchList()}
                        </select>
                    </div>
                </form>
            </section>

            <section className='row  mt-2 py-2 px-2'>
                <article className='col-12 col-lg-6'>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                        <h4 className='banner-sp-subtitle'>Solicitudes de los clientes por atender</h4>

                        <button className='btn-ht-update btn bg-primary-subtle rounded-circle p-2 lh-1 border-primary' title="Actualizar Lista"
                            onClick={() => getClientNotifications(selectedBranch.code, business_code)}
                        >
                            <img className='' width="25" height="25" src={update} alt="restart" />
                        </button>
                    </div>
                    <div className='rounded-3 '>
                        <div className='border rounded-4 bg-white'>

                            <div className=' payment-smart mx-3'>
                                <div className='transaction-wrapper border border-history no-scroll'>
                                    {/* Boton para actualizar las ordenes */}
                                    <div>
                                        {populateNotifications(true)}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </article>
                <article className='col-12 col-lg-6 mt-lg-0 mt-4'>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                        <h4 className='banner-sp-subtitle'>Solicitudes atendidas</h4>

                        <button className='btn-ht-update btn bg-primary-subtle rounded-circle p-2 lh-1 border-primary' title="Actualizar Lista"
                            onClick={() => getClientNotifications(selectedBranch.code, business_code)}
                        >
                            <img className='' width="25" height="25" src={update} alt="restart" />
                        </button>
                    </div>

                    <div className='rounded-3'>
                        <div className='border rounded-4 bg-white'>
                            <div className='mx-lg-3'>
                                <div className="w-100 bg-light px-3 py-2 border rounded mt-4">
                                    <div className="d-flex align-items-center">
                                        <h6 className="mb-0 text-secondary fw-bold">Filtar:</h6>
                                        <div className='border-0 fw-bold fs-3 ms-1  w-100'>
                                            <input
                                                spellcheck="false"
                                                className="form-control w-100"
                                                type="text"
                                                step="any"
                                                //onChange={handleChangeFiltro}
                                                placeholder="Filtar por...  "
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='transaction-wrapper-2  no-scroll mx-lg-0'>
                                    {populateNotifications(false)}
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>

            {/* Modal */}
            <Modal show={showModal} onHide={handleCloseModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Detalles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Notificacion: {notificationDetail.title} <br />
                    Solicita: {notificationDetail.body} <br />
                    Habitacion: {notificationDetail.table} <br />
                    Estado: {notificationDetail.status === 1 ? "Pendiente" : "Aprobada"} <br />
                    Fecha: {notificationDetail.created_at} <br />

                    <RenderIf isTrue={ notificationType !== "approvedRejected"}>
                        <div className='d-flex mt-4 justify-content-between'>
                            <Button variant="danger" className='btn col-5' onClick={() => updateNotification(notificationDetail._id, 3)} >
                                Cancelar
                            </Button>
                            <Button variant="success" className='btn col-5' onClick={() => updateNotification(notificationDetail._id, 2)} >
                                Aprobar
                            </Button>
                        </div>
                    </RenderIf>

                </Modal.Body>
            </Modal >
        </>

    );
}

export default ClientNotifications;
