
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})


//Class to manage the fetch to the API of the combos
export class CombosFetchAPI {

    //Function to get the combos by business
    static async getCombosByBusiness(business_id, token) {
        const res = await server(`/combos/business/${business_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get the combos by branch office
    static async getCombosByBranchOffice(branchoffice_id, token) {
        const res = await server(`/combo/${branchoffice_id}/branchoffice`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get the combo details by id 
    static async getComboById(combo_id, token) {
        const res = await server(`/combo/${combo_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get catalog details by id and branch office
    static async getComboDetailsByBranchOffice(combo_id, branchoffice_id, token) {
        const res = await server(`/combo/${branchoffice_id}/branchoffice?combo_id=${combo_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to create a new combo
    static async newCombo(data, token) {
        const res = await server(`/combo`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
        });
        return res
    }

    //Function to edit combo 
    static async updateCombo(combo_id,data, token) {
        const res = await server(`/combo/${combo_id}`, {
            method: "put",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
        });
        return res
    }

    //Function to edit combo on the branchoffice
    static async updateComboBranch(data, branchoffice_id, token) {
        const res = await server(`combo/${branchoffice_id}/branchoffice`, {
            method: "put",
            headers: { 'x-access-token': token },
            data: data
        });
        return res
    }

    //Function to asing a combo to a category
    static async addCombosToCategory(category_id, data, token) {
        const res = await server(`/combo_per_category/${category_id}/category`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: data
        });
        return res
    }

    //Function to remove a combo from category
    static async removeComboFromCategory(category_id, data, token) {
        const res = await server(`/combo_per_category/${category_id}/category`, {
            method: "delete",
            headers: { 'x-access-token': token },
            data: data
        });
        return res
    }
}