import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
import { RenderIf } from '../../utils/RenderIf';

import { ListGroup } from 'react-bootstrap';
import { GroupsFechtAPI } from '../../../api/Groups';
import { TaxesFetchAPI } from '../../../api/Taxes';
import { checkUserModules } from '../../utils/CheckUserModules';
//icons
import add from '../../img/icons/add-product.png'

function ModalCreateGroup({ loadProducts, user }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [typeCategory, setTypeCategory] = useState([])
    const [taxes, setTaxes] = useState([])
    const [upc_input, setUpc_input] = useState('')
    const [taxesSelect, setTaxesSelect] = useState([])
    const [imgpreview, setImgpreview] = useState('')
    let token = localStorage.getItem("x-access-token")



    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);
        form.append("business_id", user.business_id);
        form.append("group_type", "infinite");
        //We check that the integration module is active
        if (user.business.posIntegration !== undefined) {
            form.append("pos_integration", user.business.posIntegration)
        }
        const createGroup = Object.fromEntries(form.entries());
        createGroup.taxes = taxesSelect
        console.log(createGroup);
        console.log(taxesSelect);

        if (upc_input.length > 0) {
            try {
                //We update the data 
                let response = await GroupsFechtAPI.newGroup(createGroup, token);
                //We show the success alert 

                setTaxesSelect([])
                ErrorAlert('', 'Producto creado con exito', 'success')
                loadProducts();
                setImgpreview('')
                setShowModal(false);
            } catch (err) {
                ErrorAlert(err.response.data.error, 'Error de registro', 'error')
                console.log(err.response.data.error)
            }

        } else {
            ErrorAlert('', 'El upc ingresado tiene menos de 13 caracteres', 'error')
        }
    }

    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);
            setTaxes(response.data.data)
            console.log(response.data.data);

        } catch (err) {
            console.log(err);
        }
    }

    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }

    //filter checkbox in state true and save in state array
    const getChecked = (event) => {
        let dataTaxes = JSON.parse(event.target.value)


        if (event.target.checked == true) {
            setTaxesSelect([...taxesSelect, dataTaxes])
        } else if (event.target.checked == false) {
            for (let i = 0; i < taxesSelect.length; i++) {
                if (taxesSelect[i] == dataTaxes) {
                    taxesSelect.splice(i, 1)
                }
            }
        }
    }

    useEffect(() => {
        setEventHandle(0)

    }, [eventHandle])

    const [imageSrc, setImageSrc] = useState(
        "https://via.placeholder.com/300x200?text=Imagen+por+Defecto"
    ); // Imagen por defecto
    const fileInputRef = useRef(null);
    const handleClick = () => {
        fileInputRef.current.click(); // Dispara el clic sobre el input
    };
    return (
        <>
            <button className={'btn-add-product-list  rounded-4  pulse mt-4 py-4 w-100'}
                onClick={() => (setShowModal(true), getTaxes())}
            >
                {/**Check user permition to create products */}
                <div className='d-flex justify-content-center '>
                    <img src={add} alt="Add" />
                </div>

                <div className="text-center ">
                    <span className='fw-bold'>Agregar Nuevo Producto </span>
                </div>
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(''))}
                size='xl'
                centered>
                <Modal.Body>
                    <div className='text-center p-2'>
                        <h2 className='h4 fw-bold text-primary'>
                            Crear Nuevo Producto
                        </h2>
                    </div>
                    {/* <RenderIf isTrue={imgpreview != ''}> */}
                    <Form.Label className='text-black fw-semibold'>Imagen de Producto</Form.Label>
                    <div className='border rounded-4' ref={fileInputRef} onClick={handleClick} >
                        <div className='d-flex align-item-center justify-content-center'>
                            <img
                                className='imgEditModal'
                                src={imgpreview} />
                        </div>

                        <div className='d-flex align-item-center justify-content-center m-3' htmlFor="upload-button">
                            <label >
                                <h5 className="text-center">Seleccione una imagen</h5>
                            </label>
                        </div>
                    </div>


                    <Form.Group controlId="formFileMultiple" className="mb-3 visually-hidden">
                        <Form.Label></Form.Label>
                        <Form.Control type="file"
                            id="upload-button"
                            placeholder="Imagen"
                            name="files"
                            onChange={(e) => changeimg(e)}
                            ref={fileInputRef}
                        />
                    </Form.Group>
                    {/* </RenderIf> */}


                    {/* <div className="image-preview-container" onClick={handleClick}>
                        <div className="image-wrapper">
                            <img src={imageSrc} alt="Preview" className="preview-image" />
                        </div>
                        <input
                            type="file"
                            accept="image/*"             
                            className="file-input"
                              ref={fileInputRef}
                        />
                    </div> */}

                    <Form
                        className="needs-validation row"
                        onSubmit={submit}


                    >
                        <Form.Group className="mb-3 col-4">
                            <Form.Label className='text-black fw-semibold'>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre del producto"
                                name="name"
                                required
                                autoFocus
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 col-4" >
                            <Form.Label className='text-black fw-semibold' >Precio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="$0.00"
                                name="price"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 col-4" >
                            <Form.Label className='text-black fw-semibold' >Upc: mínimo 6 caracteres</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="0000000000000000"
                                name="upc_code"
                                onChange={(e) => setUpc_input(e.target.value)}

                            />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12" >
                            <Form.Label className='text-black fw-semibold' >Descripción</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                placeholder="Descripcion del producto"
                                name="description"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 col-4" >
                            <Form.Label className='text-black fw-semibold' >Preparación</Form.Label>
                            <Form.Select aria-label="" name="preparation">
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3  col-4" >
                            <Form.Label className='text-black fw-semibold' >Producto destacado/favorito</Form.Label>
                            <Form.Select aria-label="" name="favorite">
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3 col-4" >
                            <Form.Label className='text-black fw-semibold' >Habilitar producto para venta cruzada</Form.Label>
                            <Form.Select aria-label="" name="cross_sell">
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3 col-12" >
                            <Form.Label className='text-black fw-semibold' >Impuestos </Form.Label>
                            <div>
                                {taxes.map((tax, index) => (
                                    <>
                                        <Form.Check
                                            inline
                                            label={tax.tax}
                                            name="taxes"
                                            type='checkbox'
                                            value={JSON.stringify(tax.id)}
                                            onChange={(e) => getChecked(e)}
                                            id={`tax${tax.id}`}
                                        />
                                    </>
                                ))}
                            </div>
                        </Form.Group>

                        <RenderIf isTrue={checkUserModules('pos_integrations_module', user.modules) !== -1 && user.business.posIntegration !== undefined}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Referencia externa</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Referencia externa"
                                    name="external_reference"
                                />
                            </Form.Group>
                        </RenderIf>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label></Form.Label>
                            <Form.Control type="file"
                                id="upload-button"
                                placeholder="Imagen"
                                name="files"
                                onChange={(e) => changeimg(e)}
                            />
                        </Form.Group>


                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false), setImgpreview(''))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalCreateGroup