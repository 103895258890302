//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select'
import ReportTable from '../reports/table/ReportTable';
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { WarehouseFetchAPI } from '../../api/Warehouse';
import trash from '../img/icons/delete.png'
//Internal components 
import NavBar from '../NavBar/Navbar';
import Table from '../Table/Table';
import add from '../img/icons/add-product.png'
//Modals 
import ModalNewUpdateWarehouse from './ModalWarehouse/ModalNewUpdateWarehouse';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
const Warehouse = () => {

    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    //States 
    const [eventHandler, setEventHandler] = useState(0)
    const [warehouseSelect, setWarehouseSelect] = useState({})
    const [showModalNewWarehouse, setShowModalNewWarehouse] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });

    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);

            getWarehouseByBrachoffice(response.data.branchoffice.id)
        } catch (err) {
            console.log(err)
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
        }
    }

    //Function to delete warehouse
    const deleteWarehouse = async (warehouse_id) => {
        try {
            const response = await WarehouseFetchAPI.deleteWarehouse(warehouse_id, token)
            console.log(response);
            ErrorAlert('', 'Bodega eliminada corectamente', 'success')
            getWarehouseByBrachoffice(selectedBranch.id)
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Bodega eliminada corectamente', 'success')
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    const viewWarehouse = () => {

        const list = warehouses.map(warehouse => (
            <>
                {warehouse.is_default == 0 ? <>
                    <Col xs="12" sm="6" md="6" lg="4" xl="3">

                        <article className={'border rounded-4 mt-4 p-2 position-relative'} >
                            <div className="d-flex justify-content-between ">
                                <div className="ms-1 me-auto d-inline-block">
                                    <div className='d-flex justify-content-between'>
                                        <div className="fw-bold text-truncate card-item-categorie-title">
                                            <span>{warehouse.name}</span>
                                        </div>
                                    </div>
                                    {/* <span >Precio: <b>${parseFloat(product.price).toFixed(2)}</b></span> */}
                                    <p className='text-secondary fw-light text-truncate card-item-categorie-description'>{warehouse.location}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center rounded-3 mt-1'>
                                <div className='m-1'>

                                    <button className='btn btn-outline-primary pulse rounded-3 p-2 lh-1 border' onClick={() => (setWarehouseSelect(warehouse), setShowModalNewWarehouse(true))} >
                                        <i class="uil uil-edit-alt"></i>
                                    </button>

                                </div>
                                <div className='m-1'>

                                    <button className='btn btn-outline-danger pulse rounded-3 p-2 lh-1 border' onClick={() => deleteWarehouse(warehouse.id)} >
                                        <i class="uil uil-trash-alt"></i>
                                    </button>

                                </div>

                            </div>
                            <div className='position-absolute top-0 end-0 mt-1'>

                            </div>
                        </article>
                    </Col>
                </> : <></>}</>))
        return (list)
    }

    const viewWarehouseDefauld = () => {

        const list = warehouses.map(warehouse => (<>
            {warehouse.is_default == 1 ? <>
                <Col xs="12" sm="6" md="6" lg="4" xl="3">

                    <article className={'card-on shadow-sm rounded-4 mt-4 p-2 position-relative'} >
                        <div className="d-flex justify-content-between ">
                            <div className="ms-1 me-auto d-inline-block">
                                <div className='d-flex justify-content-between'>
                                    <div className="fw-bold text-truncate card-item-categorie-title">
                                        <span>{warehouse.name}</span>
                                    </div>


                                </div>
                                {/* <span >Precio: <b>${parseFloat(product.price).toFixed(2)}</b></span> */}
                                <p className='text-secondary fw-light text-truncate card-item-categorie-description'>{warehouse.location}</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center rounded-3 mt-1'>
                            <div className=' d-flex align-item-center justify-content-start gap-1'>
                            </div>

                        </div>
                        <div className='d-flex justify-constent-center align-items-center'>
                            <label className="icon-on pulse rounded-3 p-2 lh-1 border-on border-0">
                                {/* <img className='icon-options-cards' src={on} alt="restart" /> */}
                                <small className='ms-1 text-on fw-semibold'>Bodega por defecto </small>
                            </label>



                        </div>
                    </article>
                </Col>
            </> : <></>}</>))
        return (list)
    }

    const getWarehouseByBrachoffice = async (branchoffice_id) => {
        try {
            const response = await WarehouseFetchAPI.getwarehousebybracnhoffice(branchoffice_id, 0, token)
            console.log(response);
            setWarehouses(response.data.warehouses)
            console.log(response.data.warehouses);
            setEventHandler(1)

        } catch (err) {
            console.log(err);
            setWarehouses([])
        }
    }

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);

    }, []);

    useEffect(() => {
        setEventHandler(0)
    }, [eventHandler]);



    return (
        <>
            <div className=''>

                <div className='d-flex justify-content-between m-3'>
                    <h4><i class="uil uil-database"></i> Bodegas </h4>

                </div>

                <div className='m-3'>
                    <div className="row gx-3 gy-2 align-items-center bg-white p-2 border-sp border">
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="input_from" className='fw-bold' >Sucursal: {selectedBranch.code == 0 ? <>Seleccione un sucursal</> : <h4 className="fw-bold text-primary">{selectedBranch.name}</h4>}</label>
                                <select onChange={e => (getBranchoffice(business_code, e.target.value))} className='form-select' name="branch" id="branch">
                                    <option value={0}>Seleccione una sucursal</option>
                                    {branchList()}
                                </select>
                            </div>
                        </div>

                    </div>
                    <section className=''>
                        {selectedBranch.code != 0 ? <>
                            <div className="d-lg-flex justify-content-lg-between d-none mt-3">
                                <h4 className="fw-bold text-primary">Lista de bodegas </h4>

                            </div>

                            <div className="">
                                <Row>
                                    <Col xs="12" sm="6" md="6" lg="4" xl="3">
                                        <button className={'btn-add-product-list  rounded-4  pulse mt-4 py-4 w-100'}
                                            onClick={() => (setWarehouseSelect({}), setShowModalNewWarehouse(true))}
                                        >
                                            {/**Check user permition to create products */}
                                            <div className='d-flex justify-content-center '>
                                                <img src={add} alt="Add" />
                                            </div>

                                            <div className="text-center ">
                                                <span className='fw-bold'>Agregar una bodega </span>
                                            </div>
                                        </button></Col>
                                    {viewWarehouseDefauld()}
                                    {viewWarehouse()}
                                </Row>
                            </div></> : <></>}


                    </section>
                </div>
            </div>
            <ModalNewUpdateWarehouse showModalNewWarehouse={showModalNewWarehouse} setShowModalNewWarehouse={setShowModalNewWarehouse} selectedBranch={selectedBranch} warehouseSelect={warehouseSelect} getWarehouseByBrachoffice={getWarehouseByBrachoffice}></ModalNewUpdateWarehouse>
        </>
    );
}

export default Warehouse;
