//React components 
import React, { useState } from 'react';
import { Modal, Button, ListGroup, Form, Container, Row, Col } from 'react-bootstrap';
//Components
import ModalAddPermissionToUserRole from './ModalAddPermissionToUserRole';
import ModalForm from '../../Modal/Modal';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
import SuccessAlert from '../../Alerts/SuccessAlert';
//API
import { SmartpayModulesFetchAPI } from '../../../api/SmartPay.Modules';
import { RenderIf } from '../../utils/RenderIf';

//Function to list all smartpay modules by user role
function ModalListSmartpayModulesByUserRole({ smartPayModules, role, token, formData, setShowModal, showModal, handleFieldChange, initialFormValues, showAsingSmartPayModuleModal, setShowAsingSmartPayModuleModal, eventHandler, setEventHandler, business }) {

    //We asing the smartpay modules to the role
    const asingSmartpayModulesToRole = async (e) => {
        e.preventDefault();
        try {
            //We asing the smartpay modules to the role
            formData.role_id = role.id;
            //We asing the smartpay modules to the role
            await SmartpayModulesFetchAPI.asingSmartpayModulesToUserRole(formData, token);
            //Alert 
            SuccessAlert('Modulo asignado correctamente');
            //We set the event handler to refresh the list
            setEventHandler(1);
        } catch (err) {
            ErrorAlert(err);
        }
    }

    //Function to remove a module permission
    const removeModulePermission = async (permission_id, module_id) => {
        try {
            //We remove the module permission
            await SmartpayModulesFetchAPI.removeSmartpayModulePermission({ permissionId: permission_id, module_id, role_id: role.id }, token);
            //Alert 
            SuccessAlert('Permiso removido correctamente');
            //We set the event handler to refresh the list
            setEventHandler(1);
        } catch (err) {
            ErrorAlert(err);
        }
    }

    //Function to remove a module from a role
    const removeModuleFromUserRole = async (module_id) => {
        try {
            //We remove the module permission
            await SmartpayModulesFetchAPI.removeSmartpayModuleFromUserRole({ module_id, role_id: role.id }, token);
            //Alert 
            SuccessAlert('Modulo removido correctamente');
            //We set the event handler to refresh the list
            setEventHandler(1);
        } catch (err) {
            ErrorAlert(err);
        }
    }
    return (
        <>
            {/** Modal to list the smartpay module by user role*/}
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size='lg'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-airplay"></i>
                        Módulos de Smartpay</h3>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='row'>
                            <div className='col-11' >
                                <ListGroup as="ol" >
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{role.name}</div>
                                            {role.description}
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <h5 className='fs-4 fw-bold m-2 text-center'>Modulos asignados al rol</h5>
                                {/** We make a condition to render the button of asign a module to the role depending if the role is a default role not a created */}
                                <RenderIf isTrue={role.isdefault === false}>
                                    <div className='d-flex justify-content-center align-item-center m-3'>

                                        <button className='btn btn-primary btn-sm border-0' onClick={() => { setShowAsingSmartPayModuleModal(true); }} > <i className="uil uil-plus"></i> Asignar modulo
                                        </button>
                                    </div>
                                </RenderIf>
                            </div>
                            <div className='col-11'>

                                <ListGroup as="ol" >
                                    {smartPayModules.map(module => (
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="m-2 me-auto col-12">
                                                <div className="fw-bold d-flex justify-content-between align-items-start">{module.module_name}
                                                    {/** We make a condition to render the button of asign a permission to the module depending if the role is a default role not a created */}
                                                    <RenderIf isTrue={role.isdefault === false}>
                                                        <ModalAddPermissionToUserRole setEventHandler={setEventHandler} module={module} modulePermissions={module.permissions} token={token} role={role}></ModalAddPermissionToUserRole>
                                                    </RenderIf>
                                                </div>
                                                <Container>
                                                    <Row>
                                                        <Col >
                                                            {/** We make a condition to render the button of remove a module from the role depending if the role is a default role not a created */}
                                                            <RenderIf isTrue={role.isdefault === false}>
                                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={() => { removeModuleFromUserRole(module.module_id) }}>
                                                                    <i className="uil uil-trash-alt">Remover modulo</i>
                                                                </button>
                                                            </RenderIf>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                                {/** The list of groups on the set group */}
                                                <RenderIf isTrue={module.moduleUserRolPermissions.length > 0}>
                                                    {
                                                        module.moduleUserRolPermissions.map(permission => (
                                                            <ListGroup as="ol" >
                                                                <ListGroup.Item
                                                                    as="li"
                                                                    className="d-flex justify-content-between align-items-start"
                                                                >
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">
                                                                            {permission.permission_name}
                                                                        </div>
                                                                        <div className="">
                                                                            <b> Codigo:</b> {permission.permission_code}
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        {/** We make a condition to render the button of remove a permission to the module depending if the role is a default role not a created */}
                                                                        <RenderIf isTrue={role.isdefault === false}>
                                                                            <button className='btn btn-outline-danger btn-sm border-0'
                                                                                onClick={() => { removeModulePermission(permission.permise_id, module.module_id) }}
                                                                            >
                                                                                <i className="uil uil-trash-alt"></i>
                                                                            </button>
                                                                        </RenderIf>
                                                                    </div>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        ))
                                                    }
                                                </RenderIf>
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal >

            {/* Modal to create a new business role */}
            < ModalForm
                modal_body={initialFormValues}
                modal_header_text='Asignar modulo a rol'
                button_text='Crear'
                initialFormValues={formData}
                formType={'addForm'}
                buttonModal={false}
                setEventHandler={setEventHandler}
                eventHandle={eventHandler}
                submit={asingSmartpayModulesToRole}
                handleFieldChange={handleFieldChange}
                setShowModal={setShowAsingSmartPayModuleModal}
                showModal={showAsingSmartPayModuleModal}
            />
        </>
    )
}


export default ModalListSmartpayModulesByUserRole
