import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class WarehouseFetchAPI {

    static async createWarehouse(data, token) {
        const res = await server(`/warehouses`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getwarehousebybracnhoffice(branchoffice_id, defaultWarehouse, token) {
        let ruta = ''
        if (defaultWarehouse==1) {
            ruta = `/warehouses?branchoffice_id=${branchoffice_id}&default=1`
        }else{
            ruta = `/warehouses?branchoffice_id=${branchoffice_id}`
        }
        const res = await server(ruta, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getwarehousebyid(warehouse_id, token) {
     
        const res = await server(`/warehouse/${warehouse_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async updateWarehouse(warehouse_id,data, token) {
     
        const res = await server(`/warehouse/${warehouse_id}`, {
            method: "put",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async deleteWarehouse(warehouse_id, token) {
     
        const res = await server(`/warehouse/${warehouse_id}`, {
            method: "DELETE",
            headers: { 'x-access-token': token },
        });
        return res
    }

    
  
}
