import React from 'react'

export default function CardTotals({ total_sales, total_discounts, total_itbms }) {
    return (
        <div className="row justify-content-md-center mt-4">
            <div className="col-12 col-md-4 col-lg-4">
                <div class="card-analitics-sp shadow-sm">
                    <div class="card-analitics-sp-header d-flex justify-content-between">
                        <span>Total de Ventas</span>
                        {/* <span>
                            <img src={totals} alt="Totals" className='icon-img' />
                        </span> */}
                    </div>
                    <span class="card-value-total fw-bold text-color-sp"> <span>$</span>
                        {(!total_sales) ? <>0.00</> : <>{parseFloat(total_sales).toFixed(2)}</>}
                    </span>
                </div>
            </div>
            <div className="col-6 col-md-4 col-lg-4">
                <div class="card-analitics-sp shadow-sm">
                    <div class="card-analitics-sp-header d-flex justify-content-between">
                        <span>Total Descuentos</span>
                        {/* <span>
                            <img src={totals} alt="Totals" className='icon-img' />
                        </span> */}
                    </div>
                    <span class="card-value-total fw-bold text-color-sp"> <span>$</span>
                        {(!total_discounts) ? <>0.00</> : <>{parseFloat(total_discounts).toFixed(2)}</>}
                    </span>
                </div>
            </div>
            <div className="col-6 col-md-4 col-lg-4">
                <div class="card-analitics-sp shadow-sm">
                    <div class="card-analitics-sp-header d-flex justify-content-between">
                        <span>Impuestos</span>
                        {/* <span>
                            <img src={totals} alt="Totals" className='icon-img' />
                        </span> */}
                    </div>
                    <span class="card-value-total fw-bold text-color-sp"> <span>$</span>
                        {(!total_itbms) ? <>0.00</> : <>{parseFloat(total_itbms).toFixed(2)}</>}
                    </span>
                </div>
            </div>
        </div>
    )
}
