
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})


//Class to manage the fetch to the API of the catalogs
export class CatalogsFetchAPI {

   //Function to get catalogs by business
   static async getCatalogsByBusiness(business_id, token) {
         const res = await server(`/catalog/business/${business_id}`, {
              method: "get",
              headers: { 'x-access-token': token },
         });
         return res
    }

    //Function to get the catalogs by branch office
    static async getCatalogsByBranchOffice(branchoffice_id, token) {
        const res = await server(`/catalog_per_branchoffice/${branchoffice_id}/branchoffice`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get catalog by id
    static async getCatalogById(catalog_id, token) {
        const res = await server(`/catalog/${catalog_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get catalog details by id and branch office
    static async getCatalogDetailsByBranchOffice(catalog_id, branchoffice_id, token) {
        const res = await server(`/catalog_per_branchoffice/${branchoffice_id}/branchoffice?catalog_id=${catalog_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to create a catalog
    static async createCatalog(data, token) {
        const res = await server(`/catalog`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: data
        });
        return res
    }

    //Function to update a catalog on the branchoffice 
    static async updateBranchCatalog(data, branchoffice_id, token) {
        const res = await server(`/catalog_per_branchoffice/${branchoffice_id}/branchoffice`, {
            method: "put",
            headers: { 'x-access-token': token },
            data: data
        });
        return res
    }

    //Function to update a catalog
    static async updateCatalog(data, catalog_id, token) {
        const res = await server(`/catalog/${catalog_id}`, {
            method: "put",
            headers: { 'x-access-token': token },
            data: data
        });
        return res
    }

}
