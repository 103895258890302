import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import { Badge } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import { MachinesFetchAPI } from '../../api/Machines';
import { ListGroup } from 'react-bootstrap';

//IMG 
import vending_machine from '../img/vending-machine.jpg'

function ModalviewMachine({ machine }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [infoMachine, setInfoMachine] = useState({ branchoffice_id: "", code: "", description: "", machine_id: "", machine_name: "", machine_per_branch_id: "", machine_subtype_code: "", machine_subtype_id: "", name: "", subtype: "" })



    let token = localStorage.getItem("x-access-token")
    let user = localStorage.getItem('user');

    useEffect(() => {
    }, [eventHandle])

    return (
        <>
            <button className='btn btn-outline-primary btn-sm border-0' onClick={() => (setShowModal(true), setInfoMachine(machine))}>
                <i className="uil uil-eye"></i>
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='lg'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-eye"></i>
                        Detalle de la maquina  </h3>
                </Modal.Header>
                <Modal.Body>

                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={vending_machine}
                            alt=""
                        />
                    </div>
                    <Container>
                        <Row>
                            <Col>
                                <div className='mt-3 d-flex justify-content-center align-items-center'>
                                    <ListGroup as="ol" >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Nombre</div>
                                                {infoMachine.machine_name}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Tipo</div>
                                                {infoMachine.name}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Modelo</div>
                                                {infoMachine.subtype}
                                            </div>

                                            


                                        </ListGroup.Item>


                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Code subtipo</div>
                                                {infoMachine.machine_subtype_code}
                                            </div> 
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Code</div>

                                                {infoMachine.code}
                                            </div>

                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </Col>

                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >





        </>
    )
}

export default ModalviewMachine