import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";


//Components
import LandingPage from './components/_index';
import NoMatch from "./components/layout/NoMatch";
import Login from './components/login/Login';
import Transactions from './components/transactions/Transactions';
import TransactionsTemporal from "./components/transactions/TransactionTemporal";
import Buys from "./components/Buys/Buys";
import AnaliticStore from "./components/analitics/AnaliticStore";
import ReportStore from "./components/reports/ReportStore";
import Events from "./components/Events/Events";
import ProductStore from "./components/products/ProductStore";
import SmartpayWebhook from './components/Webhook/Webhook.jsx'
import Users from "./components/Users/Users.jsx";
import VendingPanel from "./components/Vending/VendingPanel.jsx";
import Machines from "./components/Machines/Machines.jsx";
import Inventory from "./components/Inventory/Inventory.jsx";
import Notifications from "./components/Notifications/Notifications.jsx";
//Integrations managment 
import IntegrationManager from "./components/integrations/IntegrationManager.jsx";

//API 
import { AuthFetchAPI } from "./api/Auth";

//context 
import UserContext from "./context/UserContext";


//CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/js/bootstrap.esm'
import './App.css';
import './index.css';
//Icons
import 'bootstrap-icons/font/bootstrap-icons.css'
import SupportAdmin from "./components/SupportBot/SupportAdmin/SupportAdmin.jsx";



///auth/token
function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined
  });


  //Function to check if the user is logged in or available
  const checkLogin = async () => {
    let token = localStorage.getItem('x-access-token');
    let machineToken = localStorage.getItem('x-access-machine-token');
    let user = localStorage.getItem('user');
    //We get the url
    let actualPage = window.location.href;

    //We check if the user has the token 
    if (token == null) {
      localStorage.setItem('x-access-token', '');
      token = ''
    }
    //We check if the machine has the token
    if (machineToken == null) {
      localStorage.setItem('x-access-machine-token', '');
      machineToken = ''
    }
    //We check if the token of the machine and user token is available (this is just for post cases)
    if (machineToken != '' && token != '') {
      try {
        //We check if the machine token is valid 
        const tokenMachineRes = await AuthFetchAPI.validtoken(machineToken);
        //We check if the user token is valid 
        const tokenres = await AuthFetchAPI.validtoken(token);
        //If the token is from kiosk we redirect to the kiosk page
        if ((tokenres.data === false || tokenMachineRes.data === false) && actualPage.includes("payments")) {
          localStorage.setItem('x-access-machine-token', '');
          machineToken = ''
          localStorage.setItem('x-access-token', '');
          token = ''
          window.location.reload()
        }

        // We check if the token is valid
        if (tokenres.data === true && actualPage.includes('payments')) {
          const user = await AuthFetchAPI.me(token);
          setUserData({ token: token, user: user.data })
        }
      } catch (error) {
        localStorage.setItem('x-access-machine-token', '');
        machineToken = ''
        localStorage.setItem('x-access-token', '');
        token = ''
        window.location.reload()
      }
    }

    //We check if the token of the machine is available (this is just for kiosk cases)
    if (machineToken != '') {
      try {
        //We check if the token is valid
        const tokenres = await AuthFetchAPI.validtoken(machineToken);
        console.log(tokenres)
        //If the token is from kiosk we redirect to the kiosk page
        if (tokenres.data === false && actualPage.includes("kiosk")) {
          localStorage.setItem('x-access-machine-token', '');
          machineToken = ''
        }
      } catch (error) {
        //If the token is not valid we redirect to the login page
        if (actualPage.includes("kiosk")) {
          localStorage.setItem('x-access-machine-token', '');
          machineToken = ''
        }
      }
    }

    //We check if the token is available (this is for the user token and vending cases)
    if (token != '') {
      try {
        //We check if the token is valid
        const tokenres = await AuthFetchAPI.validtoken(token);
        console.log(tokenres)

        //If the token is not valid we redirect to the login page
        if (tokenres.data === false && actualPage.includes('vending')) {
          localStorage.setItem('x-access-token', '');
          token = ''
          //We recharge the page
          window.location.reload()
        }

        // We check if the token is valid
        if (tokenres.data === true && !actualPage.includes('vending') && !actualPage.includes('kiosk')) {
          const user = await AuthFetchAPI.me(token);
          setUserData({ token: token, user: user.data })
        } else {
          localStorage.setItem('x-access-token', '');
          token = '';
          //We get the business code on the url
          let business_code = JSON.parse(user).business.code
          //We clean the user data
          localStorage.setItem('user', '');
          //We redirect to the login page
          window.location.href = `/login/${business_code}`
        }
      } catch (error) {
        //If the token is not valid we redirect to the login page
        if (actualPage.includes('vending')) {
          localStorage.setItem('x-access-token', '');
          token = ''
          //We recharge the page
          window.location.reload()
        }
        //If the token is not valid we redirect to the login page
        if (!actualPage.includes('vending') && !actualPage.includes('kiosk')) {
          localStorage.setItem('x-access-token', '');
          token = '';
          //We get the business code on the url
          let business_code = JSON.parse(user).business.code
          //We clean the user data
          localStorage.setItem('user', '');
          //We redirect to the login page
          window.location.href = `/login/${business_code}`
        }
      }
    }
  }

  useEffect(() => {
    (async () => {
      try {
        await checkLogin()
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  return (
    <>
      <Router>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Routes>
            {/* Index Main Menu  */}
            <Route exact path="/" element={<LandingPage />} />
            {/* Logins */}
            <Route path="/login/:business_code" element={<Login />} />
            {/**Transaction of admins */}
            <Route path="/transactions/:business_code" element={<Transactions />} />
            <Route path="/analitics/:business_code" element={<AnaliticStore />} />
            <Route path="/reports/:business_code" element={<ReportStore />} />
            {/** Users */}
            <Route path="/users/:business_code" element={<Users />} />
            {/** Buys */}
            <Route path="/buys/:business_code" element={<Buys />} />
            {/** Products of admins */}
            <Route path="/products/:business_code" element={<ProductStore />} />
            <Route path="/temporal/:business_code" element={<TransactionsTemporal />}></Route>
            {/**Smartpay Webhook  */}
            <Route path="/webhook/:business_code" element={<SmartpayWebhook></SmartpayWebhook>}></Route>
            {/** Integration of admins */}
            <Route path="/manager/integration/:business_code" element={<IntegrationManager />} />
            {/** Vending Panel */}
            <Route path="/vending/:business_code" element={<VendingPanel />} />
            {/** Events */}
            <Route path="/events/:business_code" element={<Events />} />
            {/** Machines */}
            <Route path="/machines/:business_code" element={<Machines />} />
            {/**Support bot admin */}
            <Route path="/support" element={<SupportAdmin />} />
            {/** Inventory */}
            <Route path="/inventory/:business_code" element={<Inventory />} />
            {/* Notifications */}
            <Route path="/notifications/:business_code" element={<Notifications />} />
            {/* Cashier Login */}
            {/* No Match link */}
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </UserContext.Provider>
      </Router>
    </>
  );
}

export default App;
