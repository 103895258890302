//React components
import React, { useState } from 'react';
import { Modal, Button, Container, Row, Col, ListGroup } from 'react-bootstrap';
import Table from '../Table/Table';
//Image 
import defaultImage from "../../assets/img/default.png";

//Function to show the detail of the machine
function ModalDetailEvents({ event }) {
    //States
    const [showModal, setShowModal] = useState(false)
    const [detailEvent, setDetailEvent] = useState({ img: "", name: "", description: "", dates: [] })

    return (
        <>
            <button className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border' onClick={() => (setShowModal(true), setDetailEvent(event))}>
                <i className="uil uil-eye text-dark"></i>
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='lg'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-eye"></i>
                        Detalle del evento  </h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={detailEvent.img ? detailEvent.img : defaultImage}
                            alt=""
                        />
                    </div>
                    <Container>
                        <div className='mt-3 d-flex justify-content-center align-items-center'>
                            <ListGroup as="ol" >
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Nombre</div>
                                        {detailEvent.name}
                                    </div>

                                </ListGroup.Item>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Descripción</div>
                                        {detailEvent.description}
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                    </Container>
                    <Table
                        table_name={`Historico de eventos`}
                        table_data={event.dates}
                        table_type={"event_table"}
                        table_headers={["Fecha de inicio", "Fecha de cierre", "Publico esperado"]}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalDetailEvents