import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { RenderIf } from '../../utils/RenderIf';

import pencil from '../../img/icons/pencil.png'

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';
import { GroupsFechtAPI } from '../../../api/Groups';
import { TaxesFetchAPI } from '../../../api/Taxes';
import { checkUserModules } from '../../utils/CheckUserModules';


function ModalEditGroup({ product, loadProducts, branchoffice_id, user }) {
    const [showModal, setShowModal] = useState(false)
    const [typeCategory, setTypeCategory] = useState([])
    const [notifications, setnotifications] = useState([])
    const [imgpreview, setImgpreview] = useState(product.img)
    const [upc_list, setUpc_list] = useState([])
    const [taxes, setTaxes] = useState([])
    const [groupType, setGroupType] = useState('infinite')

    let token = localStorage.getItem("x-access-token")

    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        //We get the data from the form
        const form = new FormData(e.target);
        //We check that the integration module is active
        if (user.business.posIntegration !== undefined) {
            form.append("pos_integration_id", user.business.posIntegration.id)
        }
        //We check if the product is from a branch office
        let editGroup = Object.fromEntries(form.entries());
        try {
            //We update the data 
            await GroupsFechtAPI.updateGroup(product.group_id, editGroup, token);
            //We check if the product is from a branch office
            if (branchoffice_id !== 0) {
                //We add the product id on the edit object
                editGroup.group_id = product.group_id;
                //We update the product in the branch office
                await GroupsFechtAPI.updateGroupBranchoffice(branchoffice_id, editGroup, token);
            }
            //We show the success alert 
            ErrorAlert('', 'Producto editada con exito', 'success')
            loadProducts();
            setShowModal(false);
            setImgpreview(product.img)
        } catch (err) {
            setImgpreview(product.img)
            ErrorAlert(err.response.data.error, 'Error en la edicion', 'error')
            console.log(err)
        }
    }

    //Function to get the taxes
    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);

            setTaxes(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }


    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }

    return (
        <>

            <button type="button" className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border'
                onClick={() => (setShowModal(true), getTaxes(), setGroupType(product.group_type), console.log(product))}
            >
                <img className='icon-options-cards' src={pencil} alt="Edit" />

            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(product.img))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Editar producto </h3>
                </Modal.Header>
                <Modal.Body>


                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={imgpreview}
                            alt={product.name}
                        />
                    </div>

                    <div className='d-flex align-item-center justify-content-center m-3'>
                        <label htmlFor="upload-button">
                            <h5 className="text-center">Actualizar imagen</h5>
                        </label>
                    </div>
                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={product.name}
                                name="name"

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Descripcion</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                placeholder={product.description}
                                name="description"

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Precio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={product.price}
                                name="price"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Preparacion</Form.Label>
                            <Form.Select aria-label="" name="preparation">
                                <RenderIf isTrue={product.branchoffice_preparation == 0}>
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                </RenderIf>
                                <RenderIf isTrue={product.branchoffice_preparation == 1}>
                                    <option value={1}>Si</option>
                                    <option value={0}>No</option>
                                </RenderIf>
                            </Form.Select>
                        </Form.Group>

                        <RenderIf isTrue={checkUserModules('pos_integrations_module', user.modules) !== -1 && user.business.posIntegration !== undefined}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Referencia externa</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Referencia externa"
                                    name="external_reference"
                                />
                            </Form.Group>
                        </RenderIf>

                        <RenderIf isTrue={branchoffice_id !== 0}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Favorito/destacado</Form.Label>
                                <Form.Select aria-label="" name="favorite">
                                    <RenderIf isTrue={product.favorite == 0}>
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                    </RenderIf>
                                    <RenderIf isTrue={product.favorite == 1}>
                                        <option value={1}>Si</option>
                                        <option value={0}>No</option>
                                    </RenderIf>
                                </Form.Select>
                            </Form.Group>
                        </RenderIf>


                        <RenderIf isTrue={branchoffice_id !== 0}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Habilitar producto para venta cruzada</Form.Label>
                                <Form.Select aria-label="" name="cross_sell" defaultChecked={product.cross_sell}>
                                    <RenderIf isTrue={product.cross_sell == 0}>
                                        <option value="0">No</option>
                                        <option value="1">Si</option>
                                    </RenderIf>
                                    <RenderIf isTrue={product.cross_sell == 1}>
                                        <option value="1">Si</option>
                                        <option value="0">No</option>
                                    </RenderIf>
                                </Form.Select>
                            </Form.Group>
                        </RenderIf>

                        <RenderIf isTrue={branchoffice_id !== 0}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Inventario</Form.Label>
                                <Form.Select aria-label="" name="group_type" onChange={e => setGroupType(e.target.value)}>
                                    <RenderIf isTrue={product.group_type == "infinite"}>
                                        <option value={"infinite"}>No</option>
                                        <option value={"limited"}>Si</option>
                                    </RenderIf>
                                    <RenderIf isTrue={product.group_type == "limited"}>
                                        <option value={"limited"}>Si</option>
                                        <option value={"infinite"}>No</option>
                                    </RenderIf>
                                </Form.Select>
                            </Form.Group>
                        </RenderIf>

                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label></Form.Label>
                            <Form.Control
                                id="upload-button"
                                type="file"
                                placeholder="Imagen"
                                name="files"
                                onChange={(e) => changeimg(e)}
                                hidden
                            />
                        </Form.Group>




                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false), setImgpreview(product.img))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalEditGroup