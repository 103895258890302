import React from 'react'
import Swal from 'sweetalert2'; 

function SuccessAlert(message) {
    const Toast = Swal.mixin({
        toast: true,
        position: "bottom",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        color:'#165116',
        background:'#dffbdd',
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      })
    return (
        //We show a loading alert 
        // Swal.fire({
        //     icon: 'success',
        //     title: 'Realizado', //mensaje en pantalla
        //     text: message,
        //     showConfirmButton: false,
        //     timer: 1500
        // })



          Toast.fire({
            icon: "success",
            title: message,          
          })
    )
}

export default SuccessAlert