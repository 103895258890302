import React, { useEffect, useState } from 'react';
//Components 
import { Modal, Button, Container, Row, Col, ListGroup, Badge, Form } from 'react-bootstrap';
//Api 
import { PaymentMethodFetchAPI } from '../../../api/PaymentMethod';
//Utils
import { RenderIf } from '../../utils/RenderIf';
import ErrorAlert from '../../Alerts/ErrorAlert';
import SuccessAlert from '../../Alerts/SuccessAlert';

//Function to render the modal of asing payment method to a machine
const ModalAsingPaymetMethod = ({ showModal, setShowModal, paymentMethodIntegrations, token, machine_id, setEventHandler }) => {
    //States 
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethodParams, setPaymentMethodParams] = useState([]);
    const [selectedPaymentMethodIntegration, setSelectedPaymentMethodIntegration] = useState(0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
    //Function to get the payment method by integration
    const getPaymentMethodByIntegration = async (integration_id) => {
        try {
            //We get the payment method by integration
            const res = await PaymentMethodFetchAPI.getPaymentMethodByIntegration(integration_id, token);
            setPaymentMethods(res.data.data);
            setSelectedPaymentMethodIntegration(integration_id);
        }
        catch (err) {
            setPaymentMethods([]);
            setSelectedPaymentMethodIntegration(0)
        }
    }

    //Function to get the payment method params
    const getPaymentMethodParams = async (payment_method_id, integration_id) => {
        try {
            //WE DONT ASK PARAMS For manual integration 
            if (integration_id == 3) {
                setPaymentMethodParams([]);
            } else {
                //We get the payment method params
                const res = await PaymentMethodFetchAPI.getPaymentMethodParams(payment_method_id, integration_id, token);
                setPaymentMethodParams(res.data.data);
            }
        }
        catch (err) {
            setPaymentMethodParams([]);
        }
    }

    //Function to save the payment method
    const savePaymentMethod = async (e) => {
        e.preventDefault();
        try {
            //We get the params
            let params = [];
            //We get the inputs of the form
            let inputs = document.getElementsByName('paramPayment');
            for (let i = 0; i < inputs.length; i++) {
                params.push({
                    param_id: inputs[i].getAttribute('data-id'),
                    param_value: inputs[i].value
                });
            }

            //We add the params to the form data 
            const form = {
                params: params,
                integration_id: selectedPaymentMethodIntegration
            };
            //We save the payment method
            await PaymentMethodFetchAPI.asingPaymentMethodToMachine(machine_id, selectedPaymentMethod, form, token);
            //We close the modal    
            setShowModal(false);
            //We show the success alert
            SuccessAlert("Metodo de pago asignado correctamente");
            //We reset the payment method params
            setPaymentMethodParams([]);
            //We set the event handler
            setEventHandler(1)
        } catch (err) {
            //We show the error alert
            if (err.response.status == 400) {
                ErrorAlert(err.response.data.error, "Advertencia", "warning");
            } else {
                ErrorAlert("Error al asignar el metodo de pago");
            }
        }
    }


    //Hook to reset the payment method params
    useEffect(() => {
        if (selectedPaymentMethodIntegration != 0) {
            //We preselect the first option on the payment method 
            document.getElementById('paymentmethod-select').selectedIndex = 0;
        }
        //We reset the payment method params
        setPaymentMethodParams([]);
        //We reset the selected payment method
        setSelectedPaymentMethod(0);
    }, [selectedPaymentMethodIntegration])


    return (
        <>
            <Modal
                show={showModal}
                size="md"
                centered
                onHide={() => { setShowModal(false) }}>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Asignar Metodo de Pago
                    </h3>
                </Modal.Header>
                <Modal.Body>
                    <Form className='mt-3' onSubmit={savePaymentMethod}>
                        {/** Select of integrations */}
                        <Form.Label>Seleccione la forma de integración</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(e) => getPaymentMethodByIntegration(e.target.value)}>
                            <option>Seleccione una forma de integración</option>
                            {paymentMethodIntegrations.map((integration, index) => (
                                <option key={index} value={integration.id}>{integration.name}</option>
                            ))}
                        </Form.Select>
                        {/** Select of payment methods */}
                        <Form.Label className='mt-3'>Seleccione el metodo de pago</Form.Label>
                        <Form.Select aria-label="Default select example" id="paymentmethod-select" onChange={(e) => { getPaymentMethodParams(e.target.value, selectedPaymentMethodIntegration); setSelectedPaymentMethod(e.target.value) }}>
                            <option>Seleccione un metodo de pago</option>
                            {paymentMethods.map((paymentMethod, index) => (
                                <option key={index} value={paymentMethod.paymentmethod_id}>{paymentMethod.payment}</option>
                            ))}
                        </Form.Select>
                        {/** List of payment method params */}
                        <ListGroup className='mt-3'>
                            <RenderIf isTrue={paymentMethodParams.length > 0}>
                                {paymentMethodParams.map((param, index) => (
                                    <ListGroup.Item key={index}>
                                        <p>
                                            {param.is_optional == 1 ? param.name + " (Opcional)" : param.name}
                                        </p>

                                        <RenderIf isTrue={param.name !== "force_duplicate" && param.name !== "iscashback" && param.name !== "force_duplicate" && param.name !== "manual_entry_indicator" && param.name !== "payment_host"}>
                                            <Form.Control type="text" name='paramPayment' placeholder={param.name} data-id={param.param_id} />
                                        </RenderIf>
                                        <RenderIf isTrue={param.name === "force_duplicate" || param.name === "iscashback" || param.name === "force_duplicate" || param.name === "manual_entry_indicator"}>
                                            <Form.Select aria-label="Default select example" name='paramPayment' data-id={param.param_id}>
                                                <option value={"no"}>No</option>
                                                <option value={"yes"}>Si</option>
                                            </Form.Select>
                                        </RenderIf>
                                        <RenderIf isTrue={param.name === "payment_host"}>
                                            <Form.Select aria-label="Default select example" name='paramPayment' data-id={param.param_id}>
                                                <option value={"ATH"}>ATH</option>
                                                <option value={"ATH2"}>ATH2</option>
                                                <option value={"ATH2"}>ATH2</option>
                                                <option value={"ATH3"}>ATH3</option>
                                                <option value={"ATH4"}>ATH4</option>
                                            </Form.Select>
                                        </RenderIf>
                                    </ListGroup.Item>
                                ))}
                            </RenderIf>
                        </ListGroup>
                        {/** Button to save the payment method */}
                        <Button className='mt-3' variant="primary" type="submit">
                            Asignar Metodo de Pago
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalAsingPaymetMethod;
