import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
//Icon
import pencil from '../../img/icons/pencil.png'

import { ListGroup } from 'react-bootstrap';
import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';

function ModalEditCategorie({ category, loadCategories }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [typeCategory, setTypeCategory] = useState([])
    const [notifications, setnotifications] = useState([])
    const [imgpreview, setImgpreview] = useState(category.img)
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');

    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))
        const form = new FormData(e.target);
        const createCategorie = Object.fromEntries(form.entries());

        try {
            //We update the data 
            let response = await CategoriesDashFetchAPI.UpdateCategories(createCategorie, category.category_id, token);
            //We show the success alert 
            console.log(response)

            ErrorAlert('', 'Categoria editada con exito', 'success')
            loadCategories();
            setShowModal(false);
            setImgpreview(category.img)
        } catch (err) {
            setImgpreview(category.img)
            ErrorAlert('', 'Error en la edicion', 'error')
            console.log(err)
        }
    }

    const getCategoryType = async () => {
        try {
            const response = await CategoriesDashFetchAPI.getCategoryType(token);
            setTypeCategory(response.data.categorie_types)
        } catch (err) {
            console.log(err)
        }
    }

    const viewTypeCategory = () => {
        const view = typeCategory.map((type) => (
            <><option value={type.id}>{type.name}</option></>
        ));

        return (view)
    }



    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }





    useEffect(() => {
        setEventHandle(0)

    }, [eventHandle])




    return (
        <>


            <button type="button" className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border'
                onClick={() => (setShowModal(true), getCategoryType())}
            >
                <img className='icon-options-cards' src={pencil} alt="Edit" />

            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(category.img))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Editar Categoria </h3>
                </Modal.Header>
                <Modal.Body>
                    {/*<Form className="needs-validation" onSubmit={submit} encType="multipart/form-data">
                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label></Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Imagen"
                                name="file"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="name" >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text" placeholder="Nombre categoria" name="name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Nombre secundario</Form.Label>
                            <Form.Control type="text" placeholder="Nombre secundario" name="secundaryName" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="category_type">
                            <Form.Label>Tipo de categoria</Form.Label>
                            <Form.Select aria-label="" name="category_type">
                                <option>Seleccione un tipo</option>
                                <option value="P">Producto</option>
                                <option value="S">Servicio</option>

                            </Form.Select>
                        </Form.Group>
                        <Button type="submit" value="Submit" >Guardar</Button>
                    </Form>*/}

                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={imgpreview}
                            alt={category.category_name}
                        />
                    </div>

                    <div className='d-flex align-item-center justify-content-center m-3'>
                        <label htmlFor="upload-button">
                            <h5 className="text-center">Actualizar imagen</h5>
                        </label>
                    </div>
                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={category.category_name}
                                name="name"

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Descripcion</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                placeholder={category.secundaryName}
                                name="description"

                            />
                        </Form.Group>



                        {/*<Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Tipo de categoria</Form.Label>
                            <Form.Select aria-label="" name="category_type">
                                <option>Seleccione un tipo</option>
                                {viewTypeCategory()}
                            </Form.Select>
                </Form.Group>*/}



                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label></Form.Label>
                            <Form.Control
                                id="upload-button"
                                type="file"
                                placeholder="Imagen"
                                name="files"
                                onChange={(e) => changeimg(e)}
                                hidden
                            />
                        </Form.Group>


                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false), setImgpreview(category.img))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalEditCategorie