//React components
import React, { useState } from 'react';
import { Modal, Button, Container, Row, Col, ListGroup } from 'react-bootstrap';
//Image 
import defaultImage from "../../assets/img/default.png";
//API 
import { NotificationsFetchAPI } from '../../api/Notifications';
//Alerts
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
//Function to show the detail of the notification
function ModalDetailNotification({ userBranchoffices, notification, setShowModal, showModal, setEventHandler, token }) {
    const [showModalAsing, setShowModalAsing] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(0);

    //Function to asign a branch office to the notification type 
    const asignBranch = async () => {
        try {
            //We asign the branch office to the notification
            await NotificationsFetchAPI.asingBranchofficeToNotification(selectedBranch, notification.id, token);
            //We set the selected notification
            setEventHandler(1);
            //We close the modal
            setShowModalAsing(false);
            //We close the modal detail
            setShowModal(false);
            //We show the success alert
            SuccessAlert('Sucursal asignada');
        } catch (err) {
            //We close the modal
            if (err.response.data.error) {
                //We show the error alert
                ErrorAlert(err.response.data.error, "Error", "warning");
            } else {
                //We show the error alert
                ErrorAlert('Error al asignar la sucursal', "Error", "error");
            }
        }
    }

    //Function to remove a branch office to the notification type
    const removeBranch = async (branchoffice_id) => {
        try {
            //We remove the branch office to the notification
            await NotificationsFetchAPI.removeBranchofficeToNotification(branchoffice_id, notification.id, token);
            //We set the selected notification
            setEventHandler(1);
            //We close the modal
            setShowModalAsing(false);
            //We close the modal detail
            setShowModal(false);
            //We show the success alert
            SuccessAlert('Sucursal removida');
        } catch (err) {
            //We close the modal
            if (err.response.data.error) {
                //We show the error alert
                ErrorAlert(err.response.data.error, "Error", "warning");
            } else {
                //We show the error alert
                ErrorAlert('Error al remover la sucursal', "Error", "error");
            }
        }
    }

    //Return the component
    return (
        <>
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='lg'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-eye"></i>
                        Detalle de la notificacion  </h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={notification.secure_url ? notification.secure_url : defaultImage}
                            alt=""
                        />
                    </div>
                    <Container>
                        <Row>
                            {/** General info */}
                            <Col>
                                <div className="mt-3">
                                    <ListGroup as="ol" >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Nombre</div>
                                                {notification.name}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Descripción</div>
                                                {notification.description}
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </Col>
                            {/** Branchoffices */}
                            <Col>
                                <div className="mt-3">
                                    <ListGroup as="ol">
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Sucursales
                                                    <button className='btn btn-primary btn-sm border-0 m-2' onClick={() => setShowModalAsing(true)}>
                                                        <i className="uil uil-plus">Asignar a sucursal</i>
                                                    </button>
                                                </div>
                                                <ListGroup as="ol" >
                                                    {
                                                        notification.branchoffices.map((branch) => (
                                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                                <div className="ms-2 me-auto" >
                                                                    {branch.name}
                                                                    <button className='btn btn-outline-danger btn-sm border-0' onClick={() => removeBranch(branch.branchoffice_id)} >
                                                                        <i className="uil uil-trash-alt"></i>
                                                                    </button>
                                                                </div>
                                                            </ListGroup.Item>
                                                        ))
                                                    }
                                                </ListGroup>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

            {/*Modal asing branchoffice to notification*/}
            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar Sucursal</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-item-center'>
                        <select className='form-select m-3' name="branchoffice_id" id="branchoffice_id" onChange={(e) => setSelectedBranch(e.target.value)} >
                            <option >Seleccione una sucursal</option>
                            {userBranchoffices.map(branchoffice => (
                                <option value={branchoffice.branchoffice_id}>{branchoffice.branchoffice_name}</option>
                            ))}
                        </select>
                        <Button size="sm" variant="success" type="button" className='m-3' onClick={() => asignBranch()}>
                            Guardar
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsing(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalDetailNotification