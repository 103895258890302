import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup, Form } from 'react-bootstrap';
//Components
import ModalForm from '../../Modal/Modal';
//Alerts 
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
//Utils
import { RenderIf } from '../../utils/RenderIf';
//API
import { SetGroupsFetchAPI } from '../../../api/SetGroups';

//Function to list all set groups
const ModalListSetGroups = ({ user, branchoffice_id }) => {
    //States
    const [showModal, setShowModal] = useState(false);
    const [showInternalModal, setShowInternalModal] = useState(false);
    const [showEditInternalModal, setShowEditInternalModal] = useState(false);
    //states
    const [businessSetGroups, setBusinessSetGroups] = useState([]);
    const [selectedSetGroup, setSelectedSetGroup] = useState(0);
    const [eventHandler, setEventHandler] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        business_id: user.business_id
    });
    //Initial form values
    const [initialFormValues, setInitialFormValues] = useState([
        { type: 'text', name: 'name', placeholder: 'Nombre', label: 'Nombre', required: true },
    ]);


    //Function to populate the list
    const populateList = () => {
        const list = businessSetGroups.map((setGroup) => (
            <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
            >
                <div className="ms-2 me-auto">
                    <div className="fw-bold">{setGroup.name}</div>
                </div>

                <div className='m-1'>

                    <button className='btn btn-outline-info btn-sm border-0'
                        onClick={async () => { setShowEditInternalModal(true); getSetGroupById(setGroup.id) }}
                    >
                        <i className="uil uil-pen"></i>
                    </button>
                </div>

                <div className='m-1'>
                    <button className='btn btn-outline-danger btn-sm border-0'
                    //onClick={async () => beforeDelete(categorie.category_id)}
                    >
                        <i className="uil uil-trash-alt"></i>
                    </button>
                </div>

            </ListGroup.Item>
        ));
        return (list)
    }

    //Function to detect the change of value on the users forms
    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    //Function to get the set groups
    const getSetGroups = async () => {
        try {
            let response;
            //We get the set groups by business
            response = await SetGroupsFetchAPI.getSetGroupsByBusiness(user.business_id, localStorage.getItem('x-access-token'));
            //We set the catalogs
            setBusinessSetGroups(response.data.sets)
        } catch (err) {
            console.log(err);
            setBusinessSetGroups([])
        }
    }

    //Function to get the set group by id
    const getSetGroupById = async (set_id) => {
        let response;
        try {
            //We get the catalog
            response = await SetGroupsFetchAPI.getSetGroupById(set_id, localStorage.getItem('x-access-token'));
            // Set initial form values for the edit form
            setFormData({
                name: response.data.set.name,
                set_id: response.data.set.id, 
                business_id: user.business_id
            });
            //We set the set group 
            setSelectedSetGroup(set_id)
        } catch (err) {
            setFormData({
                name: '',
                business_id: user.business_id
            });
            setSelectedSetGroup(0)
        }
    }

    //Function to create a set group
    const createSetGroup = async (e) => {
        e.preventDefault();
        try {
            //We create the catalog
            const response = await SetGroupsFetchAPI.createSetGroup(formData, localStorage.getItem('x-access-token'));
            //We response the data 
            SuccessAlert(response.data.message)
            //We close the modal
            setShowInternalModal(false)
            //We set the event handler
            setEventHandler(1)
        } catch (err) {
            console.log(err)
            ErrorAlert('Error al crear el conjunto de productos', 'Intente de nuevo', 'error')
        }
    }

    //Function to update the set group
    const updateSetGroup = async (e) => {
        e.preventDefault();
        try {
            //We update the set group
            await SetGroupsFetchAPI.updateSetGroup(formData, selectedSetGroup, localStorage.getItem('x-access-token'));
            //We response the data
            SuccessAlert('Actualizacion exitosa')
            //We close the modal
            setShowEditInternalModal(false)
            //We set the event handler
            setEventHandler(1)
        } catch (err) {
            ErrorAlert('Error al actualizar el conjunto de productos', 'Intente de nuevo', 'error')
        }
    }


    //Use effect to get the initial data
    useEffect(() => {
        //We call the function
        getSetGroups();
        setEventHandler(0)
        setFormData({
            name: '',
            business_id: user.business_id
        })
    }, [eventHandler]);

    useEffect(() => {
        //We call the function
        getSetGroups();
    }, [branchoffice_id]);


    return (
        <>
            {/**Button to show the modal of set groups*/}
            <button className='btn btn-dark btn-sm  fw-normal bg-body text-dark border border-1 rounded-2'
                onClick={() => (setShowModal(true))} type="button">
                <i className="uil uil-airplay"> </i> Conjuntos de Productos
            </button>
            {/**Modal to show the catalogs */}
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-airplay"></i>
                        Conjuntos de productos</h3>

                </Modal.Header>
                <Modal.Body>
                    {/* Modal to create the set group */}
                    <ModalForm
                        modal_body={initialFormValues}
                        modal_header_text='Crear conjunto de productos'
                        button_text='Crear'
                        initialFormValues={formData}
                        formType={'addForm'}
                        buttonModal={true}
                        setEventHandler={setEventHandler}
                        eventHandle={eventHandler}
                        submit={createSetGroup}
                        handleFieldChange={handleFieldChange}
                        setShowModal={setShowInternalModal}
                        showModal={showInternalModal}
                    />

                    {/**Edit form */}
                    <ModalForm
                        modal_body={initialFormValues}
                        modal_header_text='Editar conjunto de productos'
                        initialFormValues={formData}
                        formType={'editForm'}
                        buttonModal={false}
                        setEventHandler={setEventHandler}
                        eventHandle={eventHandler}
                        submit={updateSetGroup}
                        handleFieldChange={handleFieldChange}
                        setShowModal={setShowEditInternalModal}
                        showModal={showEditInternalModal}
                    />
                    <ListGroup as="ol" >
                        {populateList()}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalListSetGroups;
