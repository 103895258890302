import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { SegmentsFechtAPI } from '../../../api/Segments';
import { ItemsFechtAPI } from '../../../api/Items';
import { TaxesFetchAPI } from '../../../api/Taxes';
import ModalEditItemTosegment from '../ModalItems.jsx/ModalEditItemTosegment';
import { RenderIf } from '../../utils/RenderIf';
import Select from 'react-select';
//Icons
import eye from '../../img/icons/eye.png'
function ModalSegmentListItems({ segment, branchoffice_id }) {
    const [showModal, setShowModal] = useState(false);
    const [showModalTaxes, setShowModalTaxes] = useState(false);
    const [eventHandle, setEventHandle] = useState(0);
    const [items, setItems] = useState([])
    const [itemSelectAsing, setItemSelectAsing] = useState([])
    const [showModalAsing, setShowModalAsing] = useState(false)
    const [itemSelectNow, setItemSelectNow] = useState("")
    const [price, setPrice] = useState("")
    const [tax_id, setTax_id] = useState("")
    const [taxes, setTaxes] = useState([])
    const [taxesSelect, setTaxesSelect] = useState([]);
    const [taxSelect, setTaxSelect] = useState("");
    const [itemNowAsingTax, setItemNowAsingTax] = useState("")


    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    const getItemsBySegments = async () => {
        try {
            const response = await SegmentsFechtAPI.getItemsBysegment(segment.segment_id, branchoffice_id, token)
            setItems(response.data.data)
            console.log(response.data.data);

        } catch (err) {
            console.log(err);
            setItems([])
        }
    }

    const deleteItemToSegment = async (item_id) => {
        let data = {
            "item_id": item_id
        }

        try {
            const response = await SegmentsFechtAPI.deleteItemtoSegment(segment.segment_id, data, token)
            getItemsBySegments()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error no se pudo remover el item', 'error')
        }
    }

    const getItemsByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await ItemsFechtAPI.getItemsbYBusiness(user.business_id, token)
            setItemSelectAsing(response.data.data)

            let ItemInfo = []
            response.data.data.forEach(item => {
                ItemInfo.push({ value: item.item_id, label: item.name }) 
            });

            setItemSelectAsing(ItemInfo)
        } catch (err) {
            console.log(err);
        }
    }


    //filter checkbox in state true and save in state array
    const getChecked = (event) => {
        let dataTaxes = JSON.parse(event.target.value)


        if (event.target.checked == true) {
            setTaxesSelect([...taxesSelect, dataTaxes])
        } else if (event.target.checked == false) {
            for (let i = 0; i < taxesSelect.length; i++) {
                if (taxesSelect[i] == dataTaxes) {
                    taxesSelect.splice(i, 1)
                }
            }
        }
    }

    const deleteTaxofItemAndSegment = async (tax_id, item_id) => {
        let data = {
            tax_id: tax_id,
            segment_id: segment.segment_id,
            item_id: item_id
        }
        try {
            const response = await TaxesFetchAPI.deleteTaxOfItemAndSegment(data, token);
            getItemsBySegments()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error no se pudo remover el impuesto', 'error')
        }
    }

    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);

            setTaxes(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }


    const asingProductToSegment = async () => {
        let data = {
            price: price,
            taxes: taxesSelect,
        }
        try {
            const response = await SegmentsFechtAPI.asingItemsToSegment(itemSelectNow, segment.segment_id, data, token)
            setTaxesSelect([])
            getItemsBySegments()
            setShowModalAsing(false)
            ErrorAlert('', 'Item asignado correctamente', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
        }
    }

    const handleSaveTableSelect = (option) =>{
        setItemSelectNow(option.value);
     }


    const asingTaxToItemOfSegment = async () => {
        let data = {
            tax_id: taxSelect,
            segment_id: segment.segment_id,
            item_id: itemNowAsingTax
        }
        try {
            const response = await TaxesFetchAPI.asingTaxToItemOfSegment(data, token)
            console.log(response);
            setItemNowAsingTax(0);
            getItemsBySegments();
            setShowModalTaxes(false)
            ErrorAlert('', 'Impuesto asignado correctamente', 'success')
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
        }
    }

    const viewItem = () => {

        const list = items.map(item => (
            <Col>
                <ListGroup as="ol" className='m-1' >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <img
                            className='imgitemsList m-1'
                            src={item.img}
                            alt={item.name}
                        />
                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{item.name}</div>
                            <RenderIf isTrue={branchoffice_id !== 0}>
                                <div className='d-flex justify-constent-center align-items-center'>
                                    {item.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                                </div>
                            </RenderIf>


                            <p className=''>{item.description}</p>

                            <div className="">Precio: <b>${parseFloat(item.price).toFixed(2)}</b> - {item.description} <b>

                            </b></div>
                            <div className=''>
                                <p className=''><strong>Impuestos: </strong>

                                    <button className='btn btn-outline-primary btn-sm border-0' onClick={() => (setItemNowAsingTax(item.item_id), setShowModalTaxes(true), console.log("hola"))} >
                                        <i className="uil uil-plus">Asignar impuesto</i>
                                    </button></p>

                            </div>
                            <div className='mt-1'>
                                <ListGroup as="ol" >
                                    {item.taxes.map((tax) => (
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto" >
                                                {tax.tax}
                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={() => deleteTaxofItemAndSegment(tax.tax_id, item.item_id)}>
                                                    <i className="uil uil-trash-alt"></i>
                                                </button>
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>

                        </div>

                        <div>
                            <ModalEditItemTosegment item={item} segment={segment} load={getItemsBySegments}></ModalEditItemTosegment>
                            <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => deleteItemToSegment(item.item_id)}>
                                <i className="uil uil-trash-alt"></i>
                            </button>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Col >


        ))
        return (list)
    }

    useEffect(() => {

    }, [eventHandle])

    return (
        <>
            <button className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border'
                onClick={() => (setShowModal(true), getItemsBySegments(), getTaxes(), getItemsByBusiness())}
            >
              

                   <img className='icon-options-cards' src={eye} alt="Edit" />
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3><i className="uil uil-eye"></i>Productos del acompañante </h3>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup as="ol" >
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >

                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{segment.name}</div>
                                {segment.description}
                            </div>
                            <div className='ms-2 me-auto'>
                                <div className="fw-bold">Referencia externa: </div>
                                {segment.external_reference === null ? "No tiene" : segment.external_reference}                            </div>

                        </ListGroup.Item>
                    </ListGroup>


                    <h5 className='fs-4 fw-bold m-2 text-center'>Detalle de los productos</h5>


                    <div className='d-flex justify-content-center align-item-center'>
                        <button className='btn btn-primary btn-sm border-0' onClick={(e) => (setShowModalAsing(true))}> <i class="uil uil-plus"></i> Asignar producto
                        </button>
                    </div>

                    <div className="mt-3">
                        <Container>
                            <Row>
                                {viewItem()}
                            </Row>
                        </Container>

                        {/*<div className=" border rounded table-responsive">
                            <table className='table table-hover table-sm' >
                                <thead className='table-light'>
                                    <tr>

                                        <th>Imagen</th>
                                        <th>Nombre</th>
                                        <th>Precio</th>
                                        <th>Impuesto</th>
                                        <th>Remover</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(item => (
                                        <tr key={item.item_id}>

                                            <td className='align-middle' >
                                                <img src={item.img} alt={item.name} className='imgitemsTable'
                                                />
                                            </td>
                                            <td className='align-middle'  >{item.name}</td>
                                            <td className='align-middle text-center'  >${parseFloat(item.price).toFixed(2)}</td>
                                            <td className='align-middle text-center'  >{item.tax}</td>
                                            <td className='align-middle' >
                                                <ModalEditItemTosegment item={item} segment={segment} load={getItemsBySegments}></ModalEditItemTosegment>
                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={async (e) => deleteItemToSegment(item.item_id)}>
                                                    <i className="uil uil-trash-alt"></i>
                                                </button>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>*/}
                    </div>


                    <Modal
                        show={showModalTaxes}
                        onHide={() => (setShowModalTaxes(false))}
                        size='large'
                        centered>
                        <Modal.Header>
                            <h3><i className="uil uil-plus"></i>Asignar impuesto</h3>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='d-flex justify-content-center align-item-center'>
                                <select className='form-select m-3' name="user_branch" id="user_branch" onChange={(e) => setTaxSelect(e.target.value)}>
                                    <option >Seleccione un impuesto</option>
                                    {taxes.map(tax => (
                                        <option value={tax.id}>{tax.tax}</option>
                                    ))}
                                </select>
                                <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => asingTaxToItemOfSegment()} >
                                    Guardar
                                </Button>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => (setShowModalTaxes(false))}>Cerrar</Button>
                        </Modal.Footer>
                    </Modal>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar productos</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        className="needs-validation"

                    >

                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Item individual</Form.Label>
                            <Select
                                className='w-100'
                                options={itemSelectAsing}
                                onChange={handleSaveTableSelect}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Precio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="$0.00"
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </Form.Group>



                        <Form.Group className="mb-3" >
                            <Form.Label>Impuestos </Form.Label>
                            <div>
                                {taxes.map((tax, index) => (
                                    <>
                                        <Form.Check
                                            inline
                                            label={tax.tax}
                                            name="taxes"
                                            type='checkbox'
                                            value={JSON.stringify(tax.id)}
                                            onChange={(e) => getChecked(e)}
                                            id={`tax${tax.id}`}
                                        />
                                    </>
                                ))}
                            </div>
                        </Form.Group>

                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="button" className='m-3' onClick={() => asingProductToSegment()} >
                                Guardar
                            </Button>
                        </div>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsing(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ModalSegmentListItems