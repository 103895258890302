import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup, Form } from 'react-bootstrap';
//Components
import ModalForm from '../../Modal/Modal';
//Alerts 
import SuccessAlert from '../../Alerts/SuccessAlert';
//Utils
import HoursList from '../../utils/HoursList';
import { RenderIf } from '../../utils/RenderIf';
//API
import { CatalogsFetchAPI, updateBranchCatalog, getCatalogDetailsByBranchOffice, getCatalogById } from '../../../api/Catalogs';
//Function to list all catalogs
const ModalListCatalogs = ({ user, branchoffice_id }) => {
    //States
    const [showModal, setShowModal] = useState(false);
    const [showInternalModal, setShowInternalModal] = useState(false);
    const [showEditInternalModal, setShowEditInternalModal] = useState(false);
    //states
    const [catalogs, setCatalogs] = useState([]);
    const [selectedCatalog, setSelectedCatalog] = useState(0);
    const [eventHandler, setEventHandler] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        minHour: '00:00:00',
        maxHour: '23:30:00',
        days: [],
        business_id: user.business_id
    });
    //Hours list
    const hours = HoursList();
    //Initial form values
    const [initialFormValues, setInitialFormValues] = useState([
        { type: 'text', name: 'name', placeholder: 'Nombre', label: 'Nombre', required: true },
        { type: 'select', name: 'minHour', required: true, label: 'Hora de inicio', options: hours, defaultValue: '00:00:00' },
        { type: 'select', name: 'maxHour', required: true, label: 'Hora de finalizacion', options: hours, defaultValue: '23:30:00' },
        //{ type: 'checkbox', name: 'days', label: 'Dias', options: [{ name: 'Lunes', value: '1', id: 'monday' }, { name: 'Martes', value: '1', id: 'tuesday' }, { name: 'Miercoles', value: '1', id: 'wednesday' }, { name: 'Jueves', value: '1', id: 'thursday' }, { name: 'Viernes', value: '1', id: 'friday' }, { name: 'Sabado', value: '1', id: 'saturday' }, { name: 'Domingo', value: '1', id: 'sunday' }] },
    ]);


    //Function to populate the list
    const populateList = () => {
        const list = catalogs.map((catalog) => (
            <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
            >
                {/** We render this swicht just when we have selected a branchoffice */}
                <RenderIf isTrue={branchoffice_id !== 0}>
                    <Form.Switch
                        type="switch"
                        id={`custom-switch-${branchoffice_id == 0 ? catalog.id : catalog.catalog_id}`}
                        label=""
                        checked={catalog.status === 1 ? true : false}
                        onChange={updateBranchCatalog}
                    />
                </RenderIf>

                <div className="ms-2 me-auto">
                    <div className="fw-bold">{catalog.name}</div>
                </div>

                <div className='m-1'>

                    <button className='btn btn-outline-info btn-sm border-0'
                        onClick={async () => { setShowEditInternalModal(true); getCatalogById(branchoffice_id == 0 ? catalog.id : catalog.catalog_id) }}
                    >
                        <i className="uil uil-pen"></i>
                    </button>
                </div>

                <div className='m-1'>
                    <button className='btn btn-outline-danger btn-sm border-0'
                    //onClick={async () => beforeDelete(categorie.category_id)}
                    >
                        <i className="uil uil-trash-alt"></i>
                    </button>
                </div>

            </ListGroup.Item>
        ));
        return (list)
    }

    //Function to detect the change of value on the users forms
    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    //Function to get the catalogs
    const getCatalogs = async () => {
        try {
            let response;
            //We validate if the user has a branchoffice selected
            if (branchoffice_id == 0) {
                //We get the catalogs
                response = await CatalogsFetchAPI.getCatalogsByBusiness(user.business_id, localStorage.getItem('x-access-token'));
            } else {
                //We get the catalogs
                response = await CatalogsFetchAPI.getCatalogsByBranchOffice(branchoffice_id, localStorage.getItem('x-access-token'));
            }
            //We set the catalogs
            setCatalogs(response.data.catalogs)
        } catch (err) {
            setCatalogs([])
        }
    }

    //Function to update the catalog on the branch office
    const updateBranchCatalog = async (e) => {
        e.preventDefault();
        //Variable
        let status = 1;
        let catalog_id = e.target.id.split('-')[2];
        //We get the catalog stats
        if (e.target.checked == false) {
            status = 0
        } else {
            status = 1
        }
        //data to send
        let data = { catalog_id: catalog_id, status: status };

        try {
            //We update the catalog
            const response = await CatalogsFetchAPI.updateBranchCatalog(data, branchoffice_id, localStorage.getItem('x-access-token'));
            //Success alert
            SuccessAlert(response.data.message)
            //We set the event handler
            setEventHandler(1)
        } catch (err) {
            console.log(err)
        }
    }

    //Function to get the catalog by id
    const getCatalogById = async (catalog_id) => {
        let response, updatedFormValues = initialFormValues;
        try {
            //We ask if the catalog is from a branch office
            if (branchoffice_id === 0) {
                //We get the catalog
                response = await CatalogsFetchAPI.getCatalogById(catalog_id, localStorage.getItem('x-access-token'));
                // Set initial form values for the edit form
                setFormData({
                    name: response.data.catalog.name,
                    minHour: '00:00:00',
                    maxHour: '23:30:00',
                    catalog_id: response.data.catalog.id
                });
            } else {
                response = await CatalogsFetchAPI.getCatalogDetailsByBranchOffice(catalog_id, branchoffice_id, localStorage.getItem('x-access-token'));
                //We set the form values
                updatedFormValues[1].defaultValue = response.data.catalogs.minHour;
                updatedFormValues[2].defaultValue = response.data.catalogs.maxHour;

                setInitialFormValues(updatedFormValues)
                // Set initial form values for the edit form
                setFormData({
                    name: response.data.catalogs.name,
                    minHour: response.data.catalogs.minHour,
                    maxHour: response.data.catalogs.maxHour,
                    catalog_id: response.data.catalogs.catalog_id
                });
            }

            //We set the catalog 
            setSelectedCatalog(catalog_id)
        } catch (err) {
            setFormData({
                name: '',
                minHour: '00:00:00',
                maxHour: '23:30:00',
                days: [],
                business_id: user.business_id
            });
            setSelectedCatalog(0)
        }
    }
    //Function to create a catalog
    const createCatalog = async (e) => {
        e.preventDefault();
        try {
            console.log(formData);
            //We create the catalog
            const response = await CatalogsFetchAPI.createCatalog(formData, localStorage.getItem('x-access-token'));
            console.log(response);
            //We response the data 
            SuccessAlert(response.data.message)
            //We close the modal
            setShowInternalModal(false)
            //We set the event handler
            setEventHandler(1)
        } catch (err) {
            console.log(err)
        }
    }

    //Function to update the catalog
    const updateCatalog = async (e) => {
        e.preventDefault();
        try {
            let data = { ...formData, catalog_id: selectedCatalog }

            //We check if the user wants to update the catalog on the branch office
            if (branchoffice_id !== 0) {
                //We update the catalog on the branch office
                await CatalogsFetchAPI.updateBranchCatalog(data, branchoffice_id, localStorage.getItem('x-access-token'));
            } else {
                //We update the catalog
                await CatalogsFetchAPI.updateCatalog(data, selectedCatalog, localStorage.getItem('x-access-token'));
            }

            //We response the data
            SuccessAlert('Actualizacion exitosa')
            //We close the modal
            setShowEditInternalModal(false)
            //We set the event handler
            setEventHandler(1)
        } catch (err) {
            console.log(err)
        }
    }


    //Use effect to get the initial data
    useEffect(() => {
        //We call the function
        getCatalogs();
        setEventHandler(0)
        setFormData({
            name: '',
            minHour: '00:00:00',
            maxHour: '23:30:00',
            days: [],
            business_id: user.business_id
        })
    }, [eventHandler]);

    useEffect(() => {
        //We call the function
        getCatalogs();
    }, [branchoffice_id]);


    return (
        <>
            {/**Button to show the modal of catalogs*/}


            <button className='btn btn-dark  btn-sm fw-normal bg-body text-dark border border-1 rounded-2'
                onClick={() => (setShowModal(true))} >
                <i className="uil uil-airplay"> </i>
                <span>
                    Menús
                </span>

            </button>



            {/**Modal to show the catalogs */}
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='large'
                centered>
                <Modal.Header className='text-center'>


                </Modal.Header>
                <Modal.Body>
                    {/* Modal to create the catalog */}
                    <div className="d-flex justify-content-between">
                        <h3>
                            <i className="uil uil-airplay"></i>
                            Tipo de menú </h3>
                        <ModalForm
                            modal_body={initialFormValues}
                            modal_header_text='Crear tipo de menu'
                            button_text='Crear'
                            initialFormValues={formData}
                            formType={'addformGeneric'}
                            buttonModal={true}
                            setEventHandler={setEventHandler}
                            eventHandle={eventHandler}
                            submit={createCatalog}
                            handleFieldChange={handleFieldChange}
                            setShowModal={setShowInternalModal}
                            showModal={showInternalModal}
                        />

                    </div>

                    {/**Edit form */}
                    <ModalForm
                        modal_body={initialFormValues}
                        modal_header_text='Editar tipo de menu'
                        initialFormValues={formData}
                        formType={'editForm'}
                        buttonModal={false}
                        setEventHandler={setEventHandler}
                        eventHandle={eventHandler}
                        submit={updateCatalog}
                        handleFieldChange={handleFieldChange}
                        setShowModal={setShowEditInternalModal}
                        showModal={showEditInternalModal}
                    />
                    <ListGroup as="ol" >
                        {populateList()}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalListCatalogs;
