import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { BuysFetchAPI } from '../../api/Buys';
// External Componets
import moment from 'moment-timezone';
// Internal Components 
import BuyTable from '../Table/Table';
import ErrorAlert from '../Alerts/ErrorAlert';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import BuyModal from './Modals/BuyModal';
import { RenderIf } from '../utils/RenderIf';
//Components 
const BuysTable = () => {
    //Params
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'));
    let token = localStorage.getItem('x-access-token');
    //States 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [initialDate, setInitialDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
    const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')
    const [buysList, setBuysList] = useState([])
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [showModal, setShowModal] = useState(false);
    const [buyDetail, setBuyDetails] = useState(
        {
            products: [{
                cant: 0,
                code: "",
                name: "",
                price: 0,
                tax: 0,
                total_product: 0,
                unit_discount: 0
            }], invoiceTotal: 0, invoiceSubtotal: 0, invoiceTax: 0, invoiceDiscount: 0,
            is_void_buy: false,
            voided_buy_details: {
                invoice: 0
            }
        }
    );
    const [eventHandler, setEventHandler] = useState(0);
    const [buySelected, setBuySeleceted] = useState(null);

    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
        } catch (err) {
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to get buy by branch 
    const getBuysByBranch = async (branchoffice_id) => {
        try {
            const response = await BuysFetchAPI.getBuysByBranch(branchoffice_id, initialDate, maxDate, token);
            setBuysList(response.data.buys)
        } catch (err) {
            setBuysList([]);
            if (err.response && err.response.data.error) {
                ErrorAlert(err.response.data.error, "Error", "warning");
            } else {
                console.error(err);
            }
        }
    }

    //Function to get buy details 
    const getBuysDetails = async (buy_id) => {
        try {
            const response = await BuysFetchAPI.getBuysDetails(buy_id, token);
            setBuyDetails({ voided_buy_details: response.data.buy.voided_buy, is_void_buy: response.data.buy.is_void_buy, products: response.data.buy.products, invoiceTotal: response.data.buy.total, invoiceSubtotal: response.data.buy.subtotal, invoiceTax: response.data.buy.itbms, invoiceDiscount: response.data.buy.discount })
        } catch (err) {
            setBuyDetails({
                products: [{
                    cant: 0,
                    code: "",
                    name: "",
                    price: 0,
                    tax: 0,
                    total_product: 0,
                    unit_discount: 0
                }], invoiceTotal: 0, invoiceSubtotal: 0, invoiceTax: 0, invoiceDiscount: 0,
                is_void_buy: false,
                voided_buy_details: {
                    invoice: 0
                }

            });
        }
    }

    //Function to void a buy
    const voidBuy = async (buy_id) => {
        try {
            //We get the date of the actual day 
            let date = moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD HH:mm:ss');
            //We create the data to send
            let data = {
                createdAt: date
            }
            //We void the buy
            const response = await BuysFetchAPI.voidBuy(buy_id, data, token);
            //We set the event handler to reload the data
            getBuysByBranch(selectedBranch.id);
            //We close the modal
            setShowModal(false);
            //We set the event handler to reload the data
            setEventHandler(1);
            //Error alert
            ErrorAlert(response.data.message, "Exito", "success");
        } catch (err) {
            //Error alert
            ErrorAlert(err.response.data.error, "Error", "warning");
        }
    }

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        setEventHandler(0);
    }, []);

    //Hook to load info when the event handler is activated 
    useEffect(() => {
        //We validate if the user has selected a branch
        if (buySelected !== null && buySelected !== undefined && buySelected !== 0) {
            getBuysDetails(buySelected);
        }
        setEventHandler(0)
    }, [eventHandler])

    //Return the component
    return (
        <div>
            <h4>Filtros</h4>
            <div className="row gx-3 gy-2 align-items-center bg-white p-2 my-3 border-sp border shadow-sm">

                <div className='col-12 mb-2'>             
                    <span className='h5 text-primary-emphasis fw-bold '>
                        Información General 
                    </span>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="input_from">Sucursal</label>
                        <select onChange={e => getBranchoffice(business_code, e.target.value)} className='form-select' name="branch" id="branch">
                            <option value={0}>Seleccione...</option>
                            {branchList()}
                        </select>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="input_from">Desde</label>
                        <input type="date" name="initialDate" 
                        onChange={e => setInitialDate(e.target.value + "T00:00:00.000+00:00")} 
                        className="form-control"  />
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                    <div className="form-group">
                        <label htmlFor="input_from">Hasta</label>
                        <input type="date" name="finishDate" 
                        onChange={e => setMaxDate(e.target.value + "T23:59:59.000+00:00")} 
                        className="form-control"  />
                    </div>
                </div>
                <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                    <button type="button" onClick={e => getBuysByBranch(selectedBranch.id)} 
                     className='btn btn-continue'> Aceptar </button>
                </div>
            </div>

            {/** Buy table */}
            <BuyTable
                table_name="Tabla de compras realizadas"
                table_headers={["Fecha de creacion", "Tipo", "Proveedor", "Factura de compra", "Total", 'Acciones']}
                table_type="buy_table"
                table_data={buysList}
                setShowModal={setShowModal}
                setEventHandler={setEventHandler}
                setBuySelected={setBuySeleceted}
            />

            {/**Modal details  */}

            <BuyModal
                showModal={showModal}
                setShowModal={setShowModal}
                voidBuy={voidBuy}
                buySelected={buySelected}
                eventHandler={eventHandler}
                selectedBranch={selectedBranch}
                productsList={buyDetail.products}
                invoiceDiscount={buyDetail.invoiceDiscount}
                invoiceSubtotal={buyDetail.invoiceSubtotal}
                invoiceTax={buyDetail.invoiceTax}
                invoiceTotal={buyDetail.invoiceTotal}
                modalTitle={buyDetail.is_void_buy ? "Anulacion de compra" : "Detalles de la compra"}
                modalType={buyDetail.is_void_buy ? "void" : "details"}
                voidedBuyDetails={buyDetail.voided_buy_details}
            />


        </div>
    );
}

export default BuysTable;
