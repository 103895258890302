import React from 'react';
//Internal Components
import Navbar from '../NavBar/Navbar';
import BuyForm from './BuyForm';
//Components 
import { Tabs, Tab } from 'react-bootstrap';
import BuysTable from './BuysTable';

const Buys = () => {
    return (
        <>
            <div className='sp-grid bg-white no-scroll position-relative g-0'>
                <div className=' fixed-column'>
                    <Navbar titlePage="Compras" />
                </div>
                <div className='other-column '>
                </div>
                {/** Main container */}
                <div className="container mt-4">


                    <section className='banner-reports d-flex justify-content-lg-between align-items-center mb-4 banner-sp rounded-4 py-2 px-3'>
                        <div>
                            <div className='d-flex justify-content-lg-between align-items-center'>
                                <h2 className='banner-reports-title mb-0 pb-0'>
                                    Compras
                                </h2>
                            </div>
                            <p className='m-0 p-0 banner-reports-description'>
                                Área de comprar de sus producto para sus sucursales
                                (Inventario).
                            </p>
                        </div>

                    </section>

                    <div className='smart-pay-main'>
                        <div className=' d-flex justify-content-center'>
                            <h2 className='fs-2'> </h2>
                        </div>
                    </div>

                    {/**Tabs */}
                    <Tabs defaultActiveKey="all" variant='pills' className='mt-4 nav-scroller d-flex justify-content-start'>
                        {/**Tab of general report */}
                        <Tab eventKey="all" title={<><i className="fa-solid fa-house"></i> Ingreso de Compras </>}>
                            <div className='mx-lg-3 mt-4'>
                                <BuyForm />
                            </div>
                        </Tab>
                        <Tab eventKey="active" title={<><i className="fa-solid fa-money-check-dollar"></i> Compras Realizadas</>}>
                            <div className="mx-lg-3 mt-4">
                                <BuysTable />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
}

export default Buys;
