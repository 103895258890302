//React Components
import React, { useState } from 'react';
import { Modal, Button, Form, Container, Row, Col, ListGroup } from 'react-bootstrap';
import Select from 'react-select';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//API
import { SetGroupsFetchAPI } from '../../../api/SetGroups';
//Modal
import ModalAddGroupSetGroup from './ModalAddGroupSetGroup';
import list from '../../img/icons/list.png'
//Modal to list the setGroups by combo
function ModalListSetGroupByCombo({ combo, branchoffice_id }) {
    //Modals states
    const [showModal, setShowModal] = useState(false)
    const [showModalAsing, setShowModalAsing] = useState(false)
    //States
    const [setGroups, setSetGroups] = useState([])
    const [businessSetGroups, setBusinessSetGroups] = useState([])
    //Local storage
    let token = localStorage.getItem("x-access-token")
    let user = localStorage.getItem('user');

    //Function to get set Groups by combo
    const getSetGroupsByCombo = async () => {
        try {
            const response = await SetGroupsFetchAPI.getSetGroupsByCombo(combo.combo_id, token)
            setSetGroups(response.data.sets)
        } catch (err) {
            console.log(err);
            setSetGroups([])
        }
    }

    //Function to get set groups by business
    const getSetGroupsByBusiness = async () => {
        //Variables
        user = JSON.parse(localStorage.getItem('user'))
        let setGroupInfo = []
        try {
            //We get the set groups
            const response = await SetGroupsFetchAPI.getSetGroupsByBusiness(user.business_id, token)
            //We set the data
            response.data.sets.forEach(set => {
                setGroupInfo.push({ value: set.id, label: set.name })
            });
            setBusinessSetGroups(setGroupInfo)
        } catch (err) {
            console.log(err)
            setBusinessSetGroups([])
        }
    }
    //Function to  remove a group from a set group on a combo
    const removeGroupFromSetGroup = async (combo_id, set_id, group_id) => {
        //Variables
        let data = {
            group_id: group_id
        }
        try {
            //We remove the group from the set group
            await SetGroupsFetchAPI.removeGroupFromSetGroup(combo_id, set_id, data, token)
            //We reload the data
            getSetGroupsByCombo()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error no se pudo remover el item', 'error')
        }
    }

    //Function to remove a set group from a combo
    const removeSetGroupFromCombo = async (set_id, combo_id) => {
        //variables
        let data = {
            "set_id": set_id
        }
        try {
            //We remove the set group from the combo
            const response = await SetGroupsFetchAPI.removeSetGroupFromCombo(combo_id, data, token)
            console.log(response);
            //We reload the data
            getSetGroupsByCombo()
            ErrorAlert('', 'Conjunto de productos removido', 'success')
        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error no se pudo remover el conjunto de productos', 'error')
        }
    }
    //Function to asing the set group to a combo
    const asingSetGroupToCombo = async (e) => {
        e.preventDefault();
        try {
            //We get the data
            const form = new FormData(e.target);
            let data = {
                set_groups: [
                    Object.fromEntries(form.entries())
                ]
            }
            //We asing the set group to the combo
            const response = await SetGroupsFetchAPI.asingSetGroupToCombo(data, combo.combo_id, token)
            //We reload the data
            getSetGroupsByCombo()
            setShowModalAsing(false)
            ErrorAlert('', 'Conjunto de producto asignado con exito', 'success')
        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.message, 'Alerta', 'warning')
        }
    }

    //We get the groups of the set group
    const groupsBySetGroup = (setGroup) => {
        let groups = setGroup.groups
        {/** The list of groups of the set group */ }
        const viewGroups = groups.map(group => (
            <ListGroup as="ol" >
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <img
                        className='imgitemsList m-1'
                        src={group.img}
                        alt={group.name}
                    />
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">
                            {group.name}
                        </div>
                        <div className="">
                            <b>Precio:</b> ${parseFloat(group.price).toFixed(2)}
                        </div>
                        <div className="">
                            <b> Descripcion:</b> {group.description}
                        </div>
                    </div>

                    <div>
                        {/* <ModalEditItemTosegment item={item} segment={segment} load={getSegmentsByGroups}></ModalEditItemTosegment> */}
                        <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => removeGroupFromSetGroup(setGroup.combo_id, setGroup.set_id, group.group_id)}>
                            <i className="uil uil-trash-alt"></i>
                        </button>
                    </div>
                </ListGroup.Item>
            </ListGroup>
        ));
        //We return the view of the groups
        return (viewGroups)
    }

    return (
        <>



            <button className='btn btn btn-outline-light  pulse rounded-3 p-2 lh-1 border'
                onClick={(e) => (setShowModal(true), getSetGroupsByCombo(), getSetGroupsByBusiness())}
            >
                <img className='icon-options-cards' src={list} alt="List" />

            </button>



            {/**Modal to list the set groups */}
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='lg'
                centered>
                <Modal.Header className='text-center'>
                    <h3><i className="uil uil-list-ol"></i>Conjuntos de productos </h3>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='row'>
                            <div className='col-11' >
                                <ListGroup as="ol" >
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <img
                                            className='imgitemsList m-1'
                                            src={combo.img}
                                            alt={combo.name}
                                        />
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{combo.name}</div>
                                            {combo.description}
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <h5 className='fs-4 fw-bold m-2 text-center'>Detalles de los productos</h5>
                                <div className='d-flex justify-content-center align-item-center m-3'>

                                    <button className='btn btn-primary btn-sm border-0' onClick={() => (setShowModalAsing(true))}> <i className="uil uil-plus"></i> Asignar Conjunto
                                    </button>
                                </div>
                            </div>
                            <div className='col-11'>

                                <ListGroup as="ol" >
                                    {setGroups.map(setGroup => (
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="m-2 me-auto col-12">
                                                <div className="fw-bold d-flex justify-content-between align-items-start">{setGroup.name}
                                                    <ModalAddGroupSetGroup setGroup={setGroup} branchoffice_id={branchoffice_id} loadSetGroup={getSetGroupsByCombo} ></ModalAddGroupSetGroup>
                                                </div>

                                                <Container>
                                                    <Row>
                                                        <Col >{setGroup.isMainSetGroup === 1 ? 'Principal' : 'Secundario'}</Col>
                                                        <Col >
                                                            <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => removeSetGroupFromCombo(setGroup.set_id, setGroup.combo_id)}>
                                                                <i className="uil uil-trash-alt">Remover conjunto producto</i>
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                                {/** The list of groups on the set group */}
                                                {groupsBySetGroup(setGroup)}
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

            {/** Modal to asing the set groups */}
            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar Conjunto</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={asingSetGroupToCombo} className="needs-validation">
                        {/** Select of set groups */}
                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Seleccione conjunto</Form.Label>
                            <Select
                                name="set_id"
                                className='w-100 '
                                options={businessSetGroups}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Cantidad de selecciones</Form.Label>
                            <Form.Control
                                type='number'
                                name="count"
                                className='w-100'
                                defaultValue={1}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Es el producto principal del combo?</Form.Label>
                            <Form.Select aria-label="" name="isMainSetGroup">
                                <option value={0}>No</option>
                                <option value={1}>Si</option>
                            </Form.Select>
                        </Form.Group>
                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit" className='m-3'  >
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsing(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalListSetGroupByCombo