import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { RenderIf } from '../../utils/RenderIf';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { PromotionsFechtAPI } from '../../../api/Promotions';
import { GroupsFechtAPI } from '../../../api/Groups';
import { TaxesFetchAPI } from '../../../api/Taxes';
import eye from '../../img/icons/eye.png'
function ModalViewPromotion({ promotion }) {
    const [showModal, setShowModal] = useState(false);
    const [showModalTaxes, setShowModalTaxes] = useState(false);
    const [eventHandle, setEventHandle] = useState(0);
    const [taxes_list, setTaxes_list] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [taxSelect, setTaxSelect] = useState("");
    const [segments, setSegments] = useState([])
    const [infoPromotion, setInfoPromotion] = useState({})
    const [group, setGroup] = useState({ group: "" });
    const [imgpreview, setImgpreview] = useState(promotion.img)
    let token = localStorage.getItem("x-access-token")
    let user = localStorage.getItem('user');

    const getPromotionById = async () => {
        try {
            const response = await PromotionsFechtAPI.getPromotionsByid(promotion.promotion_id, token)
            console.log(response.data.data);
            setInfoPromotion(response.data.data)
            setTaxes_list(response.data.data.taxes)

        } catch (err) {
            console.log(err);
        }
    }

    const getGroupById = async () => {
        try {
            const response = await GroupsFechtAPI.getGroupById(promotion.group_id, token)
            console.log(response.data.data);
            setGroup(response.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);
            console.log(response);
            setTaxes(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

    const deleteTaxByPromotionId = async (tax_id) => {
        let data = {
            tax_id: tax_id,
            promotion_id: promotion.promotion_id
        }
        try {
            const response = await TaxesFetchAPI.deleteTaxByPromotionId(data, token);
            console.log(response);
            getPromotionById();

        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo remover el impuestro', 'error')
        }

    }

    const asingTaxToPromotion = async() =>{
        let data={
            tax_id: taxSelect,
            promotion_id: promotion.promotion_id
        }
        try{
            const response = await TaxesFetchAPI.asingTaxToPromotion(data, token)
            console.log(response);
            getGroupById()
            getPromotionById()
            setShowModalTaxes(false)
            ErrorAlert('', 'Impuesto asignado correctamente', 'success')
        }catch(err){
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
        }
    }






    useEffect(() => {

    }, [eventHandle])

    return (
        <>
            <button className='btn btn-outline-light  pulse rounded-3 p-2 lh-1 border'
                onClick={() => (getPromotionById(), setShowModal(true), getGroupById())}
            >
                   <img className='icon-options-cards' src={eye} alt="pencil" />
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                fullscreen
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-eye"></i>
                        Detalle de la promoción  </h3>
                </Modal.Header>
                <Modal.Body>

                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={infoPromotion.img}
                            alt={infoPromotion.name}
                        />
                    </div>
                    <Container>
                        <Row>
                            <Col>
                                <div className='mt-3'>
                                    <ListGroup as="ol" >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Nombre</div>
                                                {infoPromotion.name}
                                            </div>

                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Estado</div>
                                                {infoPromotion.status == 1 ? <><Badge bg="success">Habilitado</Badge></> : <><Badge bg="danger">Deshabilitado</Badge></>}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Descripcion</div>
                                                {infoPromotion.description}
                                            </div>

                                        </ListGroup.Item>

                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Precio</div>
                                                ${parseFloat(infoPromotion.price).toFixed(2)}
                                            </div>


                                        </ListGroup.Item>

                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Fecha de expiracion</div>
                                                {infoPromotion.expirationDate}
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </Col>
                            <Col>
                                <div className='mt-3'>
                                    <ListGroup as="ol" >

                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Impuestos
                                                    <button className='btn btn-primary btn-sm border-0 m-2' onClick={() => (getTaxes(), setShowModalTaxes(true))}>
                                                        <i className="uil uil-plus">Agregar impuestos</i>
                                                    </button>
                                                </div>
                                                {/*<RenderIf isTrue={categories.length == 0}>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto" >
                                                            No tiene categoria
                                                        </div>
                                                    </ListGroup.Item>
                                                </RenderIf>*/}
                                                <ListGroup as="ol" >
                                                    {taxes_list.map((tax) => (
                                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                            <div className="ms-2 me-auto" >
                                                                {tax.tax}
                                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={() => deleteTaxByPromotionId(tax.tax_id)} >
                                                                    <i className="uil uil-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>


                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Producto de la promocion
                                                </div>


                                                <ListGroup.Item
                                                    as="li"
                                                    className="d-flex justify-content-between align-items-start"
                                                >
                                                    <img
                                                        className='imgitemsList m-1'
                                                        src={group.img}
                                                        alt={group.name}
                                                    />
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{group.name}</div>
                                                    </div>
                                                </ListGroup.Item>

                                            </div>
                                        </ListGroup.Item>

                                    </ListGroup>
                                </div>
                            </Col>
                        </Row>
                    </Container>




                    {/*Modal add tax to product*/}
                    <Modal
                        show={showModalTaxes}
                        onHide={() => (setShowModalTaxes(false))}
                        size='large'
                        centered>
                        <Modal.Header>
                            <h3><i className="uil uil-plus"></i>Asignar impuesto</h3>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='d-flex justify-content-center align-item-center'>
                                <select className='form-select m-3' name="user_branch" id="user_branch" onChange={(e) => setTaxSelect(e.target.value)}>
                                    <option >Seleccione un impuesto</option>
                                    {taxes.map(tax => (
                                        <option value={tax.id}>{tax.tax}</option>
                                    ))}
                                </select>
                                <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => asingTaxToPromotion()} >
                                    Guardar
                                </Button>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => (setShowModalTaxes(false))}>Cerrar</Button>
                        </Modal.Footer>
                    </Modal>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >







        </>
    )
}

export default ModalViewPromotion