import React, { useState, useEffect } from 'react'
import { PositionFetchAPI } from '../../api/Position';
import { GroupsFechtAPI } from '../../api/Groups'
import { Button, Modal, Row, Col } from 'react-bootstrap';
import stand from '../../assets/img/vending/stand.png'
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { MachinesFetchAPI } from '../../api/Machines';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import { RenderIf } from '../../components/utils/RenderIf';
import { VendingFetchAPI } from '../../api/LocalRequest/Vending'
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import empty from '../../assets/img/vending/empty.png'
import Swal from 'sweetalert2';


import 'react-simple-keyboard/build/css/index.css'; // Asegúrate de importar los estilos



function ModalOperator({machine, token, branchoffice_id }) {
    let business = JSON.parse(localStorage.getItem('business_vending'));
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'));
 
    let positions_Local = [12,12,12,12,12,12,12,12]
    const [positions, setPositions] = useState([]);
    const [buttonActive, setButtonActive] = useState(false)

 

    const [showModalStock, setShowModalStock] = useState(false)

    const [disablePosition, setDisablePosition] = useState([])
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [showModalAssign, setShowModalAssign] = useState(false);
    const [showModalKeyboardControl, setShowModalKeyboardControl] = useState(false)
    const [showModalTemp, setShowModalTemp] = useState(false);
    const [allProductsBranchoffice, setAllProductsBranchoffice] = useState([])
    const [productSelect, setProductSelect] = useState({ group: "" });
    const [eventHandlerModalAmount, setEventHandlerModalAmount] = useState(0);
    const [tempConfig, setTempConfig] = useState({ tempOn: 0, tempOff: 0 });
    const [resultPosition, setResultPosition] = useState([]);
    const [temporalItem, setTemporalItem] = useState([]);
    //positions valid
  

    function actualizar() {
        window.location.reload();
    }




    //get products and the position of the machine
    const getPosition_consult = async () => {
        console.log("entre a consuta de informacion ya guardada")
        if (positions_Local.length > 0) {

            try {

                let array_position_vending_scan = positions_Local

                //Resquest get all position
                const response = await GroupsFechtAPI.getGroupsByMachine(machine.machine_per_branch_id, token);
                console.log(response);
                let postions_response = response.data.groups


                //avalible or disable position of the vending
                let temp_position = new Array;
                let temp_position_disables = new Array;
                for (let i = 0; i < array_position_vending_scan.length; i++) {
                    for (let f = 0; f < postions_response.length; f++) {
                        if ((i + 1) == postions_response[f].positionX) {
                            if (postions_response[f].positionY <= array_position_vending_scan[i]) {//Avalible
                                postions_response[f].status_position = true
                                temp_position.push(postions_response[f])
                            } else if (postions_response[f].positionY > array_position_vending_scan[i]) {//Disable
                                postions_response[f].status_position = false

                                let detail = {
                                    "group_id": null,
                                    "position_id": postions_response[f].position_id,
                                    "amount": 0
                                }
                                temp_position_disables.push(detail)
                                temp_position.push(postions_response[f])
                            }
                        }
                    }
                }

                console.log(temp_position)
                setPositions(temp_position.reverse())
                localStorage.setItem('positions', JSON.stringify(array_position_vending_scan))
                cleanDisblePosition(temp_position_disables)

            } catch (err) {
                console.log(err);
            }

        } else {
            ErrorAlert("", "Debe escanear las posiciones", "warning")
        }
    }

    //function get groups by branch office
    const getAllGroupsBranchOffice = async () => {
        try {
            const response = await GroupsFechtAPI.getGroupsByBranchoffice(branchoffice_id, token)
            setAllProductsBranchoffice(response.data.data)
            setTemporalItem(response.data.data)
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    }


   
    //update and assign disposition of the position diabled
    const cleanDisblePosition = async (data) => {

        try {
            const response = await PositionFetchAPI.cleanPositionsDisabled(machine.machine_per_branch_id, data, token)
            console.log(response);

        } catch (err) {
            //ErrorAlert("", "Las posiciones desabilitadas no fueron limpiadas", "error")
            //console.log("error");
            console.log(err);
        }
    }

    //update and assign product in the position
    const assignProductAndUpdate = async (item) => {
        LoadingAlert()
        try {
            const response = await PositionFetchAPI.updatePositionToGroup(machine.machine_per_branch_id, item, token)
            console.log(response);
            //getPosition()
            getPosition_consult();
            console.log(response);
            setShowModalDetail(false);

            ErrorAlert("", "Producto actualizado", "success")
        } catch (err) {
            console.log(err);
        }
    }

    //Save product selected and view modal details
    const handleModalDetails = (position) => {

        setProductSelect(position);
        setShowModalDetail(true)
    }

    //decrease amount product select
    const handleDecrease = () => {
        if (productSelect.amount > 0) {
            productSelect.amount = (productSelect.amount - 1)
            setProductSelect(productSelect)
            setEventHandlerModalAmount(1);
        }
    }

    //Increase amount product select
    const handleIncrease = () => {
        productSelect.amount = (productSelect.amount + 1)
        setProductSelect(productSelect)
        setEventHandlerModalAmount(1);
    }

    //update product of the  position
    const handleupdateProductPosition = (item) => {
        item.position_id = productSelect.position_id;
        console.log(item);
        assignProductAndUpdate(item);
        setShowModalAssign(false);
        setShowModalDetail(false);

    }

    //update increase temperature 
    const handleIncreaseTemp = (type) => {
        tempConfig.tempOff = (tempConfig.tempOff + 1);
        tempConfig.tempOn = (tempConfig.tempOff + 4);
        setTempConfig(tempConfig)
        setEventHandlerModalAmount(1)
    }

    //update decrease temperature 
    const handleDecreaseTemp = (type) => {

        if (tempConfig.tempOff > 0) {
            tempConfig.tempOff = (tempConfig.tempOff - 1);
            tempConfig.tempOn = (tempConfig.tempOff + 4);
        }

        setTempConfig(tempConfig)
        setEventHandlerModalAmount(1)
    }

    //clear position of the item or group
    const clearPosition = () => {
        let data = {
            "tax": null,
            "taxtValue": null,
            "dTasaITBMSValue": null,
            "group_id": null,
            "branchoffice_id": null,
            "price": null,
            "name": null,
            "img": null,
            "status": null,
            "description": null,
            "amount": null,
            "group_type": null,
            "position_id": productSelect.position_id
        }
        handleupdateProductPosition(data)
    }


    //reload the page
    const reload = () => {
        window.location.reload()
    }

    //action dispese in the vending machine
    const dispense = async (X, Y) => {
        LoadingAlert("Despacho de prueba", "Espere un momento por favor...")
        let json = { funcion: "DESPACHAR", datos: [{ X: X.toString(), Y: Y.toString() }] }
        try {
            const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
            const response = await VendingFetchAPI.operation(response_machine.data.data.android_ip, json)

            let status = response.data.data

            if (status == 0) {
                ErrorAlert("", "Despacho exitoso", "success")
            } else {
                ErrorAlert("", "Error de despacho", "error")
            }
        } catch (err) {
            ErrorAlert("", "Error de comunicacion", "error")
            console.log(err);
        }

    }


 

  



    //View position and product assign
    const viewPosition = () => {

        const reversedArray = positions


        const listPosition = reversedArray.map((position) => (
            <>{position.status_position == true ? <>
                {position.group_id == null ? <>
                    <div className=" border border-4 shadow-sm rounded opacity-25 bg-light" onClick={() => (handleModalDetails(position))}>
                        <h5 className='text-center mt-2'> {position.positionX} - {position.positionY} </h5>
                        <h5 className=' fw-bold text-center m-3'  >
                            <small className='name-stock-item'> Vacío</small>
                        </h5>
                        {/* <div className=' border border-4 shadow-sm rounded opacity-25 bg-light'>
                            <h5 className=' fw-bold text-center'  >                              
                               <small className='name-stock-item opacity-25'> {position.name} </small>
                            </h5>
                        </div>  */}
                    </div>

                </> :
                    <>
                        <div className={position.amount == 0
                            ? " border shadow-sm   bg-empty-item "
                            : (position.amount > 3
                                ? "border shadow-sm   bg-optimum-item"
                                : " border shadow-sm   bg-warning-item"
                            )}
                            onClick={() => (handleModalDetails(position))}
                        >

                            <h5 className='text-center mt-2'> {position.positionX} - {position.positionY} </h5>
                            <div className='m-1'>
                                <h5 className=' fw-bold text-center'  >
                                    <small className='name-stock-item opacity-25'> {position.name} </small>
                                </h5>
                                <h3 className='text-center m-1'>{position.amount}</h3>
                            </div>
                        </div>
                    </>}
            </> : <>
                <div className=" border border-4 shadow-sm rounded opacity-25 bg-dark text-white">
                    <h5 className='text-center mt-2'> {position.positionX} - {position.positionY} </h5>
                    <h5 className=' fw-bold text-center m-3'  >
                        <small className='name-stock-item'> Inhabilitado</small>
                    </h5>
                    {/* <div className=' border border-4 shadow-sm rounded opacity-25 bg-light'>
                            <h5 className=' fw-bold text-center'  >                              
                               <small className='name-stock-item opacity-25'> {position.name} </small>
                            </h5>
                        </div>  */}
                </div></>}</>
        ));
        return (listPosition);
    }

    //filter item assign
    const handlerFilter = (e) => {
        if (e.target.value == "") {
            setTemporalItem(allProductsBranchoffice)
            //allProductsBranchoffice
        } else {
            //let arrayFilter = allProductsBranchoffice.filter(item => JSON.stringify(item) == e.target.value)
            let arrayFilter = []
            allProductsBranchoffice.forEach(item => {
                let valueCondition = item.name.toLowerCase().includes(e.target.value.toLowerCase())
                if (valueCondition == true) {
                    arrayFilter.push(item)
                }
            });
            setTemporalItem(arrayFilter)
        }

    }

    //view Products of the branchoffice
    const viewProductBranchoffice = () => {

        const listProducts = temporalItem.map((item) => (
            <div className='col-sm-2'>
                <div className='border rounded m-3 p-2' onClick={() => (handleupdateProductPosition(item))}>
                    <div className='d-flex alight-item-center justify-content-center'>
                        <img src={item.img} height="100px"></img>
                    </div>
                    <h3 className='text-center'>{item.name}</h3>
                </div>
            </div>
        ));

        return (<>{listProducts}</>)
    }



    //reload component modal detail product select
    useEffect(() => {
        setEventHandlerModalAmount(0);
    }, [eventHandlerModalAmount]);

    return (
        <>

            <button className='btn btn-outline-primary btn-sm border-0' onClick={() => (setShowModalStock(true), getPosition_consult())}>
                <i className="uil uil-abacus"></i>
            </button>

            {/* Stock Area */}
            <Modal
                show={showModalStock}
                fullscreen
                onHide={() => setShowModalStock(false)}
                
                >
                <Modal.Header className='m-0 p-0'>
                    <button className='btn btn-primary btn-menu-vending border-0  btn-right-border'
                        //onClick={() => (syncscanVending())}
                        onClick={() => ( setShowModalStock(false))}>
                        <i className="uil uil-arrow-left icon-button"></i>
                    </button>

                    <div>
                        <div className='bd-highlight  w-100'>
                            <h2 className='title-modal-stock text-center'>
                                <i className="uil uil-web-section mx-2"></i>
                                Área de Abastecimiento
                            </h2>
                        </div>
                    </div>

                </Modal.Header>
                <Modal.Body className='m-0 p-0 no-scroll '>
                    <div className="stock-container no-scroll h-100  modalOperator ">
                        {viewPosition()}
                    </div>
                </Modal.Body>
            </Modal>
            {/* Stock Area */}

            {/*Modal details position*/}
            <Modal
                show={showModalDetail}
                fullscreen
                onHide={() => setShowModalDetail(false)}
                aria-labelledby="example-custom-modal-styling-title">

                <div className='top-menu-vending d-flex justify-content-between align-items-center'>
                    <button className='btn btn-primary btn-menu-vending  btn-right-border border-0'
                        onClick={() => (setShowModalDetail(false))}
                    >
                        <i className="uil uil-arrow-left icon-button"></i>
                    </button>
                    <div className='menu-vending-title '  >
                        <h1 className='text-center'>
                            En Abastecimiento
                        </h1>

                    </div>
                    <div>
                    </div>
                </div>
                <Modal.Body className='m-0 p-0'>
                    <RenderIf isTrue={productSelect.group_id != null}>
                        <div>
                            <div className='d-flex alight-item-center justify-content-center'>
                                <h3 className='modal-title-product'>
                                    {productSelect.name}
                                </h3>
                            </div>
                            <div className='product-stand-stock'>
                                <img src={stand} className=' background-image img-stand' alt="Stand" />
                                <div className='d-flex justify-content-center'>
                                    <img src={productSelect.img} className='foreground-image  img-modal-product-stock border border-4 rounded-sp' alt="Img Product" />
                                </div>
                            </div>

                            <div className="stokers">
                                <div className='d-flex alight-item-center justify-content-center'>
                                    <span className='title-count-stock text-center text-muted'>Cantidad de cargas realizadas </span>
                                </div>

                                <span className='px-1 number-counter'>
                                    <button className="round-button" onClick={() => (handleDecrease())}>
                                        <span className="minus"></span>
                                    </button>
                                    <p>{productSelect.amount}</p>
                                    <button className="round-button" onClick={() => (handleIncrease())}>
                                        <span className="plus"></span>
                                    </button>
                                </span>

                                <div className='d-flex alight-item-center justify-content-center'>
                                    <Button className='btn btn-primary btn-vending-product btn-menu-vending mx-3 border-0' onClick={() => (assignProductAndUpdate(productSelect))}><i className="uil uil-sync"></i>
                                        Actualizar
                                    </Button>
                                    {/*<RenderIf isTrue={productSelect.amount > 0}>
                                        <Button className='btn btn-primary btn-vending-product btn-menu-vending mx-3 border-0' onClick={() => (dispense(productSelect.positionX, productSelect.positionY))}>
                                            <i className="uil uil-sign-out-alt"></i>
                                            Despachar
                                        </Button>
                                    </RenderIf> */}
                                </div>
                            </div>

                            <RenderIf isTrue={productSelect.group_id != null}>
                                <div className='d-flex alight-item-center justify-content-between mt-5 '>
                                    <Button className='btn btn-primary btn-vending-product mx-3 border-0'
                                        onClick={() => (setShowModalAssign(true), getAllGroupsBranchOffice())}>
                                        <i className="uil uil-exchange mx-2"></i>
                                        Cambiar Producto
                                    </Button>

                                    <Button className='btn btn-success btn-vending-product mx-3 border-0'
                                        onClick={() => (clearPosition())} >
                                        <i className="uil uil-border-clear mx-2"></i>
                                        Limpiar Posición
                                    </Button>
                                </div>
                            </RenderIf>
                        </div>
                    </RenderIf>

                    <RenderIf isTrue={productSelect.group_id == null}>
                        <div className='d-flex justify-content-center align-items-center align-content-center align-self-center' onClick={() => (setShowModalAssign(true), getAllGroupsBranchOffice())}>
                            <div className='empty-stock text-center bg-light'>
                                <i className="uil uil-bring-bottom text-muted"></i>
                                <p className='text-muted'>
                                    No hay ningún producto asignado en esta  posición
                                </p>
                            </div>
                        </div>
                        <div className='d-flex alight-item-center justify-content-center m-2'>
                            <Button className='btn btn-primary btn-vending-product btn-menu-vending mx-3 border-0' onClick={() => (setShowModalAssign(true), getAllGroupsBranchOffice())}>Asignar producto</Button>
                        </div>
                    </RenderIf>
                </Modal.Body>
            </Modal>
            {/*End Modal details position*/}








            {/*Modal assign product to the position*/}
            <Modal
                show={showModalAssign}
                size='sm'
                onHide={() => setShowModalAssign(false)}
                aria-labelledby="example-custom-modal-styling-title"
                close>
                <Modal.Header>
                    <div className='col-md-12'>
                        <h1 className='text-center m-2'>Asignar producto</h1>
                        <div className="row d-flex justify-constent-center alight-item-center   p-5 ">
                            <div className='col-sm-1'><h1 className='text-center '> <i className="uil uil-search-alt"></i></h1></div>
                            <div className='col-sm-11'><input type="text" className="form-control input-vending-filter" placeholder='Buscar' onChange={(e) => handlerFilter(e)} /></div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <div className='row '>
                        {viewProductBranchoffice()}
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}
export default ModalOperator