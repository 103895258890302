import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class WarehousesFetchAPI {

    //Funtion to get the warehouses by branchoffice
    static async getWarehousesByBranchoffice(branchoffice_id, token) {
        const res = await server(`/warehouses?branchoffice_id=${branchoffice_id}`, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res;
    }
}