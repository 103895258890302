
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class NotificationsFetchAPI {

    static async createNotification(data, token) {
        const res = await server(`/notification`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: data,
        });
        return res
    }
    //Function to get the notifications
    static async getNotifications(branchoffice_code, business_code, token) {
        const res = await server(`/notification?branchoffice_code=${branchoffice_code}&business_code=${business_code}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async deleteNotification(notification_id, token) {
        const res = await server(`/notification/${notification_id}`, {
            method: "delete",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getNotificationOne(notification_id, token) {
        const res = await server(`/notification/${notification_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get the notifications types
    static async getTypeNotifications(business_id, branchoffice_id, token) {
        let route = `/branch/${branchoffice_id}/notification_type`
        //If the branch office is 0 we get the notifications of the business
        if (branchoffice_id === 0) {
            route = `/business/${business_id}/notification_type`
        }
        //We get the notifications
        const res = await server(route, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to create a new notification type
    static async createNotificationType(data, token) {
        const res = await server(`/notification_type`, {
            method: "post",
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': token
            },
            data: data,
        });
        return res
    }

    //Function to get the branchoffices where the notification is assigned
    static async getBranchofficesByNotificationTypes(notification_id, token) {
        const res = await server(`/branch/notification_type/${notification_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to edit the notification  type
    static async editNotificationType(data, token) {
        const res = await server(`/notification_type`, {
            method: "put",
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': token
            },
            data: data,
        });
        return res
    }

    //Function to asing a branchoffice to a notification type 
    static async asingBranchofficeToNotification(branchoffice_id, notification_id,  token) {
        const res = await server(`/notification_type/${notification_id}/branchoffice/${branchoffice_id}`, {
            method: "post",
            headers: { 'x-access-token': token },
        });
        return res
    }
    //Function to remove a branchoffice to a notification type
    static async removeBranchofficeToNotification(branchoffice_id, notification_id, token) {
        const res = await server(`/notification_type/${notification_id}/branchoffice/${branchoffice_id}`, {
            method: "delete",
            headers: { 'x-access-token': token },
        });
        return res
    }
    //Function to get the notification details by id 
    static async getNotificationDetails(notification_id, token) {
        const res = await server(`/notification/${notification_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to edit the notification
    static async updateNotification(notification_id, data, token) {
        const res = await server(`/notification/${notification_id}`, {
            method: "put",
            headers: { 'x-access-token': token },
            data: data,
        });
        return res
    }
}

