
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { PromotionsFechtAPI } from '../../../api/Promotions';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap'

function ModalAsingAndRemovedPromotionToBranchoffice({ branchoffice_id, load, promotionsBranchoffice }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0)
    const [promotions, setPromotions] = useState([])
    const [promotionSelect, setPromotionSelect] = useState("")
    const [searchedPromotions, setSearchedPromotions] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [promotionsSelectList, setPromotionsSelectList] = useState([])
    const [deleteMode, setDeleteMode] = useState(false)

    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    const getPromotionsByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await PromotionsFechtAPI.getPromotionsByBusiness(user.business_id, token)
            console.log(response.data.data);
            setPromotions(response.data.data)
            setSearchedPromotions(response.data.data)
        } catch (err) {
            console.log(err);
        }
    }

    const asingPromotionToBranchoffice = async (promotion_id) => {
        let data = {
            promotion_id: promotion_id
        }
        try {
            const response = await PromotionsFechtAPI.asingPromotionToBranchoffice(branchoffice_id, data, token)
            console.log(response);
            ErrorAlert('', 'Promocion asignado con exito', 'success')
            load()
            setShowModal(false)
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error en la asignacion', 'error')
            console.log(err);
        }
    }

    const deletePromotionByBrachoffice = async (promotion_id) => {
        let user = JSON.parse(localStorage.getItem('user'))
        let data = {
            "promotion_id": promotion_id
        }
        try {
            const response = await PromotionsFechtAPI.deletePromotionByBrachoffice(branchoffice_id, data, token)
            ErrorAlert('', "Promocion eliminada con exito", "success")
            load();
            setShowModal(false)
        } catch (err) {
            ErrorAlert(err.data.response.data, "Error, no se pudo remover la promocion", "error")
            console.log(err);
        }
    }

    //Filter the promotions by the term searched
    const filteredPromotions = useMemo(() => {
        if (searchTerm === '') {
            return promotions
        }
        return searchedPromotions.filter(promotion => {
            const nameMatch = promotion.promotion_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = promotion.promotion_description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, promotions]);

    const saveArrayPromotions = () => {
        if (promotionsSelectList.length > 30) {
            ErrorAlert('', 'Solo puedes agregar 30 promociones a la ves', 'warning')
        } else {
            promotionsSelectList.forEach((id, index) => {

                try {
                    if (deleteMode == true) {
                        deletePromotionByBrachoffice(id)
                    } else {
                        asingPromotionToBranchoffice(id)
                    }

                    if (index == (promotionsSelectList.length - 1)) { setPromotionsSelectList([]); setDeleteMode(false); setSearchTerm('') }
                } catch (err) {
                    ErrorAlert('', 'Uno de los promociones ya estaba asignado', 'warning')
                }
            });
        }
    }

    const handlerChecked = (e, promoiton_id) => {
        if (e.target.checked == true) {
            if (promotionsSelectList.length > 29) {
                ErrorAlert('', 'Superaste la cantidad seleccionada', 'warning')
                e.target.checked = false
            } else {
                setPromotionsSelectList([...promotionsSelectList, promoiton_id]);
            }
        } else {
            let filtrado = promotionsSelectList.filter(id => id != promoiton_id)
            setPromotionsSelectList(filtrado)
        }
    }

    const hadleModeDelete = () => {
        setShowModal(true)
        setDeleteMode(true)
        setPromotions(promotionsBranchoffice)
        setSearchedPromotions(promotionsBranchoffice)
    }


    useEffect(() => {

    }, [eventHandle])




    return (
        <>


            <div className='d-flex justify-content-center align-item-center gap-1'>
                <button className='btn btn-dark btn-sm  fw-normal bg-body text-dark border border-1 rounded-2'
                    onClick={() => (setShowModal(true), getPromotionsByBusiness())}
                >
                    <i className="uil uil-file-plus-alt"></i> Asignar
                </button>

                <button className='btn btn-dark btn-sm  fw-normal bg-body text-dark border border-1 rounded-2' onClick={() => hadleModeDelete()}>
                    <i className="uil uil-trash-alt"></i> Eliminar varios
                </button>
            </div>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                fullscreen
                centered>
                <Modal.Header className='text-center'>

                    <h3>
                        {deleteMode == true ? <>
                            <i className="uil uil-trash-alt"></i>
                            Eliminar promociones de la sucursal</> : <><i className="uil uil-file-plus-alt"></i>Asignar promociones a la sucursal</>} </h3>
                </Modal.Header>
                <Modal.Body>

                    <Container>
                        <Row>
                            <Col sm="12 m-3">
                                <h3 className='text-center m-3'>Seleccione las promociones</h3>
                                <p className='text-center '>Maximo 30 promociones</p>
                                <div className="d-flex justify-centent-center align-items-center ">
                                    <form className="row g-3 align-content-center align-items-center">
                                        <div className="col-auto ">
                                            <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                        </div>
                                        <div className="col-auto">
                                            <input
                                                spellcheck="false"
                                                className="form-control"
                                                type="text"
                                                step="any"
                                                placeholder="Ingrese producto"
                                                onChange={e => setSearchTerm(e.target.value)}

                                            />
                                        </div>
                                    </form>
                                </div>

                                <div className="m-2 ">
                                    <span className='mb-0 text-secondary fw-bold' >Promociones seleccionadas: {promotionsSelectList.length}</span>
                                </div>
                            </Col>
                            {filteredPromotions.map(promotion => (
                                <Col sm="4">
                                    <ListGroup as="ol" className='m-2' >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >

                                            <img
                                                className='imgitemsList m-1'
                                                src={promotion.img}
                                                alt={promotion.promotion_name}
                                            />
                                            <div className="ms-2 me-auto d-inline-block text-truncate">
                                                <div className="fw-bold">{promotion.promotion_name}</div>
                                                <div className="">Precio: <b>${parseFloat(promotion.price).toFixed(2)}</b></div>
                                                <div className="">Impuesto: <b>{promotion.tax}</b></div>
                                                <p className=''>{promotion.description}</p>
                                            </div>
                                            <div>
                                                <InputGroup className="">
                                                    <InputGroup.Checkbox aria-label="Checkbox for following text input" onChange={(e) => handlerChecked(e, promotion.promotion_id)} />
                                                </InputGroup>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            ))}
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between'>
                        <Button className='m-2' size="sm" onClick={() => (setShowModal(false), setPromotionsSelectList([]), setDeleteMode(false), setSearchTerm(''))}>Cerrar</Button>

                        {deleteMode == true ?
                            <Button className='m-2' size="sm" variant="danger" type="button" onClick={() => saveArrayPromotions()}>
                                Eliminar
                            </Button> :
                            <Button className='m-2' size="sm" variant="success" type="button" onClick={() => saveArrayPromotions()}>
                                Guardar
                            </Button>}
                    </div>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalAsingAndRemovedPromotionToBranchoffice