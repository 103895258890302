import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class EventsFetchAPI {

    //Function to get all the events by business
    static async getEventsByBusiness(business_id, token) {
        const res = await server(`/event?business_id=${business_id}`, {
            method: 'GET',
            headers: { 'x-access-token': token }
        });
        return res
    }

    //Function to create a new event
    static async createEvent(formData, token) {
        const res = await server(`/event`, {
            method: 'POST',
            headers: { 'x-access-token': token },
            data: formData
        });
        return res;
    }

    //Function to asing a date to an event
    static async createEventDate(event_id,formData, token) {
        const res = await server(`/event/${event_id}`, {
            method: 'POST',
            headers: { 'x-access-token': token },
            data: formData
        });
        return res;
    }

}