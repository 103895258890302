//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select'
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { WarehouseFetchAPI } from '../../api/Warehouse';
import { InventoryFetchAPI } from '../../api/Inventory';

//Internal components 
import NavBar from '../NavBar/Navbar';
import ReportTable from '../reports/table/ReportTable';
import Table from '../Table/Table';
//Modals 
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
const Report = () => {

    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    //States 
    const [eventHandler, setEventHandler] = useState(0)
    const [showModalNewWarehouse, setShowModalNewWarehouse] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    const [warehouseselected, setWarehouseSelected] = useState(0);
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [movements, setMovements] = useState([])
    const [inventoryGroups, setInventoryGroups] = useState([])
    const [inventoryTotal, setinventoryTotal] = useState(0)
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });

    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);

            setWarehouseSelected(0)
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);

            getWarehouseByBrachoffice(response.data.branchoffice.id)
        } catch (err) {
            console.log(err)
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
        }
    }

    //Function to get the warehouse by branchoffice 
    const getWarehouseByBrachoffice = async (branchoffice_id) => {
        try {
            const response = await WarehouseFetchAPI.getwarehousebybracnhoffice(branchoffice_id, 0, token)
            console.log(response);
            setWarehouses(response.data.warehouses)
            console.log(response.data.warehouses);
            setEventHandler(1)

        } catch (err) {
            console.log(err);
            setWarehouses([])
        }
    }

    //Function get Inventory 
    const getInventoryByWarehouse = async () => {
        if (warehouseselected != 0) {

            try {
                const response = await InventoryFetchAPI.getInventoryBywarehouse(warehouseselected, token)
                console.log(response.data.inventory);
                setInventoryGroups(response.data.inventory)
                let total = 0
                response.data.inventory.forEach(product => {
                    total = (total + product.quantity)
                });

                setinventoryTotal(total)

            } catch (err) {
                setInventoryGroups([])
                setinventoryTotal(0)
                ErrorAlert(err.response.data.message, 'Error de consulta', 'error')
                console.log(err);
            }

        } else {
            ErrorAlert('', 'Seleccione una bodega', 'warning')
        }
    }

    const getInventoryMovementsByWarehouse = async () => {
        try {
            const response = await InventoryFetchAPI.getInventoryMovements(warehouseselected, token)
            console.log(response);
            setMovements(response.data.movements)
        } catch (err) {
            console.log(err);
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to populate the warehouses
    const warehouseList = () => {
        const listWarehouse = warehouses.map((warehouse) => (
            <>
                <option value={warehouse.id}>{warehouse.name}</option>
            </>
        ));
        return listWarehouse
    }

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);

    }, []);

    useEffect(() => {
        setEventHandler(0)
    }, [eventHandler]);


    return (
        <>
            <div className='m-3'>

                <h4 className='fw-bold text-primary mt-2'>Filtros</h4>
                <div className="row gx-3 gy-2 align-items-center bg-white p-2 border-sp border">
                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="input_from" className='fw-bold' >Sucursal</label>
                            <select className='form-select' name="branch" id="branch" onChange={e => (getBranchoffice(business_code, e.target.value))}>
                                <option value={0}>Selecciona una sucursal</option>
                                {branchList()}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="input_from" className='fw-bold' >Bodegas</label>
                            <select className='form-select' name="branch" id="branch" onChange={(e) => setWarehouseSelected(e.target.value)}>
                                <option value={0}>Selecciona una bodega</option>
                                {warehouseList()}
                            </select>
                        </div>
                    </div>

                    <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                        <button type="button" className="btn btn-success px-5" onClick={() => (getInventoryByWarehouse(), getInventoryMovementsByWarehouse())}>Aceptar</button>
                    </div>
                </div>


                <Tab.Container defaultActiveKey="report">
                    <Row>
                        <Col sm={12} >
                            <Nav variant="underline" className="d-flex horizontal-scroll border-bottom  pe-3 ">
                                <li className="nav-sp-item">
                                    <Nav.Link eventKey="report" title="Reportes" ><i class="uil uil-file-graph"></i> Inventario</Nav.Link>
                                </li>
                                <li className="nav-sp-item"  >
                                    <Nav.Link eventKey="movements" title="Movimientos" ><i class="uil uil-file-search-alt"></i> Movimientos</Nav.Link>
                                </li>

                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="report" title="Reportes inventario">
                                    {inventoryGroups.length > 0 ? <>
                                        <section className=''>

                                            <div className="d-lg-flex justify-content-lg-between d-none mt-3">
                                                <h4 className="fw-bold text-primary">Totales de inventario </h4>

                                            </div>

                                            <div className="row justify-content-md-center mt-5">
                                                <div className="col-6 col-md-6 col-lg-6  ">
                                                    <div class="card-analitics-sp shadow-sm">
                                                        <div class="card-analitics-sp-header d-flex justify-content-between">
                                                            <span>Cantidad de productos</span>
                                                        </div>
                                                        <span class="card-value-total fw-bold text-color-sp">{inventoryGroups.length}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6">
                                                    <div class="card-analitics-sp shadow-sm">
                                                        <div class="card-analitics-sp-header d-flex justify-content-between">
                                                            <span>Inventario total</span>
                                                        </div>
                                                        <span class="card-value-total fw-bold text-color-sp"> {inventoryTotal}</span>
                                                    </div>
                                                </div>
                                            </div>


                                            <ReportTable
                                                table_name="Tabla de inventario"
                                                table_headers={["Grupo", "Cantidad"]}
                                                table_type="inventory_report"
                                                table_data={inventoryGroups}
                                            />

                                        </section>
                                    </> : <></>}
                                </Tab.Pane>

                                <Tab.Pane eventKey="movements">
                                    <section className=''>
                                        <ReportTable
                                            table_name="Tabla de moviemintos"
                                            table_headers={["Producto", "Cantidad", "Tipo moviemiento", "Razon", "Bodega raiz", "Bodega destino", "Usuario", "Fecha" ]}
                                            table_type="movements_report"
                                            table_data={movements}
                                        />

                                    </section>
                                </Tab.Pane>


                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>


            </div>
        </>
    );
}

export default Report;
