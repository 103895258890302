import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { GroupsFechtAPI } from '../../../api/Groups';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import Select from 'react-select';
import eye from '../../img/icons/eye.png'
function ModalCategoryListProducts({ category }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [groups, setGroups] = useState([])
    const [groupsSelectAsing, setGroupsSelectAsing] = useState([])
    const [showModalAsing, setShowModalAsing] = useState(false)
    const [groupSelectNow, setGroupSelectNow] = useState("")

    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');



    const getgroupsByCategory = async () => {
        try {
            const response = await GroupsFechtAPI.getGroupByIdCategory(category.category_id, token)
            setGroups(response.data.data)
        } catch (err) {
            setGroups([])
            console.log(err)
        }
    }


    const getGroups = async () => {

        user = JSON.parse(localStorage.getItem('user'));
        try {
            const response = await GroupsFechtAPI.getGroupsByBusiness(user.business_id, token)
            //setGroupsSelectAsing(response.data.data)

            let groupInfo = []
            response.data.data.forEach(group => {
                groupInfo.push({ value: group.group_id, label: group.name })
            });

            setGroupsSelectAsing(groupInfo)

        } catch (err) {
            console.log(err)

        }
    }

    const deleteCategoryToProduct = async (group_id) => {
        let data = {
            group_id: group_id
        }
        try {
            const response = await GroupsFechtAPI.deleteCategoryProduct(category.category_id, data, token)
            console.log(response);
            getgroupsByCategory();
        } catch (err) {
            console.log(err);
            ErrorAlert('', 'Error, no se pudo remover el producto', 'error')
        }
    }

    const asingProductToCategory = async () => {
        try {
            const response = await GroupsFechtAPI.asingproductToCategory(category.category_id, groupSelectNow, token)
            getgroupsByCategory()
            setShowModalAsing(false)
            ErrorAlert('', 'Producto asignado correctamente', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert('', 'Error de asignacion', 'error')
        }
    }


    const viewProduct = () => {

        const list = groups.map(product => (
            <Col  >
                <ListGroup as="ol" className='m-1' >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <img
                            className='imgitemsList m-1'
                            src={product.img}
                            alt={product.name}
                        />
                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{product.name}</div>
                            <div className='d-flex justify-constent-center align-items-center'>
                                {product.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                            </div>
                            <div className="">Precio: <b>${parseFloat(product.price).toFixed(2)}</b></div>
                            <div className="">Impuesto: <b>{product.taxes.map((tax) => (
                                <>
                                    - {tax.tax} -
                                </>
                            ))}</b></div>
                            <p className=''>{product.description}</p>
                        </div>
                        <div className='m-1'>
                            <button className='btn btn-outline-danger btn-sm border-0'
                                onClick={async () => deleteCategoryToProduct(product.group_id)}>
                                <i className="uil uil-trash-alt"></i>
                            </button>
                        </div>


                    </ListGroup.Item>
                </ListGroup>
            </Col>


        ))
        return (list)
    }

    const handleSaveTableSelect = (option) => {
        setGroupSelectNow(option.value);
    }

    useEffect(() => {

    }, [eventHandle])




    return (
        <>
            <button className='btn btn btn-outline-light  pulse rounded-3 p-2 lh-1 border'
                onClick={() => (setShowModal(true), getgroupsByCategory(), getGroups())}
            >
                <img className='icon-options-cards' src={eye} alt="restart" />

            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3><i className="uil uil-eye"></i>Productos de la categoria </h3>
                </Modal.Header>
                <Modal.Body>

                    <ListGroup as="ol" >
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <img
                                className='imgitemsList m-1'
                                src={category.img}
                                alt={category.category_name}
                            />
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{category.category_name}</div>
                                {category.secundaryName}
                            </div>
                        </ListGroup.Item>
                    </ListGroup>


                    <h5 className='fs-4 fw-bold m-2 text-center'>Detalle de los productos</h5>

                    <div className='d-flex justify-content-center align-item-center'>
                        <button className='btn btn-primary btn-sm border-0' onClick={() => (setShowModalAsing(true))}> <i className="uil uil-plus"></i> Asignar producto
                        </button>
                    </div>

                    <div className="mt-3">
                        <Container>
                            <Row>
                                {viewProduct()}
                            </Row>
                        </Container>

                        {/*<div className=" border rounded table-responsive">
                            <table className='table table-hover table-sm' >
                                <thead className='table-light'>
                                    <tr>

                                        <th>Imagen</th>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Remover</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {groups.map(item => (
                                        <tr key={item.group_id}>

                                            <td className='align-middle' >
                                                <img src={item.img} alt={item.name} className='imgitemsTable'
                                                />
                                            </td>
                                            <td className='align-middle'  >{item.name}</td>
                                            <td className='align-middle' >{item.description}</td>
                                            <td className='align-middle' >
                                                <button className='btn btn-outline-danger btn-sm border-0'
                                                    onClick={async () => deleteCategoryToProduct(item.group_id)}>
                                                    <i className="uil uil-trash-alt"></i>
                                                </button>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>*/}
                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar productos</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-item-center'>


                        <Select
                            name="user_branch" id="user_branch"
                            className='w-100 m-3'
                            options={groupsSelectAsing}
                            onChange={handleSaveTableSelect}
                        />
                        <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => asingProductToCategory()}>
                            Guardar
                        </Button>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsing(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ModalCategoryListProducts