//React Components
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//Utils
import { RenderIf } from '../../utils/RenderIf';
import { checkUserModules } from '../../utils/CheckUserModules';
//API
import { CombosFetchAPI } from '../../../api/Combos';
import { TaxesFetchAPI } from '../../../api/Taxes';

//Modal to create a new combo
function ModalCreateCombo({ loadCombos, products, user }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [taxes, setTaxes] = useState([])
    const [taxesSelect, setTaxesSelect] = useState([])
    const [imgpreview, setImgpreview] = useState('')
    let token = localStorage.getItem("x-access-token")

    //Function to register the combo 
    const submit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);
        form.append("business_id", user.business_id);
        //We check that the integration module is active
        if (user.business.posIntegration !== undefined) {
            form.append("pos_integration", user.business.posIntegration)
        }
        const createCombo = Object.fromEntries(form.entries());
        createCombo.taxes = taxesSelect

        try {
            //We update the data 
            await CombosFetchAPI.newCombo(createCombo, token);
            //We show the success alert 
            setTaxesSelect([])
            ErrorAlert('', 'Combo creado con exito', 'success')
            loadCombos();
            setImgpreview('')
            setShowModal(false);
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error de registro', 'error')
        }
    }
    //Function to get the taxes
    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);
            setTaxes(response.data.data)
        } catch (err) {
            setTaxes([])
        }
    }
    //Function to change img
    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }

    //filter checkbox in state true and save in state array
    const getChecked = (event) => {
        let dataTaxes = JSON.parse(event.target.value)
        //We check if the checkbox is checked
        if (event.target.checked == true) {
            setTaxesSelect([...taxesSelect, dataTaxes])
        } else if (event.target.checked == false) {
            for (let i = 0; i < taxesSelect.length; i++) {
                if (taxesSelect[i] == dataTaxes) {
                    taxesSelect.splice(i, 1)
                }
            }
        }
    }

    //Hook to charge the data when the page is loaded
    useEffect(() => {
        setEventHandle(0)
    }, [eventHandle])




    return (
        <>
            <div><button className='btn btn-dark btn-sm  fw-normal bg-body text-dark border border-1 rounded-2' onClick={() => (setShowModal(true), getTaxes())} ><i className="uil uil-plus-circle"></i> Nuevo</button></div>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(''))}
                size='xl'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Nuevo combo </h3>
                </Modal.Header>
                <Modal.Body>
                    <RenderIf isTrue={imgpreview != ''}>
                        <div className='d-flex align-item-center justify-content-center'>
                            <img
                                className='imgEditModal'
                                src={imgpreview} />
                        </div>

                        <div className='d-flex align-item-center justify-content-center m-3'>
                            <label htmlFor="upload-button">
                                <h5 className="text-center">Seleccione una imagen</h5>
                            </label>
                        </div>
                    </RenderIf>


                    <Form
                        className="row needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="col-6 mb-3">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre del combo"
                                name="name"
                                required
                                autoFocus
                            />
                        </Form.Group>

                        <Form.Group className="col-6 mb-3">
                            <Form.Label>Producto de referencia</Form.Label>
                            <Form.Select aria-label="" name="group_id">
                                <option value="">Seleccione un producto</option>
                                {products.map((product, index) => (
                                    <option value={product.group_id}>{product.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className=" col-6 mb-3" >
                            <Form.Label>Descripcion</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                placeholder="Descripcion del combo"
                                name="description"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="col-6 -3" >
                            <Form.Label>Combo destacado/favorito</Form.Label>
                            <Form.Select aria-label="" name="favorite">
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className=" col-6 mb-3" >
                            <Form.Label>Habilitar combo para venta cruzada</Form.Label>
                            <Form.Select aria-label="" name="cross_sell">
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </Form.Select>
                        </Form.Group>


                        <Form.Group className="col-6 mb-3" >
                            <Form.Label>Impuestos </Form.Label>
                            <div>
                                {taxes.map((tax, index) => (
                                    <>
                                        <Form.Check
                                            inline
                                            label={tax.tax}
                                            name="taxes"
                                            type='checkbox'
                                            value={JSON.stringify(tax.id)}
                                            onChange={(e) => getChecked(e)}
                                            id={`tax${tax.id}`}
                                        />
                                    </>
                                ))}
                            </div>
                        </Form.Group>
                        
                        <RenderIf isTrue={checkUserModules('pos_integrations_module', user.modules) !== -1 && user.business.posIntegration !== undefined}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Referencia externa</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Referencia externa"
                                    name="external_reference"
                                />
                            </Form.Group>
                        </RenderIf>

                        <Form.Group controlId="formFileMultiple" className="col-6 mb-3">
                            <Form.Label></Form.Label>
                            <Form.Control type="file"
                                id="upload-button"
                                placeholder="Imagen"
                                name="files"
                                onChange={(e) => changeimg(e)}
                            />
                        </Form.Group>


                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false), setImgpreview(''))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalCreateCombo