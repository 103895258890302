import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class InventoryFetchAPI {

    static async receiveInventory(data, token) {
        const res = await server(`/inventory/receive`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async sendInventory(data, token) {
        const res = await server(`/inventory/send`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async sendInventory(data, token) {
        const res = await server(`/inventory/send`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async transferInventory(data, token) {
        const res = await server(`/inventory/transfer`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }
   
    static async getInventoryBywarehouse(warehouse_id, token) {
        const res = await server(`/inventory/warehouse/${warehouse_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }
    
    static async getInventoryMovements(warehouse_id, token, status) {

        let url =""
        if(status !=  undefined){
            url = `/inventory/movements?warehouse_id=${warehouse_id}&status=${status}&type=transfer`
        }else{
            url = `/inventory/movements?warehouse_id=${warehouse_id}`
        }
        const res = await server(url, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async Updatetransferinventorymovement( token, data) {
        const res = await server(`/inventory/transfer`, {
            method: "put",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }
  
}
