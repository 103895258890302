//React Components
import React, { useState, useEffect } from 'react';
import { Col, ListGroup } from 'react-bootstrap';
//Components
import Navbar from '../NavBar/Navbar';
import ModalDetailEvents from './ModalDetailEvents';
import SmartPayForm from '../Modal/Modal';
//Alert
import ErrorAlert from '../Alerts/ErrorAlert';
//API
import { EventsFetchAPI } from '../../api/Events';
//Images
import defaultImage from "../../assets/img/default.png"
//Utils
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';

//Modals 
//import SmartpayFormModal from '../Modal/SmartpayFormModal'; 
// Component of the Events
const Events = () => {
    //User 
    let user = JSON.parse(localStorage.getItem('user'));
    let token = localStorage.getItem('x-access-token');
    //States
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState({});
    const [showNewEventModal, setShowNewEventModal] = useState(false);
    const [showDatesEventModal, setShowDatesEventModal] = useState(false);
    //We set the modal fields 
    const [formData, setFormData] = useState({});
    const [modalFields, setModalFields] = useState([
        { name: 'name', label: 'Nombre', type: 'text', placeholder: 'Ingrese nombre', required: true },
        { name: "public", label: "Publico esperado", type: "number", placeholder: "Ingrese la cantidad de publico esperado", required: true },
        { name: 'description', label: 'Descropcion', type: 'text', placeholder: 'Ingrese descripcion', required: true },
        { name: 'initialDate', label: 'Fecha de inicio', type: 'date', placeholder: 'Fecha de inicio', required: true },
        { name: 'maxDate', label: 'Fecha de fin', type: 'date', placeholder: 'Fecha de fin', required: true }
    ]);
    const [formDataDates, setFormDataDates] = useState([
        { name: 'initialDate', label: 'Fecha de inicio', type: 'date', placeholder: 'Fecha de inicio', required: true },
        { name: 'maxDate', label: 'Fecha de fin', type: 'date', placeholder: 'Fecha de fin', required: true },
        { name: "public", label: "Publico esperado", type: "number", placeholder: "Ingrese la cantidad de publico esperado", required: true },
    ]);



    //Function to detect the change of value on the users forms
    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    //Function to get the events
    const getEvents = async () => {
        try {
            //Get the events by business
            const response = await EventsFetchAPI.getEventsByBusiness(user.business_id, token);
            setEvents(response.data.events)
        } catch (err) {
            setEvents([])
        }
    }

    //Function to create a new event 
    const newEvent = async (e) => {
        e.preventDefault();
        try {
            //We add the business id to the form data
            formData.business_id = user.business_id;
            //Create a new event
            await EventsFetchAPI.createEvent(formData, token);
            //Get the events
            getEvents();
            //Close the modal
            setShowNewEventModal(false);
            //Show the alert
            ErrorAlert('', 'Evento creado', 'success');
            setFormData({});

        } catch (err) {
            ErrorAlert(err, 'Error al crear el evento', "error");
        }
    }

    //Function to add a new date to the event
    const addDateToEvent = async (e) => {
        e.preventDefault();
        try {
            //Create a new date to the event
            await EventsFetchAPI.createEventDate(selectedEvent.id, formData, token);
            //Get the events
            getEvents();
            //Close the modal
            setShowDatesEventModal(false);
            //Show the alert
            ErrorAlert('', 'Fecha agregada', 'success');
            setFormData({});

        } catch (err) {
            ErrorAlert(err, 'Error al agregar la fecha', "error");
        }
    }

    //Function to render the list of events
    const renderEvents = () => {
        const list = events.map(event => (
            <Col sm="2" md="6" lg="3" xl="3" >

                <div
                               className="d-flex justify-content-between align-items-center border rounded-3 p-2"
                >
                    <img
                        className='imgitemsList m-1 border'
                        src={event.img ? event.img : defaultImage}
                        alt=""
                    />
                    <div className="ms-2 me-auto d-inline-block text-truncate">


                        <div className="ms-1 me-auto d-inline-block">
                            <div className="fw-bold text-truncate card-item-categorie-title">{event.name}</div>
                            <p className='text-secondary fw-light text-truncate card-item-categorie-description'>
                                {event.description}
                            </p>
                        </div>


                        <div className='d-flex justify-constent-center align-items-center gap-1'>
                            <div>
                                <ModalDetailEvents
                                    event={event}
                                />
                            </div>
                            {/** Button to create a new date to the event */}
                            <RenderIf isTrue={checkUserPermission('create_date_event', user.permissions) !== -1}>
                                <div onClick={() => (setSelectedEvent(event), setShowDatesEventModal(true))}>
                                    <button className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border' >
                                        <i className="uil uil-calendar-alt text-dark"></i>
                                    </button>
                                </div>
                            </RenderIf>

                        </div>
                    </div>
                </div>
            </Col>

        ))
        return (list)
    }
    //Hook to get the events
    useEffect(() => {
        getEvents();
    }, []);

    return (
        <>
            <div className='sp-grid bg-white no-scroll position-relative g-0'>
                <div className=' fixed-column'>
                    <Navbar titlePage="Eventos" />
                </div>
                <div className='other-column '>
                </div>
                {/** Body header */}
                <div className='m-2'>
                    <main className="container mt-4">
                        <section className='banner-reports d-flex justify-content-lg-between align-items-center mb-4 banner-sp rounded-4 py-2 px-3'>
                            <div>
                                <div className='d-flex justify-content-lg-between align-items-center'>
                                    <h2 className='banner-reports-title mb-0 pb-0'>
                                        Eventos
                                    </h2>
                                </div>
                                <p className='m-0 p-0 banner-reports-description'>Gestión y creación de eventos.</p>
                            </div>
                            {/** Button to create a new event */}
                            <RenderIf isTrue={checkUserPermission('create_event', user.permissions) !== -1}>
                                <SmartPayForm
                                    button_text={"Crear evento"}
                                    formType={"addForm"}
                                    buttonModal={true}
                                    modal_header_text={"Crear evento"}
                                    modal_body={modalFields}
                                    initialFormValues={modalFields}
                                    setShowModal={setShowNewEventModal}
                                    showModal={showNewEventModal}
                                    submit={newEvent}
                                    handleFieldChange={handleFieldChange}
                                />
                            </RenderIf>
                        </section>
                    </main>

                    {/** Main container */}
                    <div className="container">
                        <div className="row  mx-1 mt-3 ">
                            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4  p-1 order-0 order-lg-0 order-md-0'>
                                <div className='d-flex justify-content-lg-between justify-content-md-start justify-content-center'>
                                    <h4 className=' fs-5 fw-bold text-left text-primary'>
                                        Listado de eventos
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {renderEvents()}
                        </div>
                    </div>

                </div>
            </div>
            {/** Modal to add a new date to the event */}
            <SmartPayForm
                formType={"addForm"}
                buttonModal={false}
                modal_header_text={"Ingrese nueva fecha"}
                modal_body={formDataDates}
                initialFormValues={formData}
                setShowModal={setShowDatesEventModal}
                showModal={showDatesEventModal}
                submit={addDateToEvent}
                handleFieldChange={handleFieldChange}
            />
        </>
    );
}

export default Events;
