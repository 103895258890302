//React Components
import React, { useState, useEffect, useMemo } from 'react';
//Components 
import { Form } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
//API
import Swal from "sweetalert2";
import { GroupsFechtAPI } from '../../api/Groups';
import { CategoriesDashFetchAPI } from '../../api/CategoriesDash';
import ModalCreateCategories from './ModalCategories/ModalCreateCategorie';
import ModalEditCategorie from './ModalCategories/ModalEditCategories';
import ModalCategoryListProducts from './ModalCategories/ModalCategoryListProducts';
import ModalAsingGroupToBranchoffice from './ModalGroups/ModalAsingAndRemovedGroupToBranchoffice';
import { RenderIf } from '../utils/RenderIf';
import ModalAsingAndRemovedCategoryToBranchoffice from './ModalCategories/ModalAsingAndRemovedCategoryToBranchoffice';

//Catalogs
import ModalListCatalogs from './ModalCatalogs/ModalListCatalogs';

//icons
import on from '../img/icons/on.png'
import off from '../img/icons/off.png'
import trash from '../img/icons/delete.png'

const CateoriesTable = ({ branchoffice_id, eventHandler, token, user, title }) => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        // We load the products list 
        //loadProducts();
        loadCategories();
    }, [eventHandler]);

    //Function to load actegories
    const loadCategories = async () => {
        let user = JSON.parse(localStorage.getItem("user"))
        try {
            if (branchoffice_id == 0) {
                const response = await CategoriesDashFetchAPI.getCategoriesByBusiness(user.business_id, token)
                console.log(response)
                setCategories(response.data.data)
                setSearchedProducts(response.data.data)
            } else {
                const response = await CategoriesDashFetchAPI.getCategoriesByBranchOffice(branchoffice_id, token)
                console.log(response)


                setCategories(response.data.data)
                setSearchedProducts(response.data.data)
            }


        } catch (err) {
            setCategories([])
            setSearchedProducts([])
        }
    }


    //delete category
    const deleteCategorytoBranchoffice = async (category_id) => {
        let user = JSON.parse(localStorage.getItem("user"))

        let data = {
            category_id: category_id
        }

        try {


            const response = await CategoriesDashFetchAPI.deleteCategorytoBranchoffice(category_id, branchoffice_id, token);
            console.log((response))
            ErrorAlert('', 'Categoria eliminada', 'success')
            loadCategories()

        } catch (err) {
            console.log(err)
            ErrorAlert('', 'Error, no se pudo eliminar la categoria', 'error')
        }
    }

    const beforeDelete = async (category_id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Estas seguro?',
            text: "Quieres eliminar esta categoria de la sucursal?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si eliminar',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCategorytoBranchoffice(category_id)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'No se elimino la categoria',
                    'error'
                )
            }
        })
    }


    //Filter the products by the term searched 
    const filteredProducts = useMemo(() => {
        if (searchTerm === '') {
            return categories
        }
        return searchedProducts.filter(categorie => {
            const nameMatch = categorie.category_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = categorie.secundaryName.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, categories]);

    //Function to listen the swicht 
    const handleSwitchChange = async (event) => {
        //We set the data to update 
        let status = 1
        if (event.target.checked == false) {
            status = 0
        }
        try {

            if (branchoffice_id == 0) {

                const formData = new FormData();
                formData.set('status', status);

                //We update the data 
                let response = await CategoriesDashFetchAPI.UpdateCategories(formData, event.target.id, token);
                //We show the success alert 
                SuccessAlert(response.data.message);

            } else {
                let data = { status: status }

                //We update the data 
                let response = await CategoriesDashFetchAPI.UpdateCategoriesByBranchOffice(data, event.target.id, branchoffice_id, token);
                //We show the success alert 
                SuccessAlert(response.data.message);

            }

            // Recarga los categorias al cambiar el estado del switch
            loadCategories()

        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error);
            //We put the swicht checked false 
            event.target.checked = false
            // Recarga los productos al cambiar el estado del switch
            loadCategories()
        }
    };

    {/* Card Categories  */ }
    const viewCategories = () => {
        const list = filteredProducts.map(categorie => (
            <Col xs="12" sm="6" md="6" lg="4" xl="3">
                <article className={categorie.status === 1
                    ? 'card-on shadow-sm rounded-4 mt-4 p-1 mx-2'
                    : 'border rounded-4 mt-4 p-1'} >
                    <div className="d-flex justify-content-between align-items-start">
                        <img
                            className='imgitemsList  border'
                            src={categorie.img}
                            alt={categorie.category_name}
                        />
                        <div className="ms-1 me-auto d-inline-block">
                            <div className="fw-bold text-truncate card-item-categorie-title">{categorie.category_name}</div>
                            <p className='text-secondary fw-light text-truncate card-item-categorie-description'>{categorie.secundaryName}</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center rounded-3 mt-1'>
                        <div className=' d-flex align-items-center justify-content-center'>
                            <div className=''>
                                <RenderIf isTrue={branchoffice_id != 0}>
                                    <button className='btn btn-outline-light  pulse rounded-3 p-2 lh-1 border'
                                        onClick={async () => beforeDelete(categorie.category_id)}>
                                        <img className='icon-options-cards' src={trash} alt="Delete" />
                                    </button>
                                </RenderIf>
                            </div>
                            <div className='mx-1'><ModalEditCategorie category={categorie} loadCategories={loadCategories}></ModalEditCategorie></div>
                            <div className=''><ModalCategoryListProducts category={categorie}></ModalCategoryListProducts></div>
                        </div>
                        <div>
                            <input type="checkbox" class="btn-check" autocomplete="off"
                                id={categorie.category_id}
                                label=""
                                checked={categorie.status === 1 ? true : false}
                                onChange={handleSwitchChange}
                            />

                            {categorie.status === 1
                                ? <> <label className="icon-on pulse rounded-3 p-2 lh-1 border-on" for={categorie.category_id}>
                                    <img className='icon-options-cards' src={on} alt="restart" />
                                    <small className='ms-1 text-on fw-semibold'>Activo</small>
                                </label>
                                </>
                                : <>
                                    <label className="btn bg-light pulse rounded-3 p-2 lh-1 border opacity-75" for={categorie.category_id} >
                                        <img className='icon-options-cards' src={off} alt="restart" />
                                        <small className='ms-1 text-secondary'>Inactivo</small>
                                    </label>
                                </>}
                        </div>
                    </div>
                </article>
            </Col>
        ))
        return (list)
    }

    const [isVisible, setIsVisible] = useState(false);

    // Función que se activa al hacer scroll
    const handleScroll = () => {
        const targetDiv = document.getElementById('target-div');
        const targetPosition = targetDiv.getBoundingClientRect().top;

        if (targetPosition < window.innerHeight) {
            setIsVisible(true); // Muestra el botón cuando pasamos el div
        } else {
            setIsVisible(false); // Oculta el botón antes de llegar al div
        }
    };

    // Añade y elimina el evento de scroll
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>

            <div className='mb-5 '>
                <div className="row  mx-1 mt-3 border rounded-3 bg-card-filters py-2 p-lg-4 sticky-lg-top">
                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4  p-1 order-0 order-lg-0 order-md-0'>
                        <div className='d-flex justify-content-lg-between justify-content-md-start justify-content-center align-items-center'>
                            <h4 className=' fs-4 fw-bold text-left text-white'>
                                {title}
                                <span className='badge rounded-pill bg-primary-subtle text-primary border-primary shadow-sm rounded-pill mx-1'>
                                    {categories.length}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4  p-1 order-2 order-lg-1 order-md-2">
                        <div className="d-flex justify-content-md-center justify-content-center">
                            <form className="mx-2">
                                <div className="col-auto ">
                                    <input
                                        spellcheck="false"
                                        className="form-control input-search-filters"
                                        type="text"
                                        step="any"
                                        placeholder="Buscar producto..."
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-8 col-xl-4 p-1 order-1 order-lg-2 order-md-1" id="target-div" >
                        <div className='d-flex  justify-content-md-end justify-content-lg-end  justify-content-center align-items-center gap-1 p-1 '>
                            <div className=''>
                                <ModalCreateCategories loadCategories={loadCategories}></ModalCreateCategories>
                            </div>
                            <div className=''>
                                <ModalListCatalogs branchoffice_id={branchoffice_id} user={JSON.parse(localStorage.getItem("user"))}></ModalListCatalogs>
                            </div>

                            <RenderIf isTrue={branchoffice_id != 0}>
                                <div className=''>
                                    <ModalAsingAndRemovedCategoryToBranchoffice branchoffice_id={branchoffice_id} load={loadCategories} categoriesBranchoffice={categories}>
                                    </ModalAsingAndRemovedCategoryToBranchoffice>
                                </div>
                            </RenderIf>
                        </div>
                    </div>
                </div>                

                <div className="mt-0 m-0 p-0">
                    <Row>
                        {viewCategories()}
                    </Row>
                </div>
            </div>

            {/* {isVisible && (
                <button className="btn-flotante"><i class="uil uil-plus-circle"></i></button>
            )} */}

        </>

    );
};

export default CateoriesTable;
