import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
});


//Class to the calls of api of smartpay modules 
export class SmartpayModulesFetchAPI {

    //Function to get the smartpay modules 
    static async getSmartpayModules(token) {
        const res = await server(`/module`, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res
    }

    //Function to asing the smartpay modules to the role
    static async asingSmartpayModulesToUserRole(data, token) {
        const res = await server(`/module/rol`, {
            method: "post",
            headers: {
                'x-access-token': token
            },
            data: data
        });
        return res
    }

    //Function to get smartpay modules by role
    static async getSmartpayModulesByRole(roleId, token) {
        const res = await server(`/module/rol?role_id=${roleId}`, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res
    }

    //Function to remove a smartpay module from a role
    static async removeSmartpayModuleFromUserRole(data, token) {
        const res = await server(`/module/rol`, {
            method: "delete",
            headers: {
                'x-access-token': token
            },
            data: data
        });
        return res
    }
    //Function to asing a smartpay module permission for a business 
    static async asingSmartpayModulePermission(data, token) {
        const res = await server(`/module/permission`, {
            method: "post",
            headers: {
                'x-access-token': token
            },
            data: data
        });
        return res
    }

    //Function to remove smartpay module permission for a business 
    static async removeSmartpayModulePermission(data, token) {
        const res = await server(`/module/permission`, {
            method: "delete",
            headers: {
                'x-access-token': token
            },
            data: data
        });
        return res; 
    }
    //Function to get smartpay module permissions
    static async getSmartpayModulePermissions(role_id, module_id, token) {
        let url = `/module/permission?`;
        //We check if the user send the user role
        if (role_id !== null) {
            url = url + `role_id=${role_id}`
        }
        //We check if the user send the module
        if (module_id !== null) {
            //We check the url if the business already exist 
            if (url.includes('role_id')) {
                url = url + `&module=${module_id}`
            } else {
                url = url + `module=${module_id}`
            }
        }
        //We make the request 
        const res = await server(url, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res
    }
}