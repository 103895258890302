import React, { useState, useEffect } from 'react';
//Components 
import { Row, Col, Container, Button } from 'react-bootstrap';
import { RenderIf } from '../utils/RenderIf';
import ErrorAlert from '../Alerts/ErrorAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import { MachinesFetchAPI } from '../../api/Machines';
import { Modal } from 'react-bootstrap';
import Modalbeneficiary from './Modalbeneficiary.jsx';
//PDF
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import DocPdf from '../Pdf/DocPdf';

//API 
import { ClientsFetchAPI } from '../../api/Clients.jsx';
import { DiscountFetchAPI } from '../../api/Discounts';
import { OrdersFetchAPI } from '../../api/Orders';
import { PrinterFetchAPI } from '../../api/Printer';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import LoadingAlert from '../Alerts/LoadingAlert';
import { ReturnPayments } from '../utils/ReturnPayments.jsx';
import { PaymentMethodFetchAPI } from '../../api/PaymentMethod.jsx';

const OrderDetails = ({ pos, orderData, order_type, business_data, setLoad, setModalShow, temporalOrders, setTemporalOrdersData }) => {
    //Variables
    const token = localStorage.getItem('x-access-token');
    let user = localStorage.getItem('user');

    //States
    const [orderDetailDiscountTotal, setOrderDetailDiscountTotal] = useState(0);
    const [orderDetailGroups, setOrderDetailGroups] = useState([]);
    const [orderDetailCombos, setOrderDetailCombos] = useState([]);
    const [selectDiscount, setSelectDiscount] = useState({})
    const [businessDiscounts, setBusinessDiscounts] = useState([]);
    const [modalShowVoucher, setmodalShowVoucher] = useState(false)
    const [modalCoupon, setModalCoupon] = useState(false)
    const [infoMachine, setInfoMachine] = useState({ machine_name: "" })
    const [eventHandler, setEventHandler] = useState(0);
    const [infoPrinter, setInfoPrinter] = useState({ printer_comanda: "", printer_ticket: "" })
    const [formatVoucher, setformatVoucher] = useState("");
    const [printers, setPrinters] = useState([]);
    const [inputValueCoupon, setInputValueCoupon] = useState(0);
    const [handlerloadData, sethandlerloadData] = useState(0)



    //TRAKING DISCOUNT
    const [user_beneficiary, setuser_beneficiary] = useState({ user_id: '', user_name: '' });
    const [client_beneficiary, setclient_beneficiary] = useState({ client_id: '', client_name: '' })
    const [beneficiary, setbeneficiary] = useState('')
    const [datadiscountApply, setDatadiscountApply] = useState({})

    let machine = localStorage.getItem('machine');

    //State of the branchoffice 
    const [branchOfficeData, setBranchofficeData] = useState({
        "ruc": "",
        "dv": "",
        "name": "",
        "ruc_type": "",
        "direction": "",
        "dgi_name": "",
    });

    //We get the machine info 
    if (machine !== '' && machine !== undefined && (machine !== null)) {
        machine = JSON.parse(localStorage.getItem('machine'));
    } else {
        machine = {
            id: 0
        }
    }
    //function action print order
    let arrayPrintersTemp = []
    const getPrinter = async () => {
        try {
            const response = await PrinterFetchAPI.getPrinter(localStorage.getItem('x-access-machine-token') === '' ? localStorage.getItem('x-access-token') : localStorage.getItem('x-access-token'), machine.id);

            let arrayPrinters = response.data.printers;
            let printer_Comanda = "";
            let printer_ticket = ""
            for (let i = 0; i < arrayPrinters.length; i++) {
                getCategoriesByprinter(arrayPrinters[i])
                if (arrayPrinters[i].printer_type == 'Tickets') {
                    printer_ticket = arrayPrinters[i].printer_name
                } else if (arrayPrinters[i].printer_type == 'Comanda') {
                    printer_Comanda = arrayPrinters[i].printer_name
                }
            }
            setPrinters(arrayPrintersTemp)
            setInfoPrinter({ printer_comanda: printer_Comanda, printer_ticket: printer_ticket });

        } catch (err) {
            console.log(err);
        }
    }

    const getCategoriesByprinter = async (printer) => {

        try {
            const response = await CategriesPosFetchAPI.getCategoriesByPrinters(printer.printer_id, localStorage.getItem('x-access-machine-token'))
            printer.categories = response.data.categories

        } catch (err) {
            printer.categories = []
            console.log(err);
        }
        arrayPrintersTemp.push(printer)

    }


    //get client by business 
    const getClientsByBusiness = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ClientsFetchAPI.getClientsByBusiness(business_data.id, token);
                resolve(response.data.data);
            } catch (err) {
                console.log(err);
                reject(err);
            }
        });
    }


    const getinfoMachineById = async () => {

        console.log(orderData)
        try {
            const response = await MachinesFetchAPI.getmachineByid(orderData.machine_id, token)
            console.log(response)

            setInfoMachine(response.data.data);
        } catch (err) {
            console.log(err)
        }
    }

    //print order
    const printOrder = async (status) => {
        LoadingAlert()
        let type_ticket = ""
        if (orderData.devolution == true) {
            type_ticket = "Nota a credito"
        } else {
            type_ticket = "factura"
        }

        let arrayPrinters = []
        if (status == true) {
            printers.forEach((printer) => {
                if (printer.printer_type == "Tickets") {
                    arrayPrinters.push(printer)
                }
            });

        } else {
            printers.forEach((printer) => {
                arrayPrinters.push(printer)
            });
        }


        let JsonImprimir = [{
            "type_ticket": type_ticket,
            "Printers": arrayPrinters,
            "Nombre_maquina_impresora": infoPrinter.printer_ticket,
            "Nombre_cocina_impresora": infoPrinter.printer_comanda,
            "Nombre_zebra_impresora": "",
            "Id_Orden_Imprimir": orderData._id,
            "token": localStorage.getItem('x-access-machine-token'),
            "transaction_code": orderData.code,
            "amount_item": orderData.groups.length,
            "imprimir_comanda": "no",
        }]


        try {
            const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
            const response = await PrinterFetchAPI.printOrder(JsonImprimir, response_machine.data.data.windows_ip)
            ErrorAlert("La orden se imprimio con exito.", "Orden impresa", "success")
        } catch (err) {
            console.log(err);
            ErrorAlert("La orden no se imprimio con exito.", "Error en la impresion", "error")
        }
    }

    const cardGroup = (product, cardGroupDiscount) => {
        return (<>
            <div className="d-flex text-muted pt-3 px-xs-2" key={product._id}>
                <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={product.img} width="42" height="42" />
                <div className="pb-3 mb-0  lh-sm  w-100">
                    <div className="d-flex justify-content-between ">
                        {/* Name */}
                        <span className="text-dark fw-bold">{product.name}</span>
                        {/* Cantidad/ Quantity*/}
                        <div>
                            <span className='px-3'>x {product.amount} </span>
                            <label className="fw-bold" translate='no'>$ {product.price.toFixed(2)}</label>
                        </div>

                    </div>
                    {/** Segment items */}
                    <>
                        <div className="d-flex justify-content-between">
                            {/* Name */}

                            <span >{order_type === "permanent" ? "Descuento" : "Descuento unitario"}</span>
                            {/* Cantidad/ Quantity*/}
                            <div >
                                {/** We check the type of modal and we return the input discount field depending of it */}
                                <RenderIf isTrue={order_type === "permanent" || orderData.is_devolution_notification == true}>
                                    <label className={product.discount === undefined ? "" : "text-danger"}>{product.discount === undefined ? "No registrado" : "- $" + product.discount.toFixed(2)}</label>
                                </RenderIf>

                                <RenderIf isTrue={order_type === "temporal" && orderData.is_discount_notification == true  && cardGroupDiscount==true}>
                                    <input
                                        type="number"
                                        className='form-control-sm text-danger text-end'
                                        min="0"
                                        step="0.01"
                                        name="product_discount"
                                        data-id={product._id}
                                        placeholder={product.unit_discount === undefined ? 0.00 : product.unit_discount.toFixed(2)}
                                    />
                                </RenderIf>
                            </div>
                        </div>
                        {product.segments.map((segments) => (
                            <div className="d-flex justify-content-between" key={segments._id}>
                                {/* item name */}
                                <strong>{segments.name + ": "}</strong>
                                <span className="">{segments.item.name}</span>
                                <span className="">{parseFloat(segments.item.price).toFixed(2)}</span>
                            </div>
                        ))}

                        <div>
                            <div>
                                <small className='text-primary p-1' >
                                    Entrega: {product.delivery_type}
                                </small>
                            </div>
                            <div>
                                <RenderIf isTrue={product.note != ""}>
                                    <small className='text-danger p-1' >
                                        Nota: {product.note}
                                    </small>
                                </RenderIf>
                            </div>


                        </div>

                    </>
                </div>
            </div>
        </>)
    }


    //We charge the items of the order 
    const orderItems = () => {
        let items = orderDetailGroups.map((product) => (
            <>{cardGroup(product, true)}</>
        ));
        return <>{items}</>
    }

    //We charge the items of the order 
    const orderCombos = () => {
        let items = orderDetailCombos.map((combo) => (
            <div className="d-flex text-muted pt-3 px-xs-2" key={combo.combo_id}>
                <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={combo.img} width="42" height="42" />
                <div className="pb-3 mb-0  lh-sm  w-100">
                    <div className="d-flex justify-content-between ">
                        {/* Name */}
                        <span className="text-dark fw-bold">{combo.name}</span>
                        {/* Cantidad/ Quantity*/}
                        <div>
                            <span className='px-3'>x {combo.amount} </span>
                            <label className="fw-bold" translate='no'>$ {combo.price.toFixed(2)}</label>
                        </div>

                    </div>
                    {/** Segment items */}
                    <>
                        <div className="d-flex justify-content-between">
                            {/* Name */}

                            <span >{order_type === "permanent" ? "Descuento" : "Descuento unitario"}</span>
                            {/* Cantidad/ Quantity*/}
                            <div >
                                {/** We check the type of modal and we return the input discount field depending of it */}
                                <RenderIf isTrue={order_type === "permanent" || orderData.is_devolution_notification == true}>
                                    <label className={combo.discount === undefined ? "" : "text-danger"}>{combo.discount === undefined ? "No registrado" : "- $" + combo.discount.toFixed(2)}</label>
                                </RenderIf>

                                <RenderIf isTrue={order_type === "temporal" && orderData.is_discount_notification == true}>
                                    <input
                                        type="number"
                                        className='form-control-sm text-danger text-end'
                                        min="0"
                                        step="0.01"
                                        name="combo_discount"
                                        data-id={combo.combo_id}
                                        placeholder={combo.unit_discount === undefined ? 0.00 : combo.unit_discount.toFixed(2)}
                                    />
                                </RenderIf>
                            </div>
                        </div>
                        {combo.setGroups.map((setGroup) => (

                            <div className="d-flex justify-content-between" key={setGroup.set_id}>


                                <details close >
                                    <summary className=''>
                                        <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap m-1 '>
                                            <i className="uil uil-info-circle  px-1 "> {setGroup.name}</i>
                                        </span>
                                    </summary>


                                    {setGroup.groups.map((group) => (<>
                                        {cardGroup(group)}
                                    </>))}

                                </details>

                            </div>
                        ))}
                        <div>
                            <div>
                                <small className='text-primary p-1' >
                                    Entrega: {combo.delivery_type}
                                </small>
                            </div>
                            <div>
                                <RenderIf isTrue={combo.note != ""}>
                                    <small className='text-danger p-1' >
                                        Nota: {combo.note}
                                    </small>
                                </RenderIf>
                            </div>


                        </div>




                    </>
                </div>
            </div>
        ));
        return <>{items}</>
    }

    //We charge the select discounts 
    const discountsOptions = () => {
        let discounts = businessDiscounts.map((discount) => (
            <option key={discount.discount_id} value={JSON.stringify(discount)}>{discount.discount_name}</option>
        ));
        return discounts
    }

    //Function to calculate the rouding of a discount
    const rounding = (amount_discount) => {

        let result = (amount_discount / 0.05).toFixed(2);
        //We verify if the result is int or decimal 
        if (result % 1 == 0) {
            console.log('no es decimal')
            amount_discount = parseFloat(amount_discount);
        } else {
            console.log('es decimal');
            amount_discount = parseFloat(amount_discount) + 0.01;
            rounding(amount_discount)
        }
        return amount_discount
    }


    //decimal reduction
    const decimalReduction = (value) => {
        let stringTax = mathRound(value).toString()
        let arraySteingTax = stringTax.split(".");
        let newDecimalTaxString = []
        let sampleTax = ''
        if (arraySteingTax[1] != undefined) {
            newDecimalTaxString = arraySteingTax[1].slice(0, 2);
            sampleTax = arraySteingTax[0] + "." + newDecimalTaxString
            console.log("Hice la reduccion");
        } else {
            console.log("Array: " + arraySteingTax);
            console.log("Decimal: " + newDecimalTaxString);
            console.log("Nuevo valor: " + sampleTax);
            sampleTax = stringTax;
        }

        let endValue = parseFloat(sampleTax).toFixed(2)
        return endValue
    }

    function mathRound(num) {
        //Respuesta de Rubén
        return Math.round(num * 100) / 100;
    }

    //Function to calculate the discounts 
    const calculateDiscounts = (jsondiscount) => {
        let json = JSON.parse(jsondiscount);
        setSelectDiscount(json.type);
        let discount = json.discountRate
        //We get the discount rate 
        let discount_rate = discount / 100;
        let totaldiscounts = 0;
        const discounts = document.getElementsByName('product_discount');
        const discountscombos = document.getElementsByName('combo_discount');
        let arrayGroupId = []
        //For every group we calculate the discount of it 
        for (let i = 0; i < orderDetailGroups.length; i++) {
            arrayGroupId.push({ group_id: orderDetailGroups[i].group_id })
            //We clear the input of the discount 
            discounts[i].value = ""
            //We get the group info 
            let group_info = orderDetailGroups[i];
            //let unit_discount = decimalReduction((group_info.price * discount_rate));
            //let total_product_discount = decimalReduction(((group_info.price * discount_rate) * group_info.amount))
            let unit_discount = decimalReduction(group_info.price * discount_rate);
            let total_product_discount = decimalReduction((group_info.price * discount_rate) * group_info.amount)
            orderDetailGroups[i].unit_discount = parseFloat(unit_discount)  //rounding(unit_discount);
            totaldiscounts = totaldiscounts + parseFloat(total_product_discount) //rounding(total_product_discount);
        }

        let arrayCombosId = []
        //For every group we calculate the discount of it 
        for (let i = 0; i < orderDetailCombos.length; i++) {
            arrayCombosId.push({ combo_id: orderDetailCombos[i].combo_id })
            //We clear the input of the discount 
            discountscombos[i].value = ""
            //We get the group info 
            let combo_info = orderDetailCombos[i];
            //let unit_discount = decimalReduction((group_info.price * discount_rate));
            //let total_product_discount = decimalReduction(((group_info.price * discount_rate) * group_info.amount))
            let unit_discount = decimalReduction(combo_info.price * discount_rate);
            let total_product_discount = decimalReduction((combo_info.price * discount_rate) * combo_info.amount)
            orderDetailCombos[i].unit_discount = parseFloat(unit_discount)  //rounding(unit_discount);
            totaldiscounts = totaldiscounts + parseFloat(total_product_discount) //rounding(total_product_discount);
        }
        let dataUser = JSON.parse(user)
        let dataDiscount = {
            "name": json.discount_name,
            "discount_id": json.discount_id,
            "total": totaldiscounts,
            "percentage": json.discountRate + "%",
            "groups": arrayGroupId,
            "combos": arrayCombosId,
            "beneficiary": {
                "client_id": client_beneficiary.client_id,
                "client_name": client_beneficiary.client_name,
                "user_id": user_beneficiary.user_id,
                "user_name": user_beneficiary.user_name
            },
            "aprrovedby": {
                "user_id": dataUser.id,
                "user_name": dataUser.name
            }
        }

        setDatadiscountApply(dataDiscount)

        //We set the total discount of the order 
        orderData.discount = totaldiscounts;
        //We set the new order group detail info 
        setOrderDetailGroups(orderDetailGroups)
        setOrderDetailCombos(orderDetailCombos)
        //We set the total discount detail 
        setOrderDetailDiscountTotal(totaldiscounts);
        //We force the recharge of the component
        setEventHandler(1);
    }

    //Function to calculate the cupon 
    const calculateCupon = () => {

        let discount_rate = parseInt(inputValueCoupon);
        let totaldiscounts = 0;
        const discounts = document.getElementsByName('product_discount');
        const discountscombo = document.getElementsByName('combo_discount');
        setSelectDiscount("C");
        //For every group we calculate the discount of it 
        for (let i = 0; i < orderDetailGroups.length; i++) {
            //We clear the input of the discount 
            discounts[i].value = ""
            calculateDiscountByGroupAndCombo(orderDetailGroups[i], i, false)
        }

        for (let i = 0; i < orderDetailCombos.length; i++) {
            //We clear the input of the discount 
            discountscombo[i].value = ""
            calculateDiscountByGroupAndCombo(orderDetailCombos[i], i, true)
        }

        function calculateDiscountByGroupAndCombo(item, index, combo) {
            let total_product_discount = (item.price * item.amount).toFixed(2);
            //Verify that the discount values ​​are greater than the total value of the quantity of products
            if (discount_rate >= total_product_discount) {
                //equalizes the value of the product to its unit discount
                let unit_discount = item.price
                total_product_discount = (item.price * item.amount).toFixed(2);

                if (combo == true) {
                    orderDetailCombos[index].unit_discount = parseFloat(unit_discount)
                } else {
                    orderDetailGroups[index].unit_discount = parseFloat(unit_discount)  //rounding(unit_discount);
                }

                totaldiscounts = totaldiscounts + parseFloat(total_product_discount) //rounding(total_product_discount);
                //deducts the balance from the value of the discount applied
                discount_rate = (discount_rate - total_product_discount)
            } else {
                //Divide the discount by the quantity of the items in the group
                let unit_discount = (discount_rate / item.amount).toPrecision(2);

                if (unit_discount >= 0) {// validates that the unit discount is not negative
                    total_product_discount = (unit_discount * item.amount).toFixed(2);
                    totaldiscounts = totaldiscounts + parseFloat(total_product_discount) //rounding(total_product_discount);

                    if (totaldiscounts > parseInt(inputValueCoupon)) {// validate that the value of the discount is not greater than the value of the coupon
                        let diference_unit = ((totaldiscounts - parseInt(inputValueCoupon)) / item.amount) //calculate the difference between the discount and the coupon applied
                        unit_discount = (unit_discount - diference_unit) //calculates the adjusted unit discount
                        total_product_discount = (unit_discount * item.amount) //recalculates the value of the total product discount by the quantity
                        totaldiscounts = totaldiscounts - (totaldiscounts - parseInt(inputValueCoupon)) // readjusts the total discount applied
                        console.log(diference_unit);
                    }

                    console.log(unit_discount);
                    console.log(totaldiscounts);

                    if (combo == true) {
                        orderDetailCombos[index].unit_discount = parseFloat(unit_discount)
                    } else {
                        orderDetailGroups[index].unit_discount = parseFloat(unit_discount)  //rounding(unit_discount);
                    }

                    discount_rate = (discount_rate - total_product_discount);
                }
            }
        }

        //We set the total discount of the order 
        orderData.discount = totaldiscounts;
        //We set the new order group detail info 
        setOrderDetailGroups(orderDetailGroups)
        setOrderDetailCombos(orderDetailCombos)
        //We set the total discount detail 
        setOrderDetailDiscountTotal(totaldiscounts);
        //We force the recharge of the component
        setEventHandler(1);
        setModalCoupon(false)
    }

    //We update the discount 
    const updateOrderDiscount = async () => {
        if (datadiscountApply.beneficiary) {
            datadiscountApply.beneficiary.client_id = client_beneficiary.client_id
            datadiscountApply.beneficiary.client_name = client_beneficiary.client_name
            datadiscountApply.beneficiary.user_id = user_beneficiary.user_id
            datadiscountApply.beneficiary.user_name = user_beneficiary.user_name

            setDatadiscountApply(datadiscountApply)
        }

        const discounts = document.getElementsByName('product_discount');
        const discountscombo = document.getElementsByName('combo_discount');
        let totaldiscounts = 0;


        //We update the status of the temp order 
        orderData.status_id = 9

        if (orderData.is_discount_notification == true) {
            let discount_empty = false

            //We get the discounts of the products 
            for (let i = 0; i < orderData.groups.length; i++) {
                let product_discount = discounts[i].value !== "" ? discounts[i].value : discounts[i].getAttribute('placeholder')

                console.log(selectDiscount);
                //We check if the discount is 0

                if (selectDiscount != "%") {

                    if (product_discount === 0 || product_discount === '0.00' || product_discount === '0') {
                        if (selectDiscount != "C") {
                            discount_empty = true
                        }
                    }
                } else {
                    orderData.groups[i].taxtValue = 0;
                    orderData.groups[i].tax = "0%";
                }

                //validates that the product discount is not greater than the price
                if (product_discount > orderData.groups[i].price) {
                    totaldiscounts = totaldiscounts + parseFloat(orderData.groups[i].price * orderData.groups[i].amount);
                    orderData.groups[i].unit_discount = parseFloat(orderData.groups[i].price);
                    orderData.groups[i].discount = parseFloat(orderData.groups[i].price);
                } else {
                    totaldiscounts = totaldiscounts + parseFloat(product_discount * orderData.groups[i].amount);
                    orderData.groups[i].unit_discount = parseFloat(product_discount);
                    orderData.groups[i].discount = parseFloat(product_discount * orderData.groups[i].amount);
                }
            }

            for (let i = 0; i < orderData.combos.length; i++) {
                let product_discount = discountscombo[i].value !== "" ? discountscombo[i].value : discountscombo[i].getAttribute('placeholder')
                let combo = orderData.combos[i]
                console.log(selectDiscount);
                //We check if the discount is 0

                if (selectDiscount != "%") {

                    if (product_discount === 0 || product_discount === '0.00' || product_discount === '0') {
                        if (selectDiscount != "C") {
                            discount_empty = true
                        }
                    }
                } else {
                    combo.taxtValue = 0;
                    combo.tax = "0%";
                }

                //validates that the product discount is not greater than the price
                if (product_discount > combo.price) {
                    totaldiscounts = totaldiscounts + parseFloat(combo.price * combo.amount);
                    combo.unit_discount = parseFloat(combo.price);
                    combo.discount = parseFloat(combo.price);
                } else {
                    totaldiscounts = totaldiscounts + parseFloat(product_discount * combo.amount);
                    combo.unit_discount = parseFloat(product_discount);
                    combo.discount = parseFloat(product_discount * combo.amount);
                }
            }
            //We set the total discount of the order 
            orderData.discount = totaldiscounts;
            //We set the total discount detail 
            setOrderDetailDiscountTotal(totaldiscounts);

            if (discount_empty === true) {
                ErrorAlert('No puede haber descuentos en 0', 'Error', 'warning')
            } else {

                let arrayDiscount = []
                if (orderData.discounts) {
                    arrayDiscount = orderData.discounts
                    arrayDiscount.push(datadiscountApply)
                } else {
                    arrayDiscount.push(datadiscountApply)
                }
                try {
                    //We update the temporal order
                    await OrdersFetchAPI.updateTempOrder(
                        {
                            "_id": orderData._id,
                            "status_id": orderData.status_id,
                            "groups": orderData.groups,
                            "combos": orderData.combos,
                            "discount": orderData.discount,
                            'discounts': arrayDiscount
                        },
                        token
                    );
                } catch (err) {
                    ErrorAlert(err.error)
                }
                //We close the modal 
                setModalShow(false)
                //We find the order on the temporal orders data 
                let index = temporalOrders.findIndex(order => order._id === orderData._id);
                //We delete the order updated 
                temporalOrders.splice(index, 1);
                //We set the temporal orders with the data updated 
                setTemporalOrdersData(temporalOrders);
                //We set the reload state to reload the component 
                setLoad(1)
            }
        } else {
            try {
                //We update the temporal order
                await OrdersFetchAPI.updateTempOrder(
                    {

                        "_id": orderData._id,
                        "status_id": orderData.status_id,
                        "groups": orderData.groups,
                        "combos": orderData.combos,
                        "discount": orderData.discount
                    },
                    token
                );
            } catch (err) {
                ErrorAlert(err.error)
            }
            //We close the modal 
            setModalShow(false)
            //We find the order on the temporal orders data 
            let index = temporalOrders.findIndex(order => order._id === orderData._id);
            //We delete the order updated 
            temporalOrders.splice(index, 1);
            //We set the temporal orders with the data updated 
            setTemporalOrdersData(temporalOrders);
            //We set the reload state to reload the component 
            setLoad(1)
        }
    }

    //We cancel the order discount 
    const cancelOrderDisocunt = async () => {
        //We update the status of the temp order (of cancel)
        orderData.status_id = 8
        try {
            //We update the temporal order
            await OrdersFetchAPI.updateTempOrder(
                {
                    "_id": orderData._id,
                    "status_id": orderData.status_id
                },
                token
            );
            //Alert message 
            SuccessAlert("Descuento cancelado")
        } catch (err) {
            ErrorAlert(err.error)
            console.log(err)
        }
        //We close the modal 
        setModalShow(false);
        //We find the order on the temporal orders data 
        let index = temporalOrders.findIndex(order => order._id === orderData._id);
        //We delete the order updated 
        temporalOrders.splice(index, 1);
        //We set the temporal orders with the data updated 
        setTemporalOrdersData(temporalOrders);
        //We set the reload state to reload the component 
        setLoad(1)
    }

    // funtion convert format voucher
    const formatVoucherString = () => {

        /*if (orderData.bank_voucher_response) {
            let evertechDataTransaction = JSON.parse(orderData.bank_voucher_response);
            let datafilter = {}

            console.log("Original");
            console.log(orderData.bank_voucher_response);
            if (evertechDataTransaction.requestId == undefined) {
                let evertechDataTransaction2 = JSON.parse(evertechDataTransaction)
                if (evertechDataTransaction2.requestId == undefined) {
                    let evertechDataTransaction3 = JSON.parse(evertechDataTransaction2)
                    console.log("1");
                    console.log(evertechDataTransaction3);
                    datafilter = evertechDataTransaction3
                } else {
                    console.log("2");
                    console.log(evertechDataTransaction2);
                    datafilter = evertechDataTransaction2
                }
            } else {
                console.log(evertechDataTransaction);
                datafilter = evertechDataTransaction
            }


            if (datafilter.requestId != undefined) {
                let detailParamsPayment = ""
                datafilter.payment[0].processorFields.forEach((process) => {
                    detailParamsPayment = detailParamsPayment + `${process.keyword.toUpperCase()}:                ${process.value}\r\n`
                })

                console.log(detailParamsPayment);
                orderData.voucher_response = `\r\n            ***  EVERTECH  ***             \r\n           ${datafilter.payment[0].issuerName}           \r\nREF:                            ${datafilter.payment[0].reference}\r\nDATE: ${datafilter.status.date.split("T")[0]}               ${datafilter.status.date.split("T")[1]}\r\n\r\nDESCRIPTION:   ${datafilter.request.payment.description}\r\nTOTAL:                         ${datafilter.payment[0].amount.from.currency}. ${datafilter.payment[0].amount.from.total} \r\n            +++ DETAIL PAYMENT +++            \r\n\r\nPAYMENT METHOD:               ${datafilter.payment[0].paymentMethodName.toUpperCase()}\r\n${detailParamsPayment}\r\nSTATUS:                       ${datafilter.status.status}\r\nRECEIPT:                       ${datafilter.payment[0].receipt}\r\n\r\n                +++ PAYER +++                \r\n\r\nNAME:                        ${datafilter.request.payer.name}\r\nEMAIL:                        ${datafilter.request.payer.email}\nPHONE:                        ${datafilter.request.payer.mobile}\n           ****** FIN  ******            \r\n`;

            }

        }*/




        let voucher = orderData.voucher_response
        console.log(voucher);
        // Extraer el número de tarjeta ocultando los dígitos intermedios
        const maskedVoucher = voucher.replace(/(\d{6})\d+(\d{4})/g, "$1XXXXXX$2");

        // Construir el string formateado del voucher
        const formattedVoucher = `${maskedVoucher}`;
        setformatVoucher(formattedVoucher)


        //return formattedVoucher;
    };

    const handleViewCoucher = () => {
        formatVoucherString()
        setmodalShowVoucher(true)
    }

    //Function to make the evertech return 
    const ecommerceReturnPayment = async () => {

        try {
            let secretKey, loginKey, paymentMethodIndex, cclw, pagueloFacilAuthToken, returnType;
            //We use the loading alert 
            LoadingAlert('Procesando...', 'Por favor espere...')
            //We get the machine payment methods
            let paymentMethods = await PaymentMethodFetchAPI.getPaymentMethod(orderData.machine_id, token);

            //We check the payment type 
            if (orderData.paymenthod === "Evertech") {
                //We get the evertech params 
                paymentMethodIndex = paymentMethods.data.data.findIndex(element => element.payment === "Evertech");
                returnType = "Evertech"
            } else if (orderData.paymenthod === "PagueloFacil") {
                //We get the evertech params 
                paymentMethodIndex = paymentMethods.data.data.findIndex(element => element.payment === "PagueloFacil");
                returnType = "PagueloFacil"
            }
            //We check that the payment method exist 
            if (paymentMethodIndex !== -1) {
                //We map the variables to asing every param value 
                paymentMethods.data.data[paymentMethodIndex].params.forEach(param => {
                    switch (param.name) {
                        case "SECRET_KEY":
                            secretKey = param.value;
                            break;
                        case "LOGIN_KEY":
                            loginKey = param.value;
                            break;
                        case "CCLW":
                            cclw = param.value;
                        case "PAGUELOFACIL_AUTH_TOKEN":
                            pagueloFacilAuthToken = param.value
                        default:
                            break;
                    }
                });

                //We make the return 
                ReturnPayments({
                    secretKey,
                    loginKey,
                    paymentEvertechInternalReference: orderData.evertech_payment_reference,
                    total: orderData.total,
                    token,
                    transaction_code: orderData.transaction_code,
                    business_code: orderData.business.code,
                    branchoffice_code: orderData.branchoffice.code,
                    bank_voucher_response: { EMVStreamResponse: orderData.bank_voucher_response },
                    setLoad: setLoad,
                    cclw,
                    codOper: orderData.paguelofacil_oper_code,
                    pagueloFacilAuthToken,
                    returnType
                });


            } else {
                ErrorAlert('Metodo de pago no asignado a la maquina de esta transaccion', 'Metodo de pago no encontrado', 'warning');
            }

        } catch (err) {
            console.log(err)
        }
    }

    //Hook to load the business discounts 
    useEffect(() => {
        (async () => {
            try {
                //We get the discounts 
                const discounts = await DiscountFetchAPI.getDiscountsByBusiness(business_data.id, token);
                setBusinessDiscounts(discounts.data.data);
            } catch (err) {
                setBusinessDiscounts([])
                console.log(err);
            }

            try {
                //We get the branchoffice info 
                const response_branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(orderData.business.code, orderData.branchoffice.code);
                //We set the business data 
                setBranchofficeData(response_branch.data.branchoffice);

            } catch (err) {
                setBranchofficeData({
                    "ruc": "",
                    "dv": "",
                    "name": "",
                    "ruc_type": "",
                    "direction": "",
                    "dgi_name": "",
                })
            }
        })();
    }, []);

    //Hook to reload the component 
    useEffect(() => {
        getPrinter()
        setOrderDetailGroups(orderData.groups);
        setOrderDetailCombos(orderData.combos);
        setOrderDetailDiscountTotal(orderData.discount)
        getinfoMachineById()
        setEventHandler(0);
    }, [eventHandler]);


    useEffect(() => {
        sethandlerloadData(0)
    }, [handlerloadData]);

    return (
        <Container className='mb-2'>
            {/* Your card */}
            <Row>
                <Col sm={12} md={12}  >
                    <div className="py-4 px-lg-4 ">
                        <div className="head-section-sp mt-2">
                            <div className="">
                                <div className="mt-1 mb-3">


                                    <div className="d-flex justify-content-between align-items-center mb-3 ">
                                        <div className='header-invoice'>
                                            <div>
                                                <h5 className='p-2'>
                                                    <span className="text-success"> {orderData.order_type === "devolution" ? "Orden de referencia: " : "Notificador:"} </span>
                                                    <span className="text-secondary"># {orderData.order_type === "devolution" ? orderData.devolution_order_reference.code : orderData.locator ? orderData.locator : 0}</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <h5>
                                            <span className="text-success">Orden: </span>
                                            <span className="text-secondary"> # {orderData.transaction_code ? orderData.transaction_code : 0}</span>
                                        </h5>



                                    </div>

                                    <div>
                                        <span className="text-nowrap text-secondary h6 d-block">
                                            <i className="fa-solid fa-store"></i>&nbsp;
                                            Sucursal: {orderData.branchoffice.name}
                                        </span>
                                        <span className="text-nowrap text-primary h6 d-block">
                                            <i className="fa-solid fa-cash-register"></i>&nbsp;
                                            {infoMachine.machine_name} / {!orderData.user ? 'No es cajera' : orderData.user.name}
                                        </span>
                                        <span className="text-nowrap text-secondary h6 d-block">
                                            <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                                            Máquina: {infoMachine.code}
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <RenderIf isTrue={order_type == "temporal" && orderData.is_discount_notification == true}>
                                <div className="">
                                    <div className="mt-1 mb-3">

                                        <div className='row d-flex justify-cointent -center alight-item-center m-3'>
                                            <div className="col d-flex justify-cointent-center alight-item-center">
                                                <Button className='' onClick={() => (setModalCoupon(true))}><i class="uil uil-ticket"></i> Aplicar un cupon</Button>
                                            </div>
                                            <div className="col d-flex justify-cointent -center alight-item-center">
                                                <Modalbeneficiary business={business_data} setclient_beneficiary={setclient_beneficiary} setuser_beneficiary={setuser_beneficiary} sethandlerloadData={sethandlerloadData} setbeneficiary={setbeneficiary} getClientsByBusiness={getClientsByBusiness}></Modalbeneficiary>
                                            </div>
                                        </div>

                                        <div className='row d-flex justify-cointent-center alight-item-center m-3'>
                                            {beneficiary != '' ? <><div className="col d-flex justify-cointent-center alight-item-center">
                                                <span className="text-nowrap text-secondary h6 d-block text-center">
                                                    <i class="uil uil-chat-bubble-user"></i>
                                                    Beneficiario: {beneficiary}
                                                </span>
                                            </div></> : <></>}

                                        </div>

                                        <div className="mb-3 ">
                                            <label htmlFor="">Seleccione el descuento</label>
                                            <select className='form-select mt-2' onChange={e => calculateDiscounts(e.target.value === "Seleccione..." ? 0 : e.target.value)} name="" id="">
                                                <option value={null}>Seleccione...</option>
                                                {discountsOptions()}
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </RenderIf>
                            <div className=' d-flex justify-content-between text-secondary opacity-75'>
                                <div> Productos </div>
                                <div> Precio </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12 order-md-last">
                                    {/* Item cards */}
                                    {orderItems()}
                                    {orderCombos()}
                                    {/**Total details */}
                                    <div className="d-flex text-muted pt-3">
                                        <div className="pb-3 mb-0 small lh-sm border-top w-100">

                                            {orderData.discounts.length > 0 ? <>
                                                <div className='d-flex justify-content-center alight-item-center m-2' >
                                                    <details close >
                                                        <summary className=''>
                                                            <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap '>
                                                                <i className="uil uil-info-circle  px-1 "></i>  Detalles del descuento
                                                            </span>
                                                        </summary>
                                                        {orderData.discounts.map(data => (
                                                            <span className="badge bg-light text-dark  summary-text-pos m-2 border rounded-4 my-1 text-wrap ">
                                                                {/* <span className='kiosk-subtitle-s' >   </span> */}
                                                                <div>
                                                                    <div className='text-muted m-0 p-0 h6'> Aprobado por: <strong>{data.aprrovedby ? data.aprrovedby.user_name : ""}</strong></div>
                                                                    <div className='text-muted m-0 p-0 h6'>Beneficiario:<strong>{data.beneficiary ? data.beneficiary.user_name != "" ? <>Colaborador - {data.beneficiary.user_name} </> : <> {data.beneficiary.client_name != "" ? <>{data.beneficiary.client_name}</> : <>Consumidor final</>} </> : ""}</strong></div>
                                                                    <br /></div>

                                                                <span className='me-2 '> Tipo descuento: <strong>{data.name}</strong> </span>
                                                                <span className='me-2 '> Procentaje: <strong>{data.percentage}</strong> </span>
                                                                <span className='me-2 '> Monto: <strong>{data.total}</strong> </span>

                                                            </span>
                                                        ))}
                                                    </details>
                                                </div>
                                            </> : <></>}

                                            <div className="d-flex justify-content-end mt-3">
                                                <span className="text-gray-dark fw-light h6"> Descuento: </span>
                                                <span className='text-danger fw-light h6'>
                                                    <span className='px-2 ' translate='no'>- $</span>
                                                    {orderDetailDiscountTotal.toFixed(2)}
                                                </span>
                                            </div>

                                            {/** We render the totals if the order is type permanent */}
                                            <RenderIf isTrue={order_type === "permanent"}>
                                                <div className="d-flex justify-content-end mt-3">
                                                    <span className="text-gray-dark fw-light h6"> Subtotal: </span>
                                                    <span className='text-black fw-light h6'>
                                                        <span className='px-2 ' translate='no'>$</span>
                                                        {orderData.subtotal.toFixed(2)}
                                                    </span>
                                                </div>

                                                {orderData.taxes.map((tax) => (
                                                    <>
                                                        <div className="d-flex justify-content-end mt-3">
                                                            <span className="text-gray-dark fw-light h6"> Impuesto {tax.tax}: </span>
                                                            <span className='text-black fw-light h6'>
                                                                <span className='px-2 ' translate='no'>$</span>
                                                                {tax.total.toFixed(2)}
                                                            </span>
                                                        </div></>
                                                ))}

                                                <div className="d-flex justify-content-end mt-3">
                                                    <strong className="text-black h5"> Total: </strong>
                                                    <span className='text-black fw-bold h5'>
                                                        <span className='px-2 ' translate='no'>$ </span>
                                                        {orderData.total.toFixed(2)}
                                                    </span>
                                                </div>

                                                <div className="d-flex justify-content-end mt-3">
                                                    <strong className="text-black h6"> Propina: </strong>
                                                    <span className='text-black fw-bold h6'>
                                                        <span className='px-2 ' translate='no'>$ </span>
                                                        {orderData.tips.toFixed(2)}
                                                    </span>
                                                </div>
                                                
                                            </RenderIf>

                                            {/** We render the buttons if the order is type temporal */}
                                            <RenderIf isTrue={order_type === "temporal"}>
                                                <div className='d-flex mt-4 justify-content-between'>
                                                    <Button variant="danger" onClick={cancelOrderDisocunt} className='btn col-5' >
                                                        Cancelar
                                                    </Button>
                                                    <Button variant="success" onClick={updateOrderDiscount} className='btn col-5' >
                                                        {orderData.is_discount_notification === true ? "Aprobar descuento" : "Aprobar devolución"}
                                                    </Button>
                                                </div>
                                            </RenderIf>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>

            <Modal
                show={modalShowVoucher}
                onHide={() => setmodalShowVoucher(false)}
                centered>
                <Modal.Header>
                    Voucher de transaccion: #{orderData.transaction_code}
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex alight-item-center justify-content-center'>
                        <div>
                            <pre>{formatVoucher}</pre>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setmodalShowVoucher(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

            {/**Print button  */}
            <RenderIf isTrue={order_type === "permanent"}>
                <RenderIf isTrue={pos == true}>
                    <Button className='m-2' onClick={() => (printOrder(true))}><i className="uil uil-print m-1"></i> Imprimir solo factura</Button>
                    <Button className='m-2' onClick={() => (printOrder(false))}><i className="uil uil-print m-1"></i> Imprimir factura con comanda</Button>
                </RenderIf>
            </RenderIf>

            {/**Voucher button */}
            <RenderIf isTrue={orderData.bank_voucher_response != "0" && order_type === "permanent"}>
                <Button className='m-2' onClick={() => (handleViewCoucher())}><i className="uil uil-receipt-alt"></i> Voucher de transaccion</Button>
            </RenderIf>

            {/** PDF button */}
            <RenderIf isTrue={order_type === "permanent"}>
                <PDFDownloadLink className='pdf-viewer-sp' document={<DocPdf
                    doc_type={orderData.invoice_type === "devolution" ? "credit_note" : "invoice"} invoiceQR={orderData.dgi_qr} invoiceDate={orderData.createdAt} cufe={orderData.cufe} auth_protocol={orderData.auth_protocol ? orderData.auth_protocol : "00000000"}
                    receptor={{
                        name: orderData.client_name,
                        ruc: orderData.client_ruc,
                        direction: "Panama",
                        dv: orderData.client_dv,
                    }}
                    emitter={branchOfficeData}
                    products={orderData.groups} invoiceSubtotal={orderData.subtotal}
                    totalTaxes={orderData.taxes}
                    totalCashReceived={orderData.total} invoiceTotal={orderData.total} invoiceTotalItbms={orderData.tax} paymentMethod={orderData.paymenthod}
                />} >
                    <button className='btn btn-secondary-sp border border-sp' > {orderData.invoice_type === "devolution" ? "Descargar nota de credito" : "Descargar factura electronica"} </button>
                </PDFDownloadLink>
            </RenderIf>


            {/** Devolution button */}
            <RenderIf isTrue={orderData.paymenthod_integration_id === 5}>
                <Button className='m-2' onClick={() => ecommerceReturnPayment()} variant="danger"><i className="uil uil-receipt-alt"></i> Realizar devolucion de pasarela de pago</Button>

            </RenderIf>



            <Modal
                show={modalCoupon}
                size="md"
                onHide={() => setModalCoupon(false)}
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <div className='mt-2 text-center p-1'>
                    <h4 className='fw-bold'>  Cupon </h4>
                </div>
                <Modal.Body>
                    <div className="input-group input-group-lg">
                        <input type="number" className="form-control" placeholder='Ingrese el monto del cupon $0.00' onChange={(e) => (setInputValueCoupon(e.target.value))} />
                    </div>

                    <div className='d-flex justify-content-between mt-3'>
                        <button className='btn btn-danger btn-lg' onClick={() => setModalCoupon(false)}> Cancelar </button>
                        <button className='btn btn-success btn-lg' onClick={() => calculateCupon()} > Aplicar  </button>
                    </div>
                </Modal.Body>
            </Modal>


        </Container>


    );
}

export default OrderDetails;