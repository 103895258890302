// React Components
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//API
import { SegmentsFechtAPI } from '../../../api/Segments';
//Utils 
import { RenderIf } from '../../utils/RenderIf';
import { checkUserModules } from '../../utils/CheckUserModules';

//Function to create a new segment
function ModalCreateSegment({ loadSegments, segment_types, user }) {
    const [showModal, setShowModal] = useState(false)
    let token = localStorage.getItem("x-access-token")

    //Function to create a segment
    const createSegment = async (e) => {
        e.preventDefault();

        //We get the data
        const form = new FormData(e.target);
        //We check that the integration module is active
        if (user.business.posIntegration !== undefined) {
            form.append("pos_integration", user.business.posIntegration)
        }
        form.append("business_id", user.business_id);
        const createSegment = Object.fromEntries(form.entries());
        try {
            const response = await SegmentsFechtAPI.createSegment(createSegment, token)
            loadSegments()
            ErrorAlert("", "Segmento registrado con exito", "success")
            setShowModal(false);
        } catch (err) {
            console.log(err);
            ErrorAlert("", "Error de registro", "error")
        }
    }


    return (
        <>

            <div><button className='btn btn-dark btn-sm  fw-normal bg-body text-dark border border-1 rounded-2' onClick={() => setShowModal(true)} ><i className="uil uil-plus-circle"></i> Nuevo</button></div>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Nuevo ampañante </h3>
                </Modal.Header>
                <Modal.Body>


                    <Form
                        className="needs-validation"
                        onSubmit={createSegment}
                    >

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre de la categoria"
                                name="name"
                                required

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Decripcion</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                placeholder="Descripcion de la categoria"
                                name="description"
                                required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Seleccione el tipo de acompañante</Form.Label>
                            <Select
                                className='w-100'
                                options={segment_types}
                                name='type'
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Cantidad minima</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Cantidad minima"
                                name="minimumCount"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Cantidad maxima</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Cantidad maxima"
                                name="maximumCount"
                            />
                        </Form.Group>
                        <RenderIf isTrue={checkUserModules('pos_integrations_module', user.modules) !== -1 && user.business.posIntegration !== undefined}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Referencia externa</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Referencia externa"
                                    name="external_reference"
                                />
                            </Form.Group>
                        </RenderIf>



                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalCreateSegment