import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
});


export class SegmentsFechtAPI {

    //Function to get the segments by branchoffice 
    static async createSegment(data, token) {
        const res = await server(`/segment`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get the segments by business 
    static async getSegmentByBusiness(businessId, token) {
        const res = await server(`/segment/${businessId}/business`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }


    //Function to get the segments by promotion id 
    static async getSegmentByPromotionId(promotion_id, token) {
        const res = await server(`/segment_per_promotion/${promotion_id}/promotion`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }



    //Function to get the segments by branchoffice 
    static async getSegmentsByBranchoffice(branchoffice_id, token) {
        const res = await server(`/segment/${branchoffice_id}/branchoffice`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }


    //Function to get the segments by branchoffice 
    static async getSegmentsByPromotion(promotion_id, token) {
        const res = await server(`/segment_per_promotion/${promotion_id}/promotion`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to update a segment status of a branchoffice 
    static async updateSegmentBranchoffice(branchoffice_id, data, token) {
        const res = await server(`/segment/${branchoffice_id}/branchoffice`,
            {
                method: "put",
                data: {
                    "segment_id": data.segment_id,
                    "status": data.status,
                    "user_id": data.user_id
                },
                headers: { 'x-access-token': token },
            });
        return res
    }

    //Function to get segments asigned a group 
    static async getSegmentsByGroup(group_id, token) {
        const res = await server(`/segment_per_group/${group_id}/group`,
            {
                method: "get",
                headers: { 'x-access-token': token }
            });
        return res
    }

    //Function to get segments asigned a group 
    static async deleteSegemtOfBusiness(businessId, data, token) {
        const res = await server(`/segment/${businessId}/business`,
            {
                method: "delete",
                data: data,
                headers: { 'x-access-token': token }
            });
        return res
    }

    //Function to delete segment to the bracnhoffice
    static async deleteSegemtOfBranchoffice(branchoffice_id, data, token) {
        const res = await server(`/segment/${branchoffice_id}/branchoffice`,
            {
                method: "delete",
                data: data,
                headers: { 'x-access-token': token }
            });
        return res
    }

    //Function to get segments asigned a group 
    static async getItemsBysegment(segment_id, branchoffice_id, token) {
        const res = await server(`/item_per_segment/${segment_id}/segment?branchoffice_id=${branchoffice_id}`,
            {
                method: "get",
                headers: { 'x-access-token': token }
            });
        return res
    }


    //Function to delete segments 
    static async deleteItemtoSegment(segment_id, data, token) {
        const res = await server(`/item_per_segment/${segment_id}/segment`,
            {
                method: "delete",
                data: data,
                headers: { 'x-access-token': token }
            });
        return res
    }

    //Function to update segment
    static async updateSegment(segment_id, data, token) {
        const res = await server(`/segment/${segment_id}`,
            {
                method: "put",
                data: data,
                headers: { 'x-access-token': token }
            });
        return res
    }

    //Function to get segments asigned to the item
    static async asingItemsToSegment(item_id, segment_id, data, token) {
        const res = await server(`/item_per_segment/${item_id}/item/${segment_id}/segment`,
            {
                method: "post",
                data: data,
                headers: { 'x-access-token': token }
            });
        return res
    }


    //Function to get segments asigned to the item
    static async asingSegmentToBranchoffice(branchoffice_id, data, token) {
        const res = await server(`/segment/${branchoffice_id}/branchoffice`,
            {
                method: "post",
                data: data,
                headers: { 'x-access-token': token }
            });
        return res
    }


    //Function to add segments to the group
    static async addSegmentToGroup(group_id, segment_id, token) {
        const res = await server(`/segment_per_group/${segment_id}/segment/${group_id}/group`,
            {
                method: "post",
                headers: { 'x-access-token': token }
            });
        return res
    }

    //Get segment types 
    static async getSegmentTypes(token) {
        const res = await server(`/segment_types`,
            {
                method: "get",
                headers: { 'x-access-token': token }
            });
        return res
    }





}