//React Components 
import React, { useState, useEffect, useMemo } from 'react';
//Components
import { Row, Container, Col, Form } from 'react-bootstrap';
//Alerts
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import Swal from 'sweetalert2';
//Modals
import ModalCreateSegment from './ModalSegment/ModalCreateSegment';
import ModalSegmentListItems from './ModalSegment/ModalSegmentListItems';
import ModalEditSegment from './ModalSegment/ModalEditSegment';
import ModalImport from './ModalImport';
import ModalAsingAndRemovedSegmentToBranchoffice from './ModalSegment/ModalAsingAndRemovedSegmentToBranchoffice';
//Utils
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';
//API
import { SegmentsFechtAPI } from '../../api/Segments';
//icons
import trash from '../img/icons/delete.png'

const SegmentTable = ({ branchoffice_id, eventHandler, token, title, user }) => {
    const [segments, setSegments] = useState([]);
    const [searchedSegments, setSearchedSegments] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [segment_types, setSegment_types] = useState([])

    const deleteSegmentByBranchoffice = async (segment_id) => {
        let data = {
            segment_id: segment_id
        }

        try {
            const response = await SegmentsFechtAPI.deleteSegemtOfBranchoffice(branchoffice_id, data, token)
            ErrorAlert('', 'Acompañante eliminado', 'success')
            loadSegments()

        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo eliminar el acompañante', 'error')

        }
    }

    const beforeDelete = async (segment_id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Estas seguro?',
            text: "Quieres eliminar este acompañante?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si eliminar',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSegmentByBranchoffice(segment_id)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'No se elimino el acompañante',
                    'error'
                )
            }
        })
    }

    //Function to load segments
    const loadSegments = async () => {
        try {
            //We search the segments of the branchoffice 
            const segments = await SegmentsFechtAPI.getSegmentsByBranchoffice(branchoffice_id, token);
            setSegments(segments.data.data)
            setSearchedSegments(segments.data.data)

        } catch (err) {

            setSegments([])
            setSearchedSegments([])
        }

    };

    //Filter the segments by the term searched 
    const filteredSegments = useMemo(() => {
        if (searchTerm === '') {
            return segments
        }

        return searchedSegments.filter(segment => {
            const nameMatch = segment.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = segment.description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, segments]);

    //Function to listen the swicht 
    const handleSwitchChange = async (event) => {
        //We set the data to update 
        let data = {
            status: event.target.checked,
            segment_id: event.target.id,
            user_id: user.id
        }
        try {
            //We update the data 
            let response = await SegmentsFechtAPI.updateSegmentBranchoffice(branchoffice_id, data, token)
            //We show the success alert 
            SuccessAlert(response.data.message);
            // Recarga los segments al cambiar el estado del switch
            loadSegments();
        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error, 'Opss!', 'warning');
            //We put the swicht checked false 
            event.target.checked = false
            // Recarga los segments al cambiar el estado del switch
            loadSegments();
        }
    };


    const viewSegments = () => {

        const list = filteredSegments.map(segment => (
            <Col xs="12" sm="6" md="6" lg="4" xl="3">

                <article className={segment.status === 1 ? 'card-on shadow-sm rounded-4 mt-4 p-2 position-relative' : 'border rounded-4 mt-4 p-2 position-relative'} >
                    <div className="d-flex justify-content-between ">
                        <div className="ms-1 me-auto d-inline-block">
                            <div className='d-flex justify-content-between'>
                                <div className="fw-bold text-truncate card-item-categorie-title">
                                    <span>{segment.name}</span>
                                </div>
                            </div>
                            {/* <span >Precio: <b>${parseFloat(product.price).toFixed(2)}</b></span> */}
                            <p className='text-secondary fw-light text-truncate card-item-categorie-description'>{segment.description}</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center rounded-3 mt-1'>
                        <div className=' d-flex align-item-center justify-content-start gap-1'>

                            <div className=''>
                                {/**Render the button remove if the user has permission */}
                                <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_segment', user.permissions) !== -1}>
                                    <button className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border' onClick={async () => beforeDelete(segment.segment_id)}>
                                        <img className='icon-options-cards' src={trash} alt="restart" />
                                    </button>
                                </RenderIf>
                            </div>


                            {/* Btns */}

                            {/**We check if the user has permition to update segment */}
                            <RenderIf isTrue={checkUserPermission('update_segment', user.permissions) !== -1}>
                                <div className=''><ModalEditSegment user={user} segment={segment} loadSegments={loadSegments} segment_types={segment_types} ></ModalEditSegment></div>                            </RenderIf>
                            <div className=''><ModalSegmentListItems segment={segment} branchoffice_id={branchoffice_id} ></ModalSegmentListItems></div>
                        </div>
                        <RenderIf isTrue={branchoffice_id !== 0}>
                            <div className='d-flex justify-constent-center align-items-center'>

                                {segment.status === 1
                                    ? <> <label className="icon-on pulse rounded-3 p-2 lh-1 border-on border-0">
                                        {/* <img className='icon-options-cards' src={on} alt="restart" /> */}
                                        <small className='ms-1 text-on fw-semibold'>Estado: Activo</small>
                                    </label> </>

                                    : <> <label className="btn bg-light pulse rounded-3 p-2 lh-1  opacity-75"  >
                                        {/* <img className='icon-options-cards opacity-25' src={off} alt="restart" /> */}
                                        <small className='ms-1 text-secondary'>Estado: Inactivo</small>
                                    </label> </>}
                            </div>
                        </RenderIf>
                    </div>
                    <div className='position-absolute top-0 end-0 mt-1'>
                        <RenderIf isTrue={checkUserPermission('enable_disable_segments', user.permissions) !== -1 && branchoffice_id !== 0}>
                            <Form.Switch
                                type="switch"
                                id={segment.segment_id}
                                label=""
                                checked={segment.status === 1 ? true : false}
                                onChange={handleSwitchChange}
                            />
                        </RenderIf>
                    </div>
                </article>
            </Col>
        ))
        return (list)
    }

    //Function to get the segment types
    const getSegmentTypes = async () => {
        //Variables
        let segmentTypeInfo = []
        try {
            const response = await SegmentsFechtAPI.getSegmentTypes(token)
            //We set the segment types
            response.data.segment_types.forEach(type => {
                segmentTypeInfo.push({ value: type.id, label: type.name })
            });
            setSegment_types(segmentTypeInfo)
        } catch (err) {
            console.log(err)
            setSegment_types([])
        }
    }

    //We load the segemnts 
    useEffect(() => {
        // We load the segments list 
        loadSegments();
    }, [eventHandler]);

    //We load the initial data 
    useEffect(() => {
        getSegmentTypes()
    }, []);


    return (
        <>

            <div className='mb-5'>
                <div className="row mx-1 mt-3  border rounded-3 bg-section-filter-segments py-2 p-lg-4 sticky-lg-top">

                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4  p-1 order-0 order-lg-0 order-md-0'>
                        <div className='d-flex justify-content-lg-between justify-content-md-start justify-content-center'>
                            <h4 className=' fs-4 fw-bold text-left text-white'>
                                {title}
                                <span className='badge rounded-pill text-color-segments shadow-sm rounded-pill mx-1'>
                                    {segments.length}
                                </span>
                            </h4>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4  p-1 order-2 order-lg-1 order-md-2">
                        <div className="d-flex justify-content-md-center justify-content-center">
                            <form className="w-75 mx-2">
                                <div className="col-auto ">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Buscar producto..."
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-8 col-xl-4 p-1 order-1 order-lg-2 order-md-1">
                        <div className='d-flex  justify-content-md-end justify-content-lg-end  justify-content-center align-items-center gap-1 p-1 '>
                            {/**Validation to create segment */}
                            <RenderIf isTrue={checkUserPermission('create_segment', user.permissions) !== -1}>
                                <div className=''><ModalCreateSegment user={user} loadSegments={loadSegments} segment_types={segment_types}></ModalCreateSegment></div>
                                <div className=''><ModalImport title={"acompañante"} load={loadSegments}></ModalImport></div>
                            </RenderIf>
                            {/**Validation to eliminate segment */}
                            <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_segment', user.permissions) !== -1}>
                                <div className=''><ModalAsingAndRemovedSegmentToBranchoffice branchoffice_id={branchoffice_id} load={loadSegments} segmentsBranchoffice={segments}></ModalAsingAndRemovedSegmentToBranchoffice></div>
                            </RenderIf>
                        </div>
                    </div>
                </div>
                <div className="mt-0 m-0 p-0">
                    <Row>
                        {viewSegments()}
                    </Row>
                </div>

            </div>

        </>

    );
};





export default SegmentTable;
