import React, { useEffect, useState } from 'react';
import { UsersFetchAPI } from '../../api/Users';
import NavBar from '../NavBar/Navbar';
import Table from '../Table/Table';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
//Modals
import SmartpayModal from '../Modal/Modal';
import ModalRoles from './Roles/ModalRoles';

export default function Users() {

    //We get the user from the local storage
    let user = JSON.parse(localStorage.getItem('user'));
    let token = localStorage.getItem('x-access-token');
    //We set the states
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [selectedUserOption, setSelectedUserOption] = useState({
        name: '',
        email: '',
        phone: '',
        idCard: '',
        role_id: '',
        branchoffices: [],
        user_id: ''
    });
    const [users, setUsers] = useState([]);
    const [authorizedUserRoles, setAuthorizedUserRoles] = useState([]);
    const [eventHandler, setEventHandler] = useState(0);
    const [formData, setFormData] = useState({});

    //We set the modal fields
    const [modalFields, setModalFields] = useState([
        { name: 'name', label: 'Nombre', type: 'text', placeholder: 'Ingrese nombre', required: true },
        { name: 'email', label: 'Correo', type: 'email', placeholder: 'Ingrese correo', required: true },
        { name: 'phone', label: 'Telefono', type: 'phone', placeholder: 'Ingrese telefono', required: true },
        { name: 'idCard', label: 'Cedula', type: 'text', placeholder: 'Ingrese la cedula', required: true },
        { name: 'pass', label: 'Contraseña', type: 'text', placeholder: 'Ingrese Contraseña', required: true },
        { name: 'passwordCheck', label: 'Confirmar contraseña', type: 'text', placeholder: 'Ingrese Contraseña', required: true },
        { name: 'role_id', label: 'Rol', type: 'select', options: authorizedUserRoles, required: true },
        { name: 'branchoffice_Id', label: 'Sucursal', type: 'checkbox', options: userBranchoffices, required: true }
    ]);

    //We set the modal branch modal fields 
    const [modalFieldsAsing, setModalFieldsAsing] = useState([
        { name: 'branchoffice_id', label: 'Sucursal', type: 'select', options: userBranchoffices, required: true }
    ])
    //We set the modals states
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showModalAsing, setShowModalAsing] = useState(false);
    const [showModalAsingModule, setShowModalAsingModule] = useState(false);
    //We set business roles 
    const [businessRoles, setBusinessRoles] = useState([]);
    const [availableRoles, setAvailableRoles] = useState([]);

    //Function to get the users by branchoffice and roles
    const getUsersByBranchofficeAndRoles = async () => {
        try {
            //We get the branchoffices
            let branchoffices = user.branchoffices.map(element => element.branchoffice_id);
            //We get the users by branchoffices and roles
            const response = await UsersFetchAPI.getUsersByBranchofficesAndRole(branchoffices, availableRoles, token);
            //We set the users
            setUsers(response.data.users);
        } catch (err) {
            console.log(err);
        }
    };

    //Function to get the roles by user
    const getRolesByUser = async (user_role_id) => {
        try {
            //We get the roles by user
            const response = await UsersFetchAPI.getRoleByUser(user_role_id, token);
            //We map the roles
            let roles = response.data.data.map(element => (
                { value: element.id, label: element.name }
            ));
            //We set the available roles
            setAvailableRoles(response.data.data.map(element => element.id));
            //We add the default value
            roles.unshift({ value: 0, label: 'Seleccione un rol' });
            //We set the roles
            setAuthorizedUserRoles(roles);
            //We force the event handler to reload the page and charge the new roles
            setEventHandler(1);
        } catch (err) {
            console.log(err);
            setAuthorizedUserRoles([]);
        }
    };

    //Function to detect the change of value on the users forms
    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    //Function to get the details of the user
    const getUserDetails = async (user_id, modalType) => {
        try {
            //We get the user details
            const response = await UsersFetchAPI.getUserById(user_id, localStorage.getItem('x-access-token'));
            //We set the selected user option
            setSelectedUserOption({
                name: response.data.user.name,
                email: response.data.user.email,
                phone: response.data.data.phone,
                idCard: response.data.user.idCard,
                role_id: response.data.user.role_id,
                branchoffices: response.data.user.branchoffices,
                user_id: response.data.user.id
            });
            //We set the form data for the edit form
            if (modalType !== 'asingModal') {
                //We set the form data
                let lastModalFields = [
                    { name: 'name', label: 'Nombre', type: 'text', placeholder: 'Ingrese nombre' },
                    { name: 'email', label: 'Correo', type: 'email', placeholder: 'Ingrese correo' },
                    { name: 'phone', label: 'Telefono', type: 'phone', placeholder: 'Ingrese telefono' },
                    { name: 'idCard', label: 'Cedula', type: 'text', placeholder: 'Ingrese la cedula' },
                    { name: 'pass', label: 'Contraseña', type: 'text', placeholder: 'Ingrese Contraseña' },
                    { name: 'passwordCheck', label: 'Confirmar contraseña', type: 'text', placeholder: 'Ingrese Contraseña' },
                    { name: 'role_id', label: 'Rol', type: 'select', options: authorizedUserRoles, defaultValue: response.data.user.role_id, required: true },
                    { name: 'branchoffice_Id', label: 'Sucursal', type: 'checkbox', options: userBranchoffices }
                ];
                lastModalFields[7].options = response.data.user.branchoffices;
                setModalFields(lastModalFields);

                // Set initial form values for the edit form
                setFormData({
                    name: response.data.user.name,
                    email: response.data.user.email,
                    phone: response.data.data.phone,
                    idCard: response.data.user.idCard,
                    role_id: response.data.user.role_id,
                    pass: '',
                    passwordCheck: '',
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    //Function to register the new user
    const newUser = async (e) => {
        e.preventDefault();
        try {
            //We add the business id to the form data
            formData.business_id = user.business.id;
            //We register the user
            let response = await UsersFetchAPI.registerUser(formData, localStorage.getItem('x-access-token'));
            //We show the success alert
            SuccessAlert('Subscripcion realizada');
            //We reload the page
            setEventHandler(1);
        } catch (err) {
            console.log(err);
            //We show the error alert
            ErrorAlert(err.response.data.error, 'Error', 'error');
        }
    };

    //Function to edit the user
    const editUser = async (e) => {
        e.preventDefault();
        try {
            // We update the user
            await UsersFetchAPI.updateUser(selectedUserOption.user_id, formData, localStorage.getItem('x-access-token'));

            //We show the success alert
            SuccessAlert('Usuario actualizado');

            //We reload the table
            getUsersByBranchofficeAndRoles();
            //We reload the modal 
            await getUserDetails(selectedUserOption.user_id);
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error', 'error');
        }
    };

    //Function to remove the user branch
    const removeUserBranch = async (user_id, branchoffice_id) => {
        try {
            //We remove the user branch
            await UsersFetchAPI.removeUserFromBranchoffice(user_id, branchoffice_id, localStorage.getItem('x-access-token'));
            //console.log(response);
            //We show the success alert
            SuccessAlert('Usuario eliminado de la sucursal');
            //We reload the modal 
            await getUserDetails(user_id);
        } catch (err) {
            //We show the error alert
            ErrorAlert(err.response.data.error, 'Error', 'error');
        }
    };

    //Function to asing the user to a branch
    const asingUserBranch = async (e) => {
        e.preventDefault();
        try {
            //We asing the user to the branch
            await UsersFetchAPI.asingUserToBranchoffice(selectedUserOption.user_id, formData.branchoffice_id, localStorage.getItem('x-access-token'));
            //We show the success alert
            SuccessAlert('Usuario asignado a la sucursal');
            //We reload the modal 
            await getUserDetails(selectedUserOption.user_id);
        } catch (err) {
            //We show the error alert
            ErrorAlert(err.response.data.error, 'Error', 'error');
        }
    };

    //Function to remove the user
    const removeUser = async (user_id) => {
        try {
            //We remove the user
            await UsersFetchAPI.removeUser(user_id, localStorage.getItem('x-access-token'));
            //We show the success alert
            SuccessAlert('Usuario eliminado');
            //We reload the table
            getUsersByBranchofficeAndRoles();
        } catch (err) {
            //We show the error alert
            ErrorAlert(err.response.data.error, 'Error', 'error');
        }
    }

    //Function to get Business user roles
    const getBusinessUserRoles = async () => {
        try {
            //We get the roles by user
            const response = await UsersFetchAPI.getUserRolesByBusiness(user.business.id, token);
            //We set the business roles
            setBusinessRoles(response.data.data);
        } catch (err) {
            setBusinessRoles([]);
        }
    }

    //We set the initial values of the form
    useEffect(() => {
        setUserBranchs(user.branchoffices);
        getRolesByUser(user.role);
        setEventHandler(0);
    }, []);

    //We set the roles of the user
    useEffect(() => {
        let lastModalFields = modalFields;
        let lastAsingModalFields = modalFieldsAsing;
        //We set the roles
        lastModalFields[6].options = authorizedUserRoles;
        //We set the branches on the asing modal
        lastAsingModalFields[0].options = user.branchoffices.map((branch) => ({
            value: branch.branchoffice_id, label: branch.branchoffice_name
        }));
        lastAsingModalFields[0].options.unshift({ value: 0, label: 'Seleccione una sucursal' });
        //We set the branches on the user add  modal
        lastModalFields[7].options = user.branchoffices.map(element => ({ name: element.branchoffice_name, id: element.branchoffice_id }));

        //We set the modal fields
        setModalFields(lastModalFields);
        setModalFieldsAsing(lastAsingModalFields);
        //We get the business user roles
        getBusinessUserRoles();
        //We get the users by branchoffice and roles
        getUsersByBranchofficeAndRoles();
        //We set the event handler
        setEventHandler(0);
    }, [eventHandler]);

    return (
        <>
            <div className='sp-grid bg-white no-scroll position-relative g-0'>
                <div className=' fixed-column'>
                    <NavBar titlePage="Usuarios" />
                </div>
                <div className='other-column '>
                </div>
                {/**Body header */}
                <main className='m-2'>
                    <section className='banner-sp d-lg-flex justify-content-lg-between  align-items-center rounded-4 mx-2  p-2 '>
                        <div>
                            <div className='d-flex justify-content-between  align-items-center'>
                                <h2 className='banner-reports-title mb-0 pb-0'>
                                    Usuarios
                                </h2>
                            </div>
                            <p className='m-0 p-0 banner-reports-description'>Gestión y creación de usuarios.</p>
                        </div>


                        <div className="d-flex justify-content-between gap-1">
                            {/**New user modal*/}
                            <SmartpayModal
                                button_text={"Nuevo usuario"}
                                buttonModal={true}
                                modal_size={"lg"}
                                modal_header_text={"Nuevo usuario"}
                                modal_body={modalFields}
                                submit={newUser}
                                setShowModal={setShowNewUserModal}
                                showModal={showNewUserModal}
                                handleFieldChange={handleFieldChange}
                                initialFormValues={formData}
                                formType={"addForm"}
                                setEventHandler={setEventHandler}
                            />

                            {/** Modal to manage the roles */}
                            <ModalRoles
                                showModal={showModalAsingModule}
                                setShowModal={setShowModalAsingModule}
                                setEventHandler={setEventHandler}
                                eventHandler={eventHandler}
                                user={user}
                                businessRoles={businessRoles}
                                token={token}
                            />
                        </div>

                    </section>


                    <div>
                        {/**Edit user modal*/}
                        <SmartpayModal
                            buttonModal={false}
                            modal_size={"lg"}
                            modal_header_text={"Editar usuario"}
                            modal_body={modalFields}
                            submit={editUser}
                            setShowModal={setShowEditUserModal}
                            showModal={showEditUserModal}
                            handleFieldChange={handleFieldChange}
                            initialFormValues={formData}
                            formType={"editForm"}
                            removeUserBranch={removeUserBranch}
                            selectedUserOption={selectedUserOption}
                        />


                        {/** Asing branch user modal */}
                        <SmartpayModal
                            buttonModal={false}
                            modal_size={"lg"}
                            modal_header_text={"Asignar sucursal"}
                            modal_body={modalFieldsAsing}
                            submit={asingUserBranch}
                            setShowModal={setShowModalAsing}
                            showModal={showModalAsing}
                            initialFormValues={formData}
                            handleFieldChange={handleFieldChange}
                            formType={"addForm"}
                            selectedUserOption={selectedUserOption}
                        />

                        {/**Table user*/}
                        <Table
                            table_name={"Lista de usuarios"}
                            table_headers={['Imagen', 'Nombre', 'Cedula', 'Correo Electronico', 'Rol', 'Acciones']}
                            table_data={users}
                            table_type={"user_table"}
                            setEditShowModal={setShowEditUserModal}
                            setSelectedUserOption={setSelectedUserOption}
                            setShowModalAsing={setShowModalAsing}
                            setShowModalAsingModule={setShowModalAsingModule}
                            getUserDetails={getUserDetails}
                            removeUser={removeUser}
                        />
                    </div>
                </main>
            </div>
        </>
    );
}
