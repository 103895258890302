import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class AuthFetchAPI {

    static async login(data) {
        console.log(data)
        const res = await server('/auth/login', {
            method: 'POST',
            data
        });
        return res
    }

    static async loginSmartPay(data) {
        const res = await server('/auth/loginsmartpay', {
            method: 'POST',
            data
        });
        return res
    }

    static async validtoken(data) {
        const res = await server("/auth/tokenIsValid", {
            method: "post",
            headers: { 'x-access-token': data },

        });
        return res;
    }
    static async loginBranchTable(data) {
        const res = await server('/auth/logintable', {
            method: 'POST',
            data
        });
        return res
    }



    static async me(data) {
        const res = await server("/auth/me", {
            method: "get",
            headers: { 'x-access-token': data },
        });
        return res;
    }

    //Function to authentication the machine (Vending machine)
    static async authMachine(business_code, branchoffice_code, machine_code) {
        const res = await server("/auth/loginsmartpay", {
            method: "POST",
            data: {
                "business_code": business_code,
                "branchoffice_code": branchoffice_code,
                "machine_code": machine_code
            },
        });
        return res;
    }

        //Function to generate token
        static async generatePublicToken(business_id) {
            console.log(business_id);
            const res = await server("/auth/token", {
                method: "POST",
                data: {business_id: business_id}

              
            });
            return res;
        }

         //Function to generate token
         static async validToken(token) {
            const res = await server("/auth/tokenIsValid", {
                method: "POST",
                headers: { 'x-access-token': token },
              
            });
            return res;
        }

          //Function to generate token
          static async getMenuGenerate(token, machine_id, branchoffice_id) {
            const res = await server("/auth/business/smartpay?machine_id="+machine_id+"&&branchoffice_id="+branchoffice_id, {

                method: "get",
                headers: { 'x-access-token': token },
              
            });
            return res;
        }

        

}