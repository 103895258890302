import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup, Form } from 'react-bootstrap';
//Components
import ModalForm from '../../Modal/Modal';
import ModalListSmartpayModulesByUserRole from './ModalListSmartpayModulesByUserRole';
//Alerts 
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
//Utils
import { RenderIf } from '../../utils/RenderIf';
//API
import { UsersFetchAPI } from '../../../api/Users';
import { SmartpayModulesFetchAPI } from '../../../api/SmartPay.Modules';

//Function to list all set groups
const ModalRoles = ({ user, setEventHandler, eventHandler, businessRoles, showModal, setShowModal, token }) => {
    //States
    const [showInternalModal, setShowInternalModal] = useState(false);
    const [showListSmartPayModuleModal, setShowListSmartPayModuleModal] = useState(false);
    const [showAsingSmartPayModuleModal, setShowAsingSmartPayModuleModal] = useState(false);
    //Initial form values
    const [initialFormValues, setInitialFormValues] = useState([
        { type: 'text', name: 'name', placeholder: 'Nombre', label: 'Nombre', required: true },
        { type: 'textarea', name: 'description', placeholder: 'Descripcion', label: 'Descripcion', required: true },
    ]);
    const [initialFormValuesAsingSmartPayModule, setInitialFormValuesAsingSmartPayModule] = useState([
        { type: 'select', name: 'module_id', placeholder: 'Modulo', label: 'Modulo', required: true, options: [] },
    ]);

    //states
    const [smartpayModules, setSmartpayModules] = useState([
        {
            module_code: "",
            module_id: 0,
            module_name: "",
            permissions: []
        }
    ]);
    //States
    const [smartPayModules, setSmartPayModules] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        business_id: user.business.id,
        module_id: 0,
        role_id: 0
    });
    const [rolSelected, setSelectedRol] = useState({
        id: 0,
        name: '',
        description: '',
        isdefault: false,
    })
    //Function to populate the list
    const populateList = () => {
        const list = businessRoles.map((role) => (
            <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
            >
                <div className="ms-2 me-auto">
                    <div className="fw-bold">{role.name}</div>
                </div>
                <RenderIf isTrue={role.business_id !== null}>
                    <div className='m-1'>
                        <button className='btn btn-outline-info btn-sm border-0'
                            onClick={async () => { setSelectedRol({...role, isdefault: false}); getSmartpayModulesByRole(role); setShowListSmartPayModuleModal(true); }}>
                            <i className="uil uil-list-ol"></i>
                        </button>
                    </div>
                </RenderIf>
                <RenderIf isTrue={role.business_id === null}>
                    <div className='m-1'>
                        <button className='btn btn-outline-success btn-sm border-0'
                            onClick={async () => { setSelectedRol({...role, isdefault: true}); getSmartpayModulesByRole(role); setShowListSmartPayModuleModal(true); }}>
                            <i className="uil uil-eye"></i>
                        </button>
                    </div>
                </RenderIf>
            </ListGroup.Item>
        ));
        return (list)
    }

    //Function to detect the change of value on the users forms
    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };


    //Function to create a new business role
    const newBusinessRole = async (e) => {
        e.preventDefault();
        try {
            //We call the function to create a new business role
            const res = await UsersFetchAPI.createBusinessUserRole(formData, token);
            if (res.status === 200) {
                SuccessAlert('Rol creado correctamente')
                setEventHandler(1);
            }
        } catch (error) {
            ErrorAlert('Error al crear el rol')
        }
    }

    //Function to get the smartpay modules 
    const getSmartpayModules = async () => {
        try {
            //We get the smartpay modules
            const res = await SmartpayModulesFetchAPI.getSmartpayModules(token);

            //We set the smartpay modules
            setSmartpayModules(res.data.modules)
            //We set the initial form values
            initialFormValuesAsingSmartPayModule[0].options = res.data.modules.map((module) => (
                { value: module.id, label: module.name }
            ));
            //We add the default value
            initialFormValuesAsingSmartPayModule[0].options.unshift({ value: 0, label: 'Seleccione un modulo...' });
            //We set the initial form values
            setInitialFormValuesAsingSmartPayModule(initialFormValuesAsingSmartPayModule);
        } catch (error) {
            setSmartpayModules([])
        }
    }


    //Function to get the smartpay modules by role
    const getSmartpayModulesByRole = async (role) => {
        try {
            //We get the smartpay modules by role
            const res = await SmartpayModulesFetchAPI.getSmartpayModulesByRole(role.id, token);
            //We set the smartpay modules
            setSmartPayModules(res.data.modules);
        } catch (err) {
            setSmartPayModules([]);
        }
    }

    //Use effect to get the initial data
    useEffect(() => {
        //We call the function
        setEventHandler(0)
        setFormData({
            name: '',
            business_id: user.business.id
        });
        getSmartpayModules();
        getSmartpayModulesByRole(rolSelected)
    }, [eventHandler]);


    return (
        <>
            {/**Button to show the modal of set groups*/}
            <button className='btn btn-dark'
                onClick={() => (setShowModal(true))} type="button">
                <i className="uil uil-airplay"></i> Roles
            </button>
            {/**Modal to show the roles */}
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-airplay"></i>
                        Roles</h3>

                </Modal.Header>
                <Modal.Body>
                    {/* Modal to create a new business role */}
                    <ModalForm
                        modal_body={initialFormValues}
                        modal_header_text='Crear nuevo rol'
                        button_text='Crear'
                        initialFormValues={formData}
                        formType={'addForm'}
                        buttonModal={true}
                        setEventHandler={setEventHandler}
                        eventHandle={eventHandler}
                        submit={newBusinessRole}
                        handleFieldChange={handleFieldChange}
                        setShowModal={setShowInternalModal}
                        showModal={showInternalModal}
                    />


                    <ListGroup as="ol" >
                        {populateList()}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

            {/**Modal to list the smartpay module by user role*/}

            <ModalListSmartpayModulesByUserRole
                role={rolSelected}
                token={token}
                setShowModal={setShowListSmartPayModuleModal}
                showModal={showListSmartPayModuleModal}
                handleFieldChange={handleFieldChange}
                initialFormValues={initialFormValuesAsingSmartPayModule}
                formData={formData}
                showAsingSmartPayModuleModal={showAsingSmartPayModuleModal}
                setShowAsingSmartPayModuleModal={setShowAsingSmartPayModuleModal}
                setEventHandler={setEventHandler}
                eventHandler={eventHandler}
                smartPayModules={smartPayModules}
                business={user.business}
            />
        </>
    );
}

export default ModalRoles;
